export function set(array, id, value) {
    let arr_copy = JSON.parse(JSON.stringify(array));
    let contains = false;
    for (let i = 0; i < arr_copy.length; i++) {
        if (arr_copy[i][0] === id) {
            arr_copy[i][1] = value;
            contains = true;
        }
    }

    if (!contains) {
        arr_copy.push([id, value]);
    }
    return arr_copy;
}

export function remove(array, value) {
    return array.filter((e) => (e !== value));
}