import React, {
  useState, useRef, useLayoutEffect, useContext
} from 'react';
import {
  Typography, Stack, Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { isAlphaNumeric } from '../../services/helpers';
import { editBlock } from '../../redux/actions/Routes';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import CheckboxInput from './CheckboxInput';
import { EditorContext } from './EditorContext';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';

function QueryBlock(props) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [params, setParams] = useState(props.blockData.params || '{}');
  const [varName, setVarName] = useState(props.blockData.varName || '');
  const [model, setModel] = useState(props.blockData.model || '');
  const [multiple, setMultiple] = useState(props.blockData.multiple || false);
  const [populate, setPopulate] = useState("");

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // let modelName = props.models.find(m => m.id === model);
    if (editorVariant === 'middleware') {
      props.editBlockMiddleware(props.id, {
        ...props.blockData, params, varName, model, multiple,
      });
    } else {
      props.editBlock(props.id, {
        ...props.blockData, params, varName, model, multiple,
      });
    }
  }, [params, varName, model, multiple]);

  const handleSetVarName = (e) => {
    if (isAlphaNumeric(e.target.value)) {
      setVarName(e.target.value);
    }
  };

  const handleSaveBlock = () => {
    props.editBlock(props.id, {
      params, varName, model, multiple,
    });
  };

  return (
    <div style={{ ...styles.main, padding: props.opened ? '1em' : '0' }}>
      { props.opened
    && (
    <Stack spacing={0}>

      <Grid container spacing={0}>

        {/* PARAMS */}
        <Grid item xs={7} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            params:
          </Typography>
          <TextInput 
            placeholder={"username: 'joe', age: {$gte: 21}"}
            value={params}
            onChange={(e) => setParams(e.target.value)}
          />
        </Grid>

        {/* MODEL SELECT */}
        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            from:
          </Typography>
          <SelectInput
            value={model}
            onChange={(e) => setModel(e.target.value)}
          >
            <option value="">
              None
            </option>
            {props.models.map((model) => (
              <option value={model.name} key={model.id}>
                {model.name}
              </option>
            ))}
          </SelectInput>
        </Grid>

        {/* SELECT MANY */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            multiple:
          </Typography>
          <CheckboxInput
            checked={multiple}
            onChange={(e) => setMultiple(e.target.checked)}
          />
        </Grid>

        {/* VAR NAME */}
        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            var name:
          </Typography>
          <TextInput 
            placeholder={"data"}
            value={varName}
            onChange={handleSetVarName}
          />
        </Grid>
      </Grid>
    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  saveCount: state.routesReducer.saveCount,
  refresh: state.routesReducer.refresh,
  blockCache: state.routesReducer.activeSequenceBlockCache,
  isDragging: state.routesReducer.isDragging,
});
const mapDispatchToProps = (dispatch) => ({
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryBlock);

const styles = {
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    width: '6em',
    marginLeft: '.5em',
    marginTop: 0,
  },
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: '1em',
  },
};
