import {
  SELECT_AUTH, SET_AUTH_REDUCER,
} from '../Types';

export const selectAuth = (data) => ({
  type: SELECT_AUTH,
  data,
});

export const setAuthReducer = (data) => ({
  type: SET_AUTH_REDUCER,
  data,
});

