import React from "react";
import {Terminal} from "xterm";
import {FitAddon} from "xterm-addon-fit";
import "./xterm.css";
import {WidthContext} from '../hooks/WidthContext';
import {connect} from 'react-redux';
import {
  addToCurrLine,
  appendCommand,
  backspace,
  clearLine,
  setLine,
  switchCommandIndex
} from "../redux/actions/Terminal";
import {terminalBackend} from "./TerminalHandler";
import {addModel} from "../redux/actions/Models";
import {addController} from "../redux/actions/Controllers";
import {addRoute} from "../redux/actions/Routes";
import {getRandomInt, initRouteObject} from "../services/helpers";

const colors = {
    white: '\u001b[37m',
    red: '\u001b[31m',
    green: '\u001b[32m',
    cyan: '\u001b[36m',
}

let term;
const fitAddon = new FitAddon();

const shellprompt = "neutrino:~$ ";

class XtermTerminal extends React.Component {
    static contextType = WidthContext;

    constructor(props) {
        super(props);

        this.state = {
            logs: "",
            width: 250,
        };
    }

    createScaffoldRedux = (modelName, modelId, modelParams) => {
        // Add Model
        this.props.addModel(modelName, modelId, modelParams);
        // Add Controller and routes
        const newControllerId = modelId + getRandomInt(10000000);
        this.props.addController(modelName, modelId, newControllerId);

        let dummyModel = {
            'name': modelName,
            'params': modelParams,
        }

        const indexRoute = initRouteObject('index', modelName, getRandomInt(10000000), newControllerId);
        const showRoute = initRouteObject('show', modelName, getRandomInt(10000000), newControllerId);
        const createRoute = initRouteObject('create', modelName, getRandomInt(10000000), newControllerId, dummyModel);
        const updateRoute = initRouteObject('update', modelName, getRandomInt(10000000), newControllerId, dummyModel);
        const deleteRoute = initRouteObject('delete', modelName, getRandomInt(10000000), newControllerId);

        this.props.addRoute(indexRoute);
        this.props.addRoute(showRoute);
        this.props.addRoute(createRoute);
        this.props.addRoute(updateRoute);
        this.props.addRoute(deleteRoute);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.width !== this.context) {
            this.setState({width: this.context});
        }

    }

    componentDidMount() {
        term = new Terminal({
            convertEol: true,
            fontFamily: `'Fira Mono', monospace`,
            fontSize: 15,
            fontWeight: 500
            // rendererType: "dom" // default is canvas
        });

        //Styling
        term.setOption("theme", {
            background: "#000f18",
            foreground: "white"
        });

        // Load Fit Addon
        term.loadAddon(fitAddon);

        // Open the terminal in #terminal-container
        term.open(document.getElementById("xterm"));

        // //Write text inside the terminal
        // term.write(c.magenta("I am ") + c.blue("Blue") + c.red(" and i like it"));

        // Make the terminal's size and geometry fit the size of #terminal-container
        fitAddon.fit();

        term.onKey((key) => {
            const char = key.domEvent.key;

            /*
             * Enter
             */
            if (char === "Enter") {
                let res = terminalBackend(this.props.currLine);
                let throwRes = true;

                if (res) {
                    // Scaffold
                    if (!res.error && res.command === 'scaffold') {
                        try {
                            // Creates model scaffold
                            this.createScaffoldRedux(res.data.name, res.data.id, res.data.params);
                        } catch (e) {
                            throwRes = false;
                            term.write("\r\n" + colors.red + `Error creating scaffold: ${e}`);
                        }
                    } else if (!res.error & res.command === 'init auth') {
                        // try {
                        const newControllerId = getRandomInt(10000000);
                        this.props.addController('Auth', null, newControllerId);

                        if (this.props.models.some(m => m.name === 'User')) {
                            console.log('has user!')
                        } else {
                            const userParams = [
                                {name: 'username', paramType: 'data', dataType: 'String'},
                                {name: 'email', paramType: 'data', dataType: 'String'},
                                {name: 'password', paramType: 'data', dataType: 'String'},
                            ]
                            this.createScaffoldRedux('User', getRandomInt(1000000), userParams);
                        }

                        const loginRoute = initRouteObject('login', 'User', getRandomInt(10000000), newControllerId);
                        this.props.addRoute(loginRoute);
                        // const registerRoute = initRouteObject('register', null, getRandomInt(10000000), newControllerId);
                        // } catch (e) {
                        //   throwRes = false;
                        //   term.write("\r\n" + colors.red + `Error setting up authentication: ${e}`);
                        // }
                    }
                    if (throwRes) {
                        let color = res.error ? colors.red : colors.green
                        term.write("\r\n" + color + res.response + colors.white);
                    }
                }

                this.prompt();
                this.props.appendCommand();
            }

            /*
             * Backspace
             */
            else if (char === "Backspace") {
                term.write("\b \b");
                this.props.backspace();
            }

            /*
             * Up Arrow
             */
            else if (char === 'ArrowUp') {
                // clear, write prompt, decrease index, then write correct line
                term.write('\x1b[2K\r')
                term.write(colors.cyan + shellprompt + colors.white);
                this.props.clearLine();
                this.props.switchCommandIndex(-1);
                if (this.props.commands.length > 0 && this.props.commandIndex - 1 >= 0) {
                    term.write(this.props.commands[this.props.commandIndex - 1]);
                    this.props.setLine(this.props.commands[this.props.commandIndex - 1]);
                }
            }

            /*
             * Down Arrow
             */
            else if (char === 'ArrowDown') {
                // clear, write prompt, increase index, then write correct line
                term.write('\x1b[2K\r')
                term.write(colors.cyan + shellprompt + colors.white);
                this.props.clearLine();
                this.props.switchCommandIndex(1);
                if (this.props.commands.length > 0 && this.props.commandIndex + 1 < this.props.commands.length) {
                    term.write(this.props.commands[this.props.commandIndex + 1]);
                    this.props.setLine(this.props.commands[this.props.commandIndex + 1]);
                }
            } else if (char === 'ArrowLeft') {

            } else if (char === 'ArrowRight') {

            }

            /*
             * Delete
             */
            else if (char === 'Delete') {
                term.write('\x1b[2K\r')
                term.write(shellprompt);
                this.props.clearLine();
            }

            /*
             * Other
             */
            else {
                term.write(char);
                this.props.addToLine(char);
            }
        });

        this.prompt();
    }

    prompt = () => {
        term.write("\r\n" + colors.cyan + shellprompt + colors.white);
    };

    render() {
        return (
            <div
                className="flex h-full overflow-x-auto pt-1 pl-2"
            >
                <div
                    id="xterm"
                    style={{
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        flex: 1,
                        whiteSpace: 'normal',
                        maxWidth: "28em"
                    }}
                    className="h-full w-full"
                />
            </div>
        );
    }
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    terminalOpen: state.terminalReducer.terminalOpen,
    commandIndex: state.terminalReducer.commandIndex,
    commands: state.terminalReducer.commands,
    currLine: state.terminalReducer.currLine,
    commandIndex: state.terminalReducer.commandIndex
});
const mapDispatchToProps = (dispatch) => ({
    toggleTerminal: () => dispatch(toggleTerminal()),
    addToLine: (c) => dispatch(addToCurrLine(c)),
    clearLine: () => dispatch(clearLine()),
    backspace: () => dispatch(backspace()),
    setLine: (str) => dispatch(setLine(str)),
    appendCommand: () => dispatch(appendCommand()),
    switchCommandIndex: (i) => dispatch(switchCommandIndex(i)),
    addModel: (name, id, params) => dispatch(addModel(name, id, params)),
    addController: (name, affiliation, id) => dispatch(addController(name, affiliation, id)),
    addRoute: (data) => dispatch(addRoute(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(XtermTerminal);