import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ExclamationCircleIcon
} from '@heroicons/react/outline';
import axios from 'axios';
import authHeader from '../../services/auth-header';
import { UserContext } from '../../hooks/UserContext';
import MuiAlert from '@mui/material/Alert';
import {
  Snackbar,
} from '@mui/material';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
import { BUILDER_BACKEND_URL } from '../../constants/constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ProfileSecurity() {
  const { authUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [cPass, setcPass] = useState('');
  const [openErr, setOpenErr] = useState(false);
  const [openSucc, setOpenSucc] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const handleClose = (event, reason, variant='success') => {
    if (reason === 'clickaway') {
      return;
    }
    if (variant === 'error')
      setOpenErr(false);
    else 
      setOpenSucc(false);
  };

  const handleSubmit = (password) => {
    setUpdateError(null);
    setOpenErr(false);
    setOpenSucc(false);
    axios.put(
      `${BUILDER_BACKEND_URL}/users/${authUser}/editpassword`,
      {
        password,
      },
      { headers: authHeader() },
    )
      .then((res) => {
        setOpenSucc(true);
      })
      .catch((err) => {
        setOpenErr(true);
        setUpdateError(err.response.data.message);
      });
  };

  const validate = () => {
    if (password !== '' && cPass !== '' && password === cPass) {
      handleSubmit(password);
    };
  };

  const cancel = () => {
    setPassword('');
    setcPass('');
    navigate('?page=Projects');
  }

  return (
      <div className="mx-auto max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Security</h1>

          <form className="divide-y-blue-gray-200 mt-6 space-y-8 divide-y">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                  <h2 className="text-xl font-medium text-blue-gray-900">Update password</h2>
                  <p className="mt-1 text-sm text-blue-gray-500">
                  Type and confirm your new password
                  </p>
              </div>

              <div className="sm:col-span-6">
                  <label htmlFor="first-name" className="block text-sm font-medium text-blue-gray-900">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    id="pass"
                    autoComplete="given-name"
                    className="h-6 mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  />
              </div>

              <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Confirm password
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="password"
                  value={cPass}
                  onChange={(e) => setcPass(e.target.value)}
                  name="confirm-password"
                  id="c-pass"
                  className={classNames(
                    password !== cPass ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ' border-blue-gray-300',
                    "h-6 block w-full rounded-md  pr-10 text-red-900 focus:outline-none sm:text-sm"
                  )}
                  aria-invalid="true"
                  aria-describedby="password-error"
                />
                { password !== cPass &&
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div>
                }
                                
              </div>
              { password !== cPass &&
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Passwords must match.
                </p>
              }
              </div>
              </div>

              <div className="flex justify-end pt-8">
              <button
                  type="button"
                  onClick={cancel}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                  Cancel
              </button>
              <button
                  type="button"
                  onClick={validate}
                  className={classNames(
                    (password === '' || cPass !== password) ? "bg-gray-400 cursor-default" : "bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 ",
                    "ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2"
                  )}
              >
                  Save
              </button>
              </div>
          </form>

        <Snackbar open={openErr} autoHideDuration={6000} onClose={(e, reason) => handleClose(e, reason, 'error')}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Error updating user data{updateError && `: ${updateError}`}
          </Alert>
        </Snackbar>

        <Snackbar open={openSucc} autoHideDuration={6000} onClose={(e, reason) => handleClose(e, reason, 'success')}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Successfully updated user data!
          </Alert>
        </Snackbar>
      </div>
  )
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  currProjectParams: state.modelsReducer,
  projectName: state.modelsReducer.projectName,
  activeProject: state.activeProjectReducer.activeProject,
});
const mapDispatchToProps = (dispatch) => ({
  togglePagination: (key) => dispatch(togglePagination(key)),
  setActiveProject: (params) => dispatch(setActiveProject(params)),
  setActiveProjectData: (params) => dispatch(setActiveProjectData(params)),
  setReduxActiveProjectId: (params) => dispatch(setActiveProject(params)),
  setReduxModelsData: (params) => dispatch(setActiveProjectData(params)),
  setReduxControllersData: (params) => dispatch(setControllers(params)),
  setReduxRoutesData: (params) => dispatch(setRoutes(params)),
  resetReduxModels: () => dispatch(resetProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSecurity);
