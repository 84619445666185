import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useApi from '../../hooks/useApi';
import authHeader from '../../services/auth-header';
import '../../App.css';
import { UserContext } from '../../hooks/UserContext';
import { BUILDER_BACKEND_URL } from '../../constants/constants';
import MuiAlert from '@mui/material/Alert';
import {
  Button
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  resetProject,
  setActiveProjectData,
  togglePagination,
} from '../../redux/actions/Models';
import { setActiveProject } from '../../redux/actions/ActiveProject';
import { setControllers } from '../../redux/actions/Controllers';
import { resetRoutes, setRoutes } from '../../redux/actions/Routes';
import { resetMiddlewares, setMiddlewares } from '../../redux/actions/Middlewares';
import { setAuthReducer } from '../../redux/actions/Auth';
import {setModelRelations} from "../../redux/actions/Relations";

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ProjectsDisplay(props) {
  const {
    resetReduxModels,
    setReduxActiveProjectId,
    setReduxModelsData,
    setReduxControllersData,
    setReduxRoutesData,
    setReduxMiddlewaresData,
    setReduxRelationsData,
    setReduxAuthData,
    resetControllers,
    resetRoutes,
    resetMiddlewares,
  } = props;

  const { setAuthUser } = useContext(UserContext);
  const { id } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const {
    result: user, loading, error, refresh,
  } = useApi(`${BUILDER_BACKEND_URL}/users/${id}`);
  const navigate = useNavigate();

  function handleSetActive(params, projId) {
    const parsedParams = JSON.parse(params);
    const reduxModels = parsedParams.modelParams || [];
    const reduxControllers = parsedParams.controllerParams || [];
    const reduxRoutes = parsedParams.routeParams || [];
    const reduxMiddlewares = parsedParams.middlewareParams || [];
    const reduxRelations = parsedParams.relationParams || [];
    const reduxAuth = parsedParams.authParams || {};
    setReduxActiveProjectId(projId);
    setReduxModelsData(reduxModels);
    setReduxControllersData(reduxControllers);
    setReduxRoutesData(reduxRoutes);
    setReduxMiddlewaresData(reduxMiddlewares);
    setReduxRelationsData(reduxRelations);
    setReduxAuthData(reduxAuth);
  }

  function handleDeleteProject(projId) {
    axios.delete(`${BUILDER_BACKEND_URL}/projects/${projId}`, { headers: authHeader() });
    if (id === props.activeProject) {
      resetReduxModels();
      setReduxActiveProjectId(null);
    }
    refresh();
  }

  function handleNewProject() {
    props.setActiveProject(null);
    props.resetProject();
    resetRoutes();
    resetControllers();
    resetMiddlewares();
    navigate('/');
  }

  useEffect(() => {
    if (!loading && error) {
      logout();
    }
  }, [loading, error]);


  return (
      <div className="mx-auto max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
        <div className='flex mb-10'>
          <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900 flex-1">Projects</h1>
          <button
            type="button"
            onClick={handleNewProject}
            className={classNames(
              "bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
              "ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none"
            )}
          >
            New Project
          </button>
        </div>

          {user.projects && user.projects.map((project, i) => (
            <div
              className="listItem w-full mt-5"
              key={project._id}
              style={{
                backgroundColor: props.activeProject === project._id ? '#0a171f20' : 'white',
                padding: 5,
                borderRadius: 5,
                flex: 1,
              }}
            >
              <IconButton
                style={{ marginRight: 5 }}
                aria-label="delete"
                onClick={() => handleDeleteProject(project._id)}
              >
                <DeleteIcon color="error" />
              </IconButton>

              <div style={{ flex: 1, textAlign: 'left' }}>{
                JSON.parse(project.params).modelParams 
                ? JSON.parse(project.params).modelParams.projectName 
                : "error displaying project name:/"
              }</div>

              {/* <div style={{ flex: 1, textAlign: 'left' }}>{JSON.stringify(JSON.parse(project.params).modelParams)}</div> */}

              {/* <Button size='small' // variant='outlined'
              onClick={() => navigate(`/projects/${project._id}`)}>show</Button> */}

              <Button
                variant={props.activeProject === project._id ? 'contained' : 'outlined'}
                size="small"
                style={{ marginLeft: 10, width: 100 }}
                onClick={() => handleSetActive(project.params, project._id)}
              >
                {
                  props.activeProject === project._id ? 'active' : 'set active'
                }
              </Button>

              {/* <Button variant="contained" color="error" size='small' style={{marginLeft: 10}}
                onClick={handleDeleteProject}>delete
              </Button> */}
            </div>
          ))}
      </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  currProjectParams: state.modelsReducer,
  projectName: state.modelsReducer.projectName,
  activeProject: state.activeProjectReducer.activeProject,
});
const mapDispatchToProps = (dispatch) => ({
  togglePagination: (key) => dispatch(togglePagination(key)),
  setActiveProject: (params) => dispatch(setActiveProject(params)),
  setActiveProjectData: (params) => dispatch(setActiveProjectData(params)),
  setReduxActiveProjectId: (params) => dispatch(setActiveProject(params)),
  setReduxModelsData: (params) => dispatch(setActiveProjectData(params)),
  setReduxControllersData: (params) => dispatch(setControllers(params)),
  setReduxRoutesData: (params) => dispatch(setRoutes(params)),
  setReduxMiddlewaresData: (params) => dispatch(setMiddlewares(params)),
  setReduxRelationsData: (data) => dispatch(setModelRelations(data)),
  setReduxAuthData: (data) => dispatch(setAuthReducer(data)),
  resetReduxModels: () => dispatch(resetProject()),
  resetProject: () => dispatch(resetProject()),
  resetControllers: () => dispatch(setControllers([])),
  resetRoutes: () => dispatch(resetRoutes()),
  resetMiddlewares: () => dispatch(resetMiddlewares()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsDisplay);
