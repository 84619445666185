import {
  REMOVE_WARNING,
  SET_WARNINGS,
} from '../Types';

export const setWarnings = (data) => ({
  type: SET_WARNINGS,
  data,
});

export const removeWarning = (data) => ({
  type: REMOVE_WARNING,
  data,
});
