import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import MuiAlert from '@mui/material/Alert';
import {
  Snackbar,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import { BUILDER_BACKEND_URL } from '../constants/constants';
import './AuthStyles.css';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function Register(props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [discordUsername, setDiscordUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(null);
  const [openErr, setOpenErr] = useState(false);

  const handleSubmit = (username, email, password) => {
    setLoading(true);
    setErr(null);
    axios.post(`${BUILDER_BACKEND_URL}/register`, {
      username,
      discordUsername,
      email,
      password,
    })
      .then((result) => {
        setLoading(false);
        setRes(result.data.message);
        // navigate('/login');
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setOpenErr(true);
        setLoading(false);
      });
  };

  const validate = () => {
    if (password !== confirmPassword) {
      setErr('passwords do not match');
    }
    if (username !== '' && email !== '' && password !== '' && (password === confirmPassword)) {
		  handleSubmit(username, email, password);
    } else {
      if (username === '') {
        setErr('username cannot be left blank');
      } else if (email === '') {
        setErr('email cannot be left blank');
      } else if (password === '') {
        setErr('password cannot be left blank');
      } else if (password !== confirmPassword) {
        setErr('passwords do not match');
      }
      setOpenErr(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErr(false);
  };

  // FOR NOW: NOT REQUIRED EMAIL VERIFICATION
  if (res) {
    return (
      <div className="root">
        <div style={{ flex: 1 }} />
        <div className="auth-container w-11/12 lg:w-2/5">

          <p style={{ color: 'limegreen', textAlign: 'center' }}>{res}</p>
          <p
            style={{ textAlign: 'center', cursor: 'pointer' }}
            className="underline"
            onClick={() => navigate('/login')}
          >
            Back to Login
          </p>

        </div>
        <div style={{ flex: 1 }} />
      </div>
    );
  }

  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">

        <Stack spacing={3} style={{ width: '100%' }}>
          <h1 className="title text-center">Register</h1>
          <TextField
            label="username"
            size="small"
            type="String"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {/* <TextField
            label="discord username"
            size="small"
            type="String"
            value={discordUsername}
            onChange={(e) => setDiscordUsername(e.target.value)}
          /> */}
          <TextField
            label="email"
            size="small"
            type="String"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="password"
            size="small"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="confirm password"
            size="small"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button variant="contained" onClick={validate}>{ loading ? 'loading...' : 'create account'}</Button>

          <div className='text-center'>
            Already have an account? {" "}
            <a 
              onClick={() => navigate('/login')}
              className="underline cursor-pointer"
            >sign in</a>
          </div>
        </Stack>
      </div>

      <div style={{ flex: 1 }} />

      {/* SNACKBAR */}
      <Snackbar open={openErr} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {err}
        </Alert>
      </Snackbar>
    </div>
  );
}
