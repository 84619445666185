import React from 'react';
import { connect } from 'react-redux';
import { setActiveController, setActiveModel } from '../redux/actions/Sidebar';
import { Typography } from '@mui/material';

function ControllerSidebar({ controllers, selectController }) {
  return (
    <div className=''>
      {controllers.map((c) => (
        <div
          key={c.id}
          className="width-full bg-offwhite text-black p-1 pl-2 mb-2 flex"
          style={{borderRadius: '.25em 0 0 .25em', borderRightWidth: 0, cursor: 'pointer'}}
          onClick={() => selectController(c.id)}
        >
          <Typography variant="caption" display="block" style={{ fontWeight: 'bold', opacity: 0.8, fontSize: '.75em' }}>
            {c.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

/* Redux */
const mapStateToProps = (state) => ({
  controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
  selectController: (id) => dispatch(setActiveController(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControllerSidebar);