import React, {
  useEffect, useId, useState, useCallback,
} from 'react';
import {
  Button, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Stack, FormHelperText, Checkbox, Label, Chip,
} from '@mui/material';
import { connect } from 'react-redux';
import axios from 'axios';
import Modal from './Modal';
import {
  getRandomInt, initRouteObject, isAlphaNumeric, pluralize,
} from '../services/helpers';
import { addModel, removeModel } from '../redux/actions/Models';
import { toPascalCase, toTitleCase } from '../util/caseMatching';
import { addController } from '../redux/actions/Controllers';
import { addRoute } from '../redux/actions/Routes';
import { AI_BACKEND_URL } from '../constants/constants';
import {
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import useApi from '../hooks/useApi';

const validTypes = ['string', 'text', 'number', 'boolean', 'integer']

const parseParamsString = (str) => {
  let inStr = str.trim().replace(/\s\s+/g, ' ');;
  let arr = inStr.split(' ');
  let initParams = []

  for (let p of arr) {
    let param = p.trim().split(':');
    if (param.length === 2 && validTypes.includes(param[1])) {
      initParams.push({ name: param[0], paramType: 'data', dataType: toTitleCase(param[1]) })
    } else {
      return false;
    }
  }

  return initParams;
}

function ModelModal({
  open, handleClose, addModel, addController, addRoute,
}) {
  const id = useId();
  const [name, setName] = useState('');
  const [schemaRecs, setSchemaRecs] = useState([]);
  const [toggleRecs, setToggleRecs] = useState([]);
  const [recsLoading, setRecsLoading] = useState(false);
  const [withController, setWithController] = useState(true);
  const [selectedSchema, setSelectedSchema] = useState([]);
  const [schemaString, setSchemaString] = useState('');
  const submitDisabled = (name === '' || (!parseParamsString(schemaString) && schemaString.trim() !== ''));

  /*
   * Fixes spacing in schema string when toggling schema recs 
   */
  useEffect(() => {
    let newStr = schemaString.replace(/\s\s+/g, ' ');
    setSchemaString(newStr)
  }, [toggleRecs])

  /*
   * Toggles suggested schema chip if user manually types in suggested schema
   */
  useEffect(() => {
    let parsedParams = parseParamsString(schemaString);
    if (parsedParams) {
      for (let p of parsedParams) {
        for (let i = 0; i < schemaRecs.length; i++) {
          let s = schemaRecs[i];
          if (s[0] === p.name) {
            const newToggle = JSON.parse(JSON.stringify(toggleRecs));
            newToggle[i] = true;
            setToggleRecs(newToggle);
          }
        }
      }
    }
  }, [schemaString])

  /*
   * Handles toggling suggested schema chip + will remove schema from string if user untoggles 
   */
  const toggleSchema = (index) => {
    const newToggle = JSON.parse(JSON.stringify(toggleRecs));
    let pName = schemaRecs[index][0];

    if (newToggle[index] && (schemaString.includes(pName))) {
      let newStr = schemaString
        .replace(` ${pName}:string`, '')
        .replace(` ${pName}:text`, '')
        .replace(` ${pName}:boolean`, '')
        .replace(` ${pName}:number`, '')
        .replace(` ${pName}:integer`, '')
      setSchemaString(newStr);
    } else if (!newToggle[index]) {
      let newStr = schemaString + ` ${schemaRecs[index][0]}:${schemaRecs[index][1]} `;
      setSchemaString(newStr)
    }

    newToggle[index] = !newToggle[index];
    setToggleRecs(newToggle);
  };

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const getSuggestions = useCallback(
    debounce(() => {
      axios.get(`${AI_BACKEND_URL}/schema-suggestions?model=${name}`)
        .then((res) => {
          setSchemaRecs(res.data.data[1]);
          const falseInit = new Array(res.data.data[1].length).fill(false);
          setToggleRecs(falseInit);
        });
    }, 200),
    [name],
  );

  useEffect(() => {
    if (name.length >= 3) {
      getSuggestions();
    }
  }, [name]);

  const handleChangeName = (e) => {
    if (isAlphaNumeric(e.target.value)) {
      setName(toPascalCase(e.target.value));
    }
  };

  const handleSubmit = () => {
    const newModelId = id + getRandomInt(10000);
    const parsedString = parseParamsString(schemaString);

    const initParams = [];
    for (let i = 0; i < schemaRecs.length; i++) {
      if (toggleRecs[i]) {
        initParams.push({ name: schemaRecs[i][0], paramType: 'data', dataType: toTitleCase(schemaRecs[i][1]) });
      }
    }

    if (parsedString) {
      for (let p of parsedString) {
        if (!initParams.some(param => param.name === p.name)) {
          initParams.push(p);
        }
      }
    }


    setSchemaRecs([]);
    setToggleRecs([]);

    addModel(name, newModelId, initParams);

    if (withController) {
      const newControllerId = id + getRandomInt(10000);
      addController(name, newModelId, newControllerId);

      let dummyModel = {
        'name': name,
        'params': initParams,
      }

      const indexRoute = initRouteObject('index', name, id + getRandomInt(10000), newControllerId);
      const showRoute = initRouteObject('show', name, id + getRandomInt(10000), newControllerId);
      const createRoute = initRouteObject('create', name, id + getRandomInt(10000), newControllerId, dummyModel);
      const updateRoute = initRouteObject('update', name, id + getRandomInt(10000), newControllerId, dummyModel);
      const deleteRoute = initRouteObject('delete', name, id + getRandomInt(10000), newControllerId);

      addRoute(indexRoute);
      addRoute(showRoute);
      addRoute(createRoute);
      addRoute(updateRoute);
      addRoute(deleteRoute);
    }

    setName('');
    setSchemaString('');
    handleClose();
  };

  const handleSchemaString = (e) => {
    let newStr = e.target.value.replace(/\s\s+/g, ' ')
    if (newStr[0] !== ' ') {
      newStr = " " + newStr
    }
    setSchemaString(newStr);
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      submitDisabled={submitDisabled}
      title="New Model"
      handleSubmit={handleSubmit}
    >

      <Stack spacing={2}>

        <FormControl size="small">
          <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
            model name
          </Typography>
          <TextField
            value={name}
            size="small"
            placeholder="NewModel"
            fullWidth
            onChange={handleChangeName}
          />
          <FormHelperText>By convention, model names should be singluar</FormHelperText>
        </FormControl>

        <FormControl size="small">
          <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
            model schema
          </Typography>
          <div className='flex w-full bg-darkbg text-white items-center pl-4'>
            <span className='pr-1' style={{color: '#049889'}}>~$</span>
            <input
              type='text'
              style={{border: 'none', outline: 'none'}}
              className='w-full p-4 pl-2 bg-darkbg text-white focus:outline-none outline-none outline-0'
              value={schemaString}
              placeholder="name:string age:number bio:text"
              onChange={handleSchemaString}
            />
            { !parseParamsString(schemaString) && schemaString.trim() !== '' &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            }
          </div>
          <FormHelperText>Type model parameters in the format of 'paramA:type paramB:type'. Additional paramters may be added later</FormHelperText>
        </FormControl>
        
        {/* INIT WITH CONTROLLER CHECKBOX */}
        <FormControl size="small">
          <div style={{ display: 'flex' }}>
            <div>
              <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                initialize with with controller
              </Typography>
              <FormHelperText>This will create all CRUD routes by default</FormHelperText>
            </div>

            <div style={{ flex: 1 }} />
            <Checkbox
              checked={withController}
              onChange={(e) => setWithController(e.target.checked)}
            />
          </div>
        </FormControl>

        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
          suggested schema
        </Typography>
        <div>
          {schemaRecs.map((param, index) => (
            <Chip
              key={param[0]}
              label={`${param[0]} : ${param[1]}`}
              style={{ margin: '.25em', padding: '0 .5em 0 .5em' }}
              size="small"
              variant={toggleRecs[index] ? 'contained' : 'outlined'}
              color={toggleRecs[index] ? 'primary' : 'default'}
              onClick={() => { toggleSchema(index); }}
            />
          ))}

        </div>

      </Stack>
    </Modal>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name, id, params) => dispatch(addModel(name, id, params)),
  addController: (name, affiliation, id) => dispatch(addController(name, affiliation, id)),
  addRoute: (data) => dispatch(addRoute(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelModal);

const styles = {
  header: {
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
};
