import '../App.css';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Menu, MenuItem,} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ControllerModal from '../components/ControllerModal';
import RouteModal from '../components/RouteModal';
import {deleteController} from '../redux/actions/Controllers';
import ControllerDisplay from '../components/ControllerDisplay';

export default function ControllersPage(props) {
    const dispatch = useDispatch()

    // redux state
    const controllers = useSelector((state) => state.controllersReducer.controllers);
    const routes = useSelector((state) => state.routesReducer.routes);
    const activeController = useSelector((state) => state.sidebarReducer.activeController);

    // local state
    const [openController, setOpenController] = useState(false);
    const [openRoute, setOpenRoute] = useState(false);
    const handleOpenController = () => setOpenController(true);
    const handleCloseController = () => setOpenController(false);
    const handleOpenRoute = () => setOpenRoute(true);
    const handleCloseRoute = () => setOpenRoute(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedController, setSelectedController] = useState(null);
    const openMore = Boolean(anchorEl);

    const handleClickMore = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedController(id);
    };

    const handleCloseMore = () => {
        setAnchorEl(null);
        setSelectedController(null);
    };

    function handleDeleteController() {
        dispatch(deleteController(selectedController));
        handleCloseMore();
    }

    return (
        <div className="flex flex-col items-center w-full mt-8 pb-32">

            <div className='w-full flex flex-col'>

                {/* HEADER */}
                <div className="row flex-1 mr-8 ml-8">
                    <h1>Route Groups</h1>
                    <div style={{flex: 1}}/>

                    <Button
                        style={{marginRight: '2em', width: '12em'}}
                        size="small"
                        variant="contained"
                        onClick={handleOpenController}
                    >
                        Add Controller
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{width: '12em'}}
                        onClick={handleOpenRoute}
                    >
                        Add Route
                    </Button>

                    <a href="https://www.neutrinojs.dev/docs#routes" target="_blank" rel="noreferrer" className='ml-5'>
                        <InfoIcon style={{color: 'lightgray'}}/>
                    </a>
                </div>
            </div>

            {/* SHOW CONTROLLERS */}
            <div className='w-full'>
                {controllers.map((controller, index) => (
                    <ControllerDisplay
                        openMenu={(e) => handleClickMore(e, controller.id)}
                        key={controller.id}
                        controller={controller}
                        routes={routes}
                        activeController={activeController}
                    />
                ))}
            </div>


            <ControllerModal
                open={openController}
                handleClose={handleCloseController}
            />
            <RouteModal
                open={openRoute}
                handleClose={handleCloseRoute}
            />
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={openMore}
                onClose={handleCloseMore}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleDeleteController}>delete controller</MenuItem>
            </Menu>
        </div>
    );
}