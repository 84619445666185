import React, { useEffect, useId, useState } from 'react';
import { connect } from 'react-redux';
import { selectAuth } from '../redux/actions/Auth';
import { ChevronRightIcon } from '@heroicons/react/solid'
import { 
  CalendarIcon, 
  CogIcon,
  LockClosedIcon,
  LockOpenIcon
} from '@heroicons/react/outline';
import { addController } from '../redux/actions/Controllers';
import { addRoute } from '../redux/actions/Routes';
import { addMiddleware } from '../redux/actions/Middlewares';
import { getRandomInt, initMiddlewareObject, initRouteObject } from '../services/helpers';
import { addModel } from '../redux/actions/Models';

const items = [
  {
    name: 'Simple authentication',
    description: 'Simple email-password JWT authentication',
    href: '#',
    iconColor: 'bg-pink-500',
    icon: LockOpenIcon,
    disabled: false,
  },
  {
    name: 'Authentication with email account verification',
    description: 'Coming soon.',
    href: '#',
    iconColor: 'bg-purple-500',
    icon: LockClosedIcon,
    disabled: true,
  },
  {
    name: 'OAuth 2.0',
    description: 'Coming soon.',
    href: '#',
    iconColor: 'bg-yellow-500',
    icon: LockClosedIcon,
    disabled: true,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function AuthPage({
  authConfig,
  selectAuth,
  models,
  addModel,
  addController,
  addRoute,
  addMiddleware,
  controllers,
  middlewares,
}) {
  const [selected, setSelected] = useState(null);
  const id = useId();

  useEffect(() => {
    if (authConfig) {
      setSelected(authConfig);
    };
  }, [authConfig]);

  const handleGenerateAuth = () => {
    selectAuth(selected);
    const userModel = models.find((m) => m.name.toLowerCase() === 'user');
    const prevAuthController = controllers.find(c => c.name.toLowerCase() === 'auth' || c.name.toLowerCase() === 'authentication');
    const prevVerifyJwtMiddleware = middlewares.find(m => m.handler.toLowerCase() === 'verifyjwt');

    /*
     * If user model doesn't alredy exist, create one
     */
    if (!userModel) {
      const newModelId = id + getRandomInt(10000);

      const initParams = [
        { name: 'username', paramType: 'data', dataType: 'String' },
        { name: 'email', paramType: 'data', dataType: 'String' },
        { name: 'password', paramType: 'data', dataType: 'String' }
      ];

      addModel('User', newModelId, initParams);
    }

    let newControllerId = id + getRandomInt(10000);
    if (!prevAuthController) {
      addController('Auth', null, newControllerId);
    } else {
      newControllerId = prevAuthController.id;
    }
    

    const loginRoute = initRouteObject('login', 'User', id + getRandomInt(10000), newControllerId);
    const registerRoute = initRouteObject('register', 'User', id + getRandomInt(10000), newControllerId);

    addRoute(loginRoute);
    addRoute(registerRoute);

    if (!prevVerifyJwtMiddleware) {
      const verifyJWTMiddleware = initMiddlewareObject('verifyJWT', 'User', id + getRandomInt(10000));

      addMiddleware(verifyJWTMiddleware);
    };
  }

  return (
    <div className='flex flex-col items-center mt-8 pb-32'>
      
      {/* HEADING */}
      <div className='w-full flex'>
        <div className="row flex flex-1 mr-8 ml-8">
          <h1>Authentication & Permissions</h1>
          <div className='flex-1'/>
        </div>
      </div>

      { true &&
        <div className="mx-auto max-w-lg mt-8">
          <h2 className="text-lg font-medium text-gray-900">Set up authentication in your project</h2>
          <p className="mt-1 text-sm text-gray-500">Get started by selecting from the following templates. This may be changed later.</p>
          <ul role="list" className="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200">
            {items.map((item, itemIdx) => (
              <li key={itemIdx}>
                <div
                  onClick={() => setSelected(item.name)}
                  className={classNames(
                  !item.disabled && selected === item.name ? 'bg-maingreen' : '',
                  !item.disabled && selected !== item.name ? "hover:bg-gray-100" : '',
                  item.disabled ? "cursor-default" : "cursor-pointer",
                  "group relative flex items-start space-x-3 py-4 pl-2 pr-2",
                )}
                >
                  <div className="flex-shrink-0">
                    <span
                      className={classNames(
                        item.disabled ? 'bg-gray-300' : item.iconColor, 
                        'inline-flex items-center justify-center h-10 w-10 rounded-lg')}
                    >
                      <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div 
                      className={classNames(
                        !item.disabled && selected === item.name ? 'text-white' : 'text-gray-900',
                        "text-sm font-medium"
                      )}
                    >
                      <a href={item.href}>
                        <span className="absolute inset-0" aria-hidden="true" />
                        {item.name}
                      </a>
                    </div>
                    <p 
                      className={classNames(
                        !item.disabled && selected === item.name ? 'text-gray-200' : 'text-gray-500',
                        "text-sm"
                      )}
                    >{item.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon 
                      className={classNames(
                        !item.disabled ? 'group-hover:text-gray-500' : 'text-gray-400',
                        "h-5 w-5 text-gray-400"
                      )}
                      aria-hidden="true" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
          { !authConfig && items.some(i => selected === i.name && !i.disabled) && 
            <div className="mt-6 flex">
              <a 
                onClick={handleGenerateAuth}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                Generate authentication
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          }
        </div>
      }
    </div>
  )
};

/* Redux */
const mapStateToProps = (state) => ({
  authConfig: state.authReducer.authConfig,
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  middlewares: state.middlewaresReducer.middlewares,
});
const mapDispatchToProps = (dispatch) => ({
  selectAuth: (type) => dispatch(selectAuth(type)),
  addModel: (name, id, params) => dispatch(addModel(name, id, params)),
  addController: (name, affiliation, id) => dispatch(addController(name, affiliation, id)),
  addRoute: (data) => dispatch(addRoute(data)),
  addMiddleware: (type, data) => dispatch(addMiddleware(type, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);