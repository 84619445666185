import { createSlice } from '@reduxjs/toolkit'

export const configSlice= createSlice({
    name: 'config',
    initialState: {
        // some other config stuff
        mediaHandlerConfig: {
            databaseType: null,
            // some other params...
        },
        firebaseConfig: { // Will be populated if the user wishes to use firebase.
            apiKey: "", 
            authDomain: "", 
            // databaseURL: "", 
            // projectId: "", 
            // storageBucket: "",
            // messagingSenderId: "", 
            // appId: "", 
        },
        awsConfig: { // Will be populated if the user wishes to use AWS.
            // accessKeyId: "",
            // secretAccessKey: "",
            // region: "",
        },
        otherConfigData: {
            serverPort: 8080, 
            mongoDBAtlas: "", 
        }, 
    },
    reducers: {
        setDatabaseType: (state, action) => {
            state.mediaHandlerConfig.databaseType = action.payload.databaseType
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDatabaseType } = configSlice.actions

export default configSlice.reducer