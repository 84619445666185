import { 
  ADD_BLOCK_MIDDLEWARE,
  ADD_MIDDLEWARE, 
  DELETE_BLOCK_MIDDLEWARE, 
  DELETE_MIDDLEWARE, 
  EDIT_BLOCK_MIDDLEWARE, 
  EDIT_MIDDLEWARE, 
  MOVE_BLOCK_MIDDLEWARE, 
  RESET_MIDDLEWARES, 
  SAVE_ACTIVE_MIDDLEWARE, 
  SET_ACTIVE_MIDDLEWARE_FROM_ID,
  SET_MIDDLEWARES,
  TOGGLE_CALLBACK_BLOCK_MIDDLEWARE
} from "../Types";


export const addMiddleware = (data) => ({
  type: ADD_MIDDLEWARE,
  data,
});

export const editMiddleware = (key, data) => ({
  type: EDIT_MIDDLEWARE,
  key,
  data,
});

export const deleteMiddleware = (key) => ({
  type: DELETE_MIDDLEWARE,
  key,
});

export const setActiveMiddlewareFromId = (id) => ({
  type: SET_ACTIVE_MIDDLEWARE_FROM_ID,
  id,
});

export const saveActiveMiddleware = () => ({
  type: SAVE_ACTIVE_MIDDLEWARE,
});

export const addBlockMiddleware = (index, data, parentId, variant = 'success') => ({
  type: ADD_BLOCK_MIDDLEWARE,
  index,
  data,
  parentId,
  variant,
});

export const editBlockMiddleware = (key, data) => ({
  type: EDIT_BLOCK_MIDDLEWARE,
  data,
  key,
});

export const deleteBlockMiddleware = (id) => ({
  type: DELETE_BLOCK_MIDDLEWARE,
  key: id,
});

export const moveBlockMiddleware = (blockId, index, parentId, variant) => ({
  type: MOVE_BLOCK_MIDDLEWARE,
  blockId,
  index,
  parentId,
  variant,
});

export const toggleCallbackMiddleware = (key) => ({
  type: TOGGLE_CALLBACK_BLOCK_MIDDLEWARE,
  key,
});

export const resetMiddlewares = () => ({
  type: RESET_MIDDLEWARES,
});

export const setMiddlewares = (data) => ({
  type: SET_MIDDLEWARES,
  data,
});