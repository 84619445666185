import {
  ADD_BLOCK,
  EDIT_BLOCK,
  DELETE_BLOCK,
  SET_CURR_BLOCK_DATA,
  SET_ACTIVE_ROUTE,
  SET_ACTIVE_ROUTE_FROM_ID,
  ADD_ROUTE,
  DELETE_ROUTE,
  MOVE_BLOCK,
  SET_DRAGGING,
  SAVE_ACTIVE_ROUTE,
  TOGGLE_OPEN_BLOCK,
  TOGGLE_CALLBACK_BLOCK,
  TRIGGER_REFRESH,
  TOGGLE_MIDDLEWARE,
  EDIT_ROUTE,
  SET_ROUTES,
  SET_ROUTE_MIDDLEWARES,
  RESET_ROUTES,
} from '../Types';

export const setRoutes = (data) => ({
  type: SET_ROUTES,
  data,
});

export const setDragging = (data) => ({
  type: SET_DRAGGING,
  data,
});

export const addBlock = (index, data, parentId, variant = 'success') => ({
  type: ADD_BLOCK,
  index,
  data,
  parentId,
  variant,
});

export const editBlock = (key, data) => ({
  type: EDIT_BLOCK,
  data,
  key,
});

export const deleteBlock = (id) => ({
  type: DELETE_BLOCK,
  key: id,
});

export const setCurrBlockData = (data) => ({
  type: SET_CURR_BLOCK_DATA,
  data,
});

export const setActiveRoute = (data) => ({
  type: SET_ACTIVE_ROUTE,
  data,
});

export const saveActiveRoute = () => ({
  type: SAVE_ACTIVE_ROUTE,
});

export const setActiveRouteFromId = (id) => ({
  type: SET_ACTIVE_ROUTE_FROM_ID,
  id,
});

/**
 * @export
 * @param {object} data
 */
export const addRoute = (data) => ({
  type: ADD_ROUTE,
  data,
});

export const editRoute = (key, data) => ({
  type: EDIT_ROUTE,
  key,
  data,
});

export const resetRoutes = () => ({
  type: RESET_ROUTES,
});

export const setRouteMiddlewares = (key, data) => ({
  type: SET_ROUTE_MIDDLEWARES,
  key,
  data,
});

export const deleteRoute = (key) => ({
  type: DELETE_ROUTE,
  key,
});

export const toggleMiddleware = (key) => ({
  type: TOGGLE_MIDDLEWARE,
  key,
});

export const moveBlock = (blockId, index, parentId, variant) => ({
  type: MOVE_BLOCK,
  blockId,
  index,
  parentId,
  variant,
});

export const toggleOpenBlock = (key) => ({
  type: TOGGLE_OPEN_BLOCK,
  key,
});

export const toggleCallback = (key) => ({
  type: TOGGLE_CALLBACK_BLOCK,
  key,
});

export const triggerRefresh = () => ({
  type: TRIGGER_REFRESH,
});
