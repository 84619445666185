import { configureStore } from '@reduxjs/toolkit'
import ActiveProjectReducer from './reducers/ActiveProjectReducer';
import ControllersReducer from './reducers/ControllersReducer';
import RoutesReducer from './reducers/RoutesReducer';
import ModelsReducer from './reducers/ModelsReducer';
import WarningsReducer from './reducers/WarningsReducer';
import SidebarReducer from './reducers/SidebarReducer';
import MiddlewaresReducer from './reducers/MiddlewaresReducer';
import TerminalReducer from './reducers/TerminalReducer';
import AuthReducer from './reducers/AuthReducer';
import RelationsReducer from './reducers/RelationsReducer';
import mailersReducer from "./MailersSlice";
import configReducer from "./ConfigSlice";

export default configureStore({
  reducer: {
    modelsReducer: ModelsReducer,
    activeProjectReducer: ActiveProjectReducer,
    warningsReducer: WarningsReducer,
    controllersReducer: ControllersReducer,
    routesReducer: RoutesReducer,
    sidebarReducer: SidebarReducer,
    middlewaresReducer: MiddlewaresReducer,
    terminalReducer: TerminalReducer,
    authReducer: AuthReducer,
    relationsReducer: RelationsReducer,
    mailersReducer: mailersReducer,
    configReducer: configReducer, 
  },
})

