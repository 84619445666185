import React, {
  useState, useEffect, useRef, useLayoutEffect, useContext
} from 'react';
import {
  Typography, Stack, Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { addModel, removeModel } from '../../redux/actions/Models';
import { isAlphaNumeric } from '../../services/helpers';
import CallbackDisplay from './CallbackDisplay';
import { toggleCallback, editBlock } from '../../redux/actions/Routes';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import CheckboxInput from './CheckboxInput';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';
import { EditorContext } from './EditorContext';

function UpdateBlock({
  models,
  blockVariant,
  opened,
  id,
  toggleCallback,
  showCallback,
  saveCount,
  editBlock,
  editBlockMiddleware,
  blockData,
}) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [params, setParams] = useState(blockData.params || '');
  const [updateParams, setUpdateParams] = useState(blockData.updateParams || '');
  const [varName, setVarName] = useState(blockData.varName || '');
  const [model, setModel] = useState(blockData.model || '');
  const [multiple, setMultiple] = useState(blockData.multiple || false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (editorVariant === 'middleware') {
      editBlockMiddleware(id, {
        ...blockData, params, updateParams, varName, model, multiple,
      });
    } else {
      editBlock(id, {
        ...blockData, params, updateParams, varName, model, multiple,
      });
    }
  }, [params, updateParams, varName, model, multiple]);

  const handleSetVarName = (e) => {
    if (isAlphaNumeric(e.target.value)) {
      setVarName(e.target.value);
    }
  };

  return (
    <div style={{ ...styles.main, padding: opened ? '1em' : '0' }}>
      { opened
    && (
    <Stack spacing={2}>

      <Grid container spacing={0}>

        {/* PARAMS */}
        <Grid item xs={7} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            params:
          </Typography>
          <TextInput
            type="text"
            placeholder="{ username: 'joe' }"
            value={params}
            onChange={(e) => setParams(e.target.value)}
          />
        </Grid>

        {/* MODEL SELECT */}
        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            from:
          </Typography>
          <SelectInput
            value={model}
            onChange={(e) => setModel(e.target.value)}
          >
            {models.map((model) => (
              <option value={model.name}>
                {model.name}
              </option>
            ))}
          </SelectInput>
        </Grid>

        {/* SELECT MANY */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            multiple:
          </Typography>
          <CheckboxInput
            checked={multiple}
            onChange={(e) => setMultiple(e.target.checked)}
          />
        </Grid>

        {/* UPDATE PARAMS */}
        <Grid item xs={7} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            update params:
          </Typography>
          <TextInput
            placeholder="{ username: 'newUsername', age: 18 }"
            value={updateParams}
            onChange={(e) => setUpdateParams(e.target.value)}
          />
        </Grid>

        {/* VAR NAME */}
        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            var name:
          </Typography>
          <TextInput
            placeholder="data"
            value={varName}
            onChange={handleSetVarName}
          />
        </Grid>

        {/* ENABLE CALLBACK */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            enable callback:
          </Typography>
          <CheckboxInput
            checked={showCallback}
            onChange={(e) => toggleCallback(id)}
          />
        </Grid>
      </Grid>

      { showCallback
    && <CallbackDisplay parentId={id} />}

    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  withCallback: state.routesReducer.withCallback,
  saveCount: state.routesReducer.saveCount,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  toggleCallback: (key) => dispatch(toggleCallback(key)),
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBlock);

const styles = {
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  callbackBox: {
    borderRadius: '.5em',
    border: '1px dashed lightgray',
    padding: '.25em',
    minHeight: '1em',
  },
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    marginLeft: '.5em',
    marginTop: 0,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: '1em',
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
};
