export const QUERY_HELP = 
{
  title: "Queries item from database",
  example: "query from: User, query by: { name: 'joe' }"
}
export const CREATE_HELP =  
{
  title: "Creates item to database",
  example: "create to: User, data: { name: 'joe', age: 24 }"
}
export const UPDATE_HELP =
{
  title: "Updates item from database",
  example: "edit from: User, update if: { name: 'joe' }, updated data: { name: 'newName', newAge: 25 }"
}
export const DELETE_HELP = 
{
  title: "Deletes item from database",
  example: "delete from User, delete if: {{ name: 'joe' }}"
}

export const ERROR_HELP = 
{
  title: "Throws a custom error",
  example: "return status 500, message: 'error updating database'"
}

export const RETURN_HELP = 
{
  title: "Returns success data or a custom message",
  example: "return status 200, message: 'successfully updated!'"
}

export const CUSTOM_HELP = 
{
  title: "Allows you to insert a custom block of code",
  example: "console.log('this is a custom piece of code')"
}