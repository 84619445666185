import React, {
  useState, useRef, useLayoutEffect, useContext
} from 'react';
import {
  Typography, Stack, Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { editBlock } from '../../redux/actions/Routes';
import CheckboxInput from './CheckboxInput';
import TextInput from './TextInput';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';
import { EditorContext } from './EditorContext';

function ReturnBlock(props) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [data, setData] = useState(props.blockData.data || false);
  const [returnContent, setReturnContent] = useState(props.blockData.returnContent || '');
  const [status, setStatus] = useState(props.blockData.status || 200);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (editorVariant === 'middleware') {
      props.editBlockMiddleware(props.id, {
        ...props.blockData, data, returnContent, status,
      });
    } else {
      props.editBlock(props.id, {
        ...props.blockData, data, returnContent, status,
      });
    }
  }, [data, returnContent, status]);

  return (
    <div style={{ ...styles.main, padding: props.opened ? '1em' : '0' }}>
      { props.opened
    && (
    <Stack spacing={0}>

      <Grid container spacing={0}>

        {/* STATUS */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            status:
          </Typography>
          <TextInput
            type="number"
            placeholder="200"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </Grid>

        {/* RETURN CONTENT */}
        <Grid item xs={9} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            {data ? 'return content:' : 'message:'}
          </Typography>
          <TextInput
            placeholder={data ? "{ data: 'return data', content: 'content' }" : 'successfully updated!'}
            value={returnContent}
            onChange={(e) => setReturnContent(e.target.value)}
          />
        </Grid>

        {/* DATA */}
        <Grid item xs={1} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            data:
          </Typography>
          <CheckboxInput
            checked={data}
            onChange={(e) => setData(e.target.checked)}
          />
        </Grid>

      </Grid>

    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  saveCount: state.routesReducer.saveCount,
});
const mapDispatchToProps = (dispatch) => ({
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnBlock);

const styles = {
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    marginLeft: '.5em',
    marginTop: 0,
  },
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: '1em',
  },
};
