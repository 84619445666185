import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import PrivateRoute from './Auth/PrivateRoute';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import store from './redux/Store';
import './index.css';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import VerifyEmail from './Auth/VerifyEmail';
import Login from './Auth/Login';
import {UserContext} from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import RequestVerificationEmail from './Auth/RequestVerificationEmail';
import Register from './Auth/Register';
import Main from './Main';

const container = document.getElementById('root');
const root = createRoot(container);

function ReduxMain() {
    const {user, setUser} = useFindUser();

    return (
        <div style={{height: "100vh"}}>
            <Provider store={store}>
                <UserContext.Provider value={{authUser: user, setAuthUser: setUser}}>
                    <DndProvider backend={HTML5Backend}>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element={<PrivateRoute component={<Main/>}/>}
                                />
                                <Route path="/*" element={<Main/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/register" element={<Register/>}/>
                                <Route path="/forgotpassword" element={<ForgotPassword/>}/>
                                <Route path="/resetpassword/:token" element={<ResetPassword/>}/>
                                <Route path="/users/:id/verify/:token" element={<VerifyEmail/>}/>
                                <Route path="/request-verification-email" element={<RequestVerificationEmail/>}/>
                            </Routes>
                        </BrowserRouter>
                    </DndProvider>
                </UserContext.Provider>
            </Provider>
        </div>
    );
}

root.render(<ReduxMain/>);
