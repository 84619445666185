/*
 * Combine Reducers
 * Used for saving projects to DB
 */
export const combineReducers = (controllerParams, modelParams, routeParams, middlewareParams, relationParams, mailerParams, authParams) => {
    const dbData = {
        controllerParams,
        modelParams,
        routeParams,
        middlewareParams,
        relationParams,
        mailerParams,
        authParams,
    };

    return dbData;
};

/*
 * Validate email
 */
export const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );


/*
 * Takes in redux model object, converts to appropriate backend model JSON schema
 */
export function translateModel(m) {
    /*
     * Backend model schema
     */
    const newModel = {
        model_name: m.name,
        id: m.id,
        auth: m.auth,
        schema: [],
        has_many: [],
        belongs_to: [],
    };
    if (m.belongsTo) {
        newModel.belongs_to = m.belongsTo; // add alias
    }
    for (const p of m.params) {
        newModel.schema.push({
            name: p.name,
            required: true,
            type: p.dataType,
        });
    }
    for (const x of m.hasMany) {
        newModel.has_many.push(x); // add alias
    }
    return newModel;
}

/*
 * Route translation semantics
 */
export function translateRouteName(routeName) {
    switch (routeName) {
        // case 'index':
        //   return 'all';
        // case 'show':
        //   return 'find';
        // case 'create':
        //   return 'create';
        // case 'update':
        //   return 'update';
        case 'destroy':
            return 'delete';
        default:
            return routeName;
    }
}

/*
 * Backend Model schema example
 */

/*
{
  "model_name": "user",
  "auth": true,
  "schema": [
    {
      "name": "name",
      "type": "String",
      "required": true
    },
    {
      "name": "charity",
      "type": "String",
      "required": true
    },
    {
      "name": "username",
      "type": "String",
      "required": true
    },
    {
      "name": "email",
      "type": "String",
      "required": true
    },
    {
      "name": "password",
      "type": "String",
      "required": true
    }
  ],
  "has_many": [
    ["paymentmethod", "paymentMethods"],
    ["workoutgroup", "workoutGroups"],
    ["monthlypledge", "monthlyPledges"],
    ["user", "friends"]
  ],
  "belongs_to": [],
  "routes": [
    {
      "route": "index",
      "middleware": "verifyJWT",
      "logic": " "
    },
    {
      "route": "show",
      "middleware": "verifyJWT",
      "logic": " "
    },
    {
      "route": "create",
      "middleware": "",
      "logic": " "
    },
    {
      "route": "update",
      "middleware": "verifyJWT",
      "logic": " "
    },
    {
      "route": "delete",
      "middleware": "verifyJWT",
      "logic": ""
    }
  ]
}
*/
