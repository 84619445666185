import '../App.css';
import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import {
  addModel,
  addParam,
  editModel,
  removeModel,
  removeParam,
  removeRelation,
  addBelongsTo,
  toggleAuth,
  removeBelongsTo,
} from '../redux/actions/Models';
import NewParamBox from './newParamBox';
import {isAlphaNumeric, pluralize} from '../services/helpers';
import {getModelFromId} from "../util/modelsUtil";

function ModelBox(props) {
  const [name, setName] = useState(props.model.name);
  const [openParamBox, setOpenParamBox] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const modelRef = useRef();

  useEffect(() => {
    if (props.model.id === props.activeModel) {
      modelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [props.activeModel]);

  useEffect(() => {
    if (name !== props.model.name) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [name, props.model]);

  useEffect(() => {
    setName(props.model.name);
  }, [props.models.length]);

  const handleUpdate = (val) => {
    if (isAlphaNumeric(val)) {
      const newModel = { ...props.model, name: val };
      props.editModel(props.model.id, newModel);
    }
  };

  const handleDelete = () => {
    props.removeModel(props.model.id);
  };

  function getChipColor(name, auth) {
    switch (name) {
      case 'id':
        return 'warning';
      case 'username':
        if (auth) { return 'primary'; }
        return 'default';
      case 'email':
        if (auth) { return 'primary'; }
        return 'default';
      case 'password':
        if (auth) { return 'primary'; }
        return 'default';
      default:
        return 'default';
    }
  }

  /*
   * variant is 1 if current model is model B
   */
  const getRelString = (relation, variantB) => {
      if (relation.relation_type === "many-to-many") {
        if (relation.model_a === relation.model_b) {
          return `${relation.field_a}, ${relation.field_b} (many-to-many): Self`
        }
        return `${variantB ? relation.field_b : relation.field_a} (many-to-many): `
      }
      else if (relation.relation_type === "one-to-many") {
        if (variantB) {
          return `${relation.field_b} (one-to-[many]): ${getModelFromId(props.models, relation.model_a).name}`
        } else {
          return `${relation.field_a} ([one]-to-many): ${pluralize(getModelFromId(props.models, relation.model_b).name)}`
        }
      }
      return ""
  }

  return (
    <div className="col" ref={modelRef}>
      <Typography variant="caption" display="block" style={styles.idTitle}>
        {props.model.id}
      </Typography>

      <div className="row">
        <Paper
          elevation={0}
          variant="outlined"
          style={{
            padding: '1em', flex: 1, marginRight: '1em',
          }}
        >

          {/* MODEL NAME TEXT FIELD */}
          <div className="row">
            <TextField
              label="ModelName"
              variant="outlined"
              size="small"
              style={{ marginRight: 15 }}
              value={props.model.name}
              onChange={(e) => handleUpdate(e.target.value)}
            />
          </div>

          {/* RELATIONS DISPLAY */}
          <div className="row" style={{ marginTop: 10, flexWrap: 'wrap' }}>
            {props.relations.filter(r => r.model_a === props.model.id || r.model_b === props.model.id).map((rel, key) => (
                <Chip
                    size="small"
                    key={key}
                    label={getRelString(rel, rel.model_b === props.model.id)} // many[0] is many name, many[1] is alias
                    onClick={() => {props.openRelEdit(rel)}}
                    style={{ margin: 5 }}
                />
            ))}
          </div>

          {/* ADD PARAM BUTTON */}
          <div className="row" style={{ marginTop: 10, flexWrap: 'wrap' }}>
            <Button
              size="small"
              variant={openParamBox ? 'outlined' : 'contained'}
              style={{ margin: '0 .5em .5em 0' }}
              onClick={() => setOpenParamBox(!openParamBox)}
              color={openParamBox ? 'error' : 'primary'}
            >
              {openParamBox ? 'Cancel' : 'Add Param'}
            </Button>

            {/* PARAMS DISPLAY */}
            <Chip
              label="_id: ObjectId"
              onClick={() => {}}
              style={{ margin: 5 }}
              size="small"
            />
            {props.model.params.length > 0
              ? props.model.params.map((param, key) => (
                <Chip
                  size="small"
                  color={getChipColor(param.name, props.model.auth)}
                  key={key}
                  label={`${param.name}: ${param.dataType}`}
                  onClick={() => {}}
                  onDelete={() => props.removeParam(props.model.id, param.name)}
                  style={{ margin: 5 }}
                />
              ))
              : (
                <div
                  style={{ marginLeft: 15, color: 'gray', textAlign: 'left' }}
                >
                  This model has no params, click on 'ADD PARAM' to create a new model parameter
                  e.g. username
                </div>
              )}
          </div>
        </Paper>

        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon color="error" />
        </IconButton>
      </div>

      {openParamBox && <NewParamBox model={props.model} close={() => setOpenParamBox(false)} />}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  relations: state.relationsReducer.relations,
  activeModel: state.sidebarReducer.activeModel,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  editModel: (id, model) => dispatch(editModel(id, model)),
  removeModel: (key) => dispatch(removeModel(key)),
  addParam: (modelId, param) => dispatch(addParam(modelId, param)),
  removeParam: (modelId, paramName) => dispatch(removeParam(modelId, paramName)),
  addBelongsTo: (modelId, data) => dispatch(addBelongsTo(modelId, data)),
  removeRelation: (modelId, data) => dispatch(removeRelation(modelId, data)),
  removeBelongsTo: (modelId, data) => dispatch(removeBelongsTo(modelId, data)),
  toggleAuth: (modelId, data) => dispatch(toggleAuth(modelId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelBox);

const styles = {
  idTitle: {
    fontWeight: 'bold',
    opacity: 0.4,
    marginLeft: '.5em',
    marginTop: '1em',
    textAlign: 'left',
    fontSize: '.5em',
  },
};
