import React from 'react';
import {Stack} from '@mui/material';
import {connect} from 'react-redux';
import LogicBlock from './LogicBlock';
import NewBlock from './NewBlock';
import {EditorContext} from './EditorContext';

function LogicBlockContainer(props) {
    const editorVariant = props.variant || 'route';
    let sequence = editorVariant === 'route'
        ? props.activeSequence
        : props.activeSequenceMiddleware;

    return (
        <div style={styles.container}>
            <EditorContext.Provider value={props.variant || 'route'}>
                <Stack spacing={0.5}>
                    <NewBlock index={-1} separator={props.activeSequence.length > 0}/>
                    <div style={{height: '.5em'}}/>
                    {sequence.map((block, index) => (
                        <div key={block.id}>
                            <LogicBlock
                                blockData={block}
                                blockVariant={block.blockVariant}
                                params={block.params}
                                varName={block.varName}
                                model={block.model}
                                id={block.id}
                                index={index}
                                mailerId={block.mailerId}
                            />
                            <NewBlock separator={index !== props.activeSequence.length - 1} index={index}/>
                        </div>
                    ))}
                </Stack>
            </EditorContext.Provider>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    controllers: state.controllersReducer.controllers,
    activeSequence: state.routesReducer.activeSequence,
    activeSequenceMiddleware: state.middlewaresReducer.activeSequence,
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LogicBlockContainer);

const styles = {
    container: {
        borderRadius: '.5em',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '5em',
    },
};
