import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Menu } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isAlphaNumeric } from '../../services/helpers';
import { deleteMiddleware, editMiddleware, setActiveMiddlewareFromId } from '../../redux/actions/Middlewares';

function MiddlewareDisplay(props) {
  const { middleware } = props;
  const [handler, setHandler] = useState(middleware.handler);
  const [editMode, setEditMode] = useState(middleware.handler.includes('untitledMiddleware'));
  const navigate = useNavigate();

  /* Edit Menu */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickMenu = (option) => {
    switch (option) {
      case 'config':
        setEditMode(true);
        break;
      case 'delete':
        props.deleteMiddleware(middleware.id);
        break;
      case 'logic':
        handleClickEdit(middleware.id);
        break;
      default:
        setEditMode(false);
    }
    setAnchorEl(null);
  };
  // edit button
  const handleClickEditButton = (e) => {
    // SAVE
    if (editMode) {
      setEditMode(false);
      props.editMiddleware(middleware.id, { handler });
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  // gear icon
  const handleClickEdit = (id) => {
    navigate('/middleware-editor');
    props.setActiveMiddlewareFromId(id);
  };

  const handleChangeHandler = (e) => {
    const str = e.target.value;
    if (!str.includes(' ')) {
      setHandler(e.target.value);
    }
  };

  const cbxWid = '4em';

  return (
    <div className='flex-1 m-8 mt-4 mb-0'>
      <div
        className="row"
        style={{
          borderWidth: 1,
          borderColor: '#bfd9ef',
          backgroundColor: '#e5f0f9',
          borderRadius: 2,
          borderStyle: 'solid',
          marginTop: 5,
          padding: 3,
        }}
      >


        {/* Handler */}
        <div style={{ flex: 2, textAlign: 'left', marginLeft: '1em' }}>
          { editMode
            ? (
              <input
                type="data"
                placeholder={middleware.handler}
                style={styles.textInput}
                value={handler}
                onChange={handleChangeHandler}
              />
            )
            : handler}
        </div>

        {/* logic */}
        <div style={{
          width: cbxWid, display: 'flex', justifyContent: 'center',
        }}
        >
          <IconButton
            color={props.middlewares.find((m) => m.id === middleware.id).logic.length > 0 ? 'primary' : 'warning'}
            onClick={() => handleClickEdit(middleware.id)}
          >
            <SettingsIcon style={{ fontSize: 20 }} />
          </IconButton>
        </div>

        {/* EDIT BUTTON */}
        <Button
          style={{ marginRight: '.5em', marginLeft: '1em' }}
          variant={editMode ? 'contained' : 'outlined'}
          size="small"
          onClick={handleClickEditButton}
        >
          {editMode ? 'save' : 'edit'}
        </Button>

      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickMenu('config')}>Config</MenuItem>
        <MenuItem onClick={() => handleClickMenu('logic')}>Logic</MenuItem>
        <MenuItem onClick={() => handleClickMenu('delete')}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  middlewares: state.middlewaresReducer.middlewares,
});
const mapDispatchToProps = (dispatch) => ({
  editMiddleware: (id, data) => dispatch(editMiddleware(id, data)),
  deleteMiddleware: (id) => dispatch(deleteMiddleware(id)),
  setActiveMiddlewareFromId: (id) => dispatch(setActiveMiddlewareFromId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddlewareDisplay);

const styles = {
  textInput: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: 'black',
    borderWidth: 1,
    padding: 3,
    borderRadius: 2,
  },
};
