import { toCamelCase } from "../util/caseMatching";

export function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
};

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

// export function isValidVarName(str) {
//   if (
//     !(e.target.value).includes(" ") && 
//     !(e.target.value).includes("-") &&
//     !(e.target.value).includes("{") && 
//     !(e.target.value).includes("}") &&
//     !(e.target.value).includes("[") && 
//     !(e.target.value).includes("]") &&
//     !(e.target.value).includes("\\") && 
//     !(e.target.value).includes("/")
//   ) {
//     return true;
//   }
//   return false;
// }

export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) &&  // numeric (0-9)
        !(code > 64 && code < 91) &&  // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(str.charAt(i) === "_")
      ) {
      return false;
    }
  }
  return true;
};

/*
 * Validate email
 */
export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export function isValidUrl(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) &&  // numeric (0-9)
        !(code > 64 && code < 91) &&  // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(str.charAt(i) === "_") &&
        !(str.charAt(i) == "?" || str.charAt(i) == "/")
      ) {
      return false;
    }
  }
  return true;
};

// export const pluralize = (str) => {

// }

/**
 * Returns the plural of an English word.
 *
 * @export
 * @param {string} word
 * @param {number} [amount]
 * @returns {string}
 */
export function pluralize(word, amount) {
  if (amount !== undefined && amount === 1) {
      return word
  }
  const plural = {
      '(quiz)$'               : "$1zes",
      '^(ox)$'                : "$1en",
      '([m|l])ouse$'          : "$1ice",
      '(matr|vert|ind)ix|ex$' : "$1ices",
      '(x|ch|ss|sh)$'         : "$1es",
      '([^aeiouy]|qu)y$'      : "$1ies",
      '(hive)$'               : "$1s",
      '(?:([^f])fe|([lr])f)$' : "$1$2ves",
      '(shea|lea|loa|thie)f$' : "$1ves",
      'sis$'                  : "ses",
      '([ti])um$'             : "$1a",
      '(tomat|potat|ech|her|vet)o$': "$1oes",
      '(bu)s$'                : "$1ses",
      '(alias)$'              : "$1es",
      '(octop)us$'            : "$1i",
      '(ax|test)is$'          : "$1es",
      '(us)$'                 : "$1es",
      '([^s]+)$'              : "$1s"
  }
  const irregular = {
      'move'   : 'moves',
      'foot'   : 'feet',
      'goose'  : 'geese',
      'sex'    : 'sexes',
      'child'  : 'children',
      'man'    : 'men',
      'tooth'  : 'teeth',
      'person' : 'people'
  }
  const uncountable = [
      'sheep',
      'fish',
      'deer',
      'moose',
      'series',
      'species',
      'money',
      'rice',
      'information',
      'equipment',
      'bison',
      'cod',
      'offspring',
      'pike',
      'salmon',
      'shrimp',
      'swine',
      'trout',
      'aircraft',
      'hovercraft',
      'spacecraft',
      'sugar',
      'tuna',
      'you',
      'wood'
  ]
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
      return word
  }
  // check for irregular forms
  for (const w in irregular) {
      const pattern = new RegExp(`${w}$`, 'i')
      const replace = irregular[w]
      if (pattern.test(word)) {
          return word.replace(pattern, replace)
      }
  }
  // check for matches using regular expressions
  for (const reg in plural) {
      const pattern = new RegExp(reg, 'i')
      if (pattern.test(word)) {
          return word.replace(pattern, plural[reg])
      }
  }
  return word
}


/**
 * @export
 * @param {string} type
 * @param {string} model
 * @param {string} id
 * @param {string} controllerId
 * @return {object} newRoute
 */
export const initRouteObject = (type, model, id, controllerId, modelData=null) => {
  let newRoute = {
    controller: controllerId,
    id: id,
    middleware: [],
    logic: []
  }

  let modelParams = modelData ? modelData.params : null
  let paramsString = ''
  if (modelData) {
    let i = 0;
    for (let p of modelParams) {
      paramsString += p['name'] + `${i < modelParams.length-1 ? ', ' : ''}`;
      i++;
    }
  }

  switch (type) {
    case 'index':
      newRoute.url = `/${pluralize(model).toLowerCase()}`
      newRoute.handler = 'index'
      newRoute.verb = 'get'
      newRoute.logic = [
        {
          id: id + getRandomInt(10000),
          blockVariant: 'query',
          varName: 'data',
          params: '{}',
          model: model,
          multiple: true,
        },
        {
          id: id + getRandomInt(10000),
          blockVariant: 'return',
          status: 200,
          data: true,
          returnContent: "data"
        },
      ]
      break;
    case 'show':
      newRoute.url = `/${pluralize(model).toLowerCase()}/:id`
      newRoute.handler = 'show'
      newRoute.verb = 'get'
      newRoute.logic = [
        {
          id: id + getRandomInt(10000),
          blockVariant: 'query',
          varName: 'data',
          params: '{ _id: id }',
          model: model,
          multiple: false,
        },
        {
          id: id + getRandomInt(10000),
          blockVariant: 'return',
          status: 200,
          data: true,
          returnContent: "data"
        },
      ]
      break;
    case 'create':
      newRoute.url = `/${pluralize(model).toLowerCase()}`
      newRoute.handler = 'create'
      newRoute.verb = 'post'
      newRoute.logic = [
        {
          id: id + getRandomInt(10000),
          blockVariant: 'create',
          varName: 'newData',
          model: model,
          fields: `{ ${paramsString} }`,
          success: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'return',
              status: 200,
              data: false,
              returnContent: `New ${model} was successfully created!`
            },
          ],
          error: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'error',
              status: 500,
              returnContent: `Error creating new ${model}`
            },
          ]
        },
      ]
      break;
    case 'update':
      newRoute.url = `/${pluralize(model).toLowerCase()}/:id`
      newRoute.handler = 'update'
      newRoute.verb = 'put'
      newRoute.logic = [
        {
          id: id + getRandomInt(10000),
          blockVariant: 'update',
          varName: 'newData',
          params: '{ _id: id }',
          updateParams: `{ ${paramsString} }`,
          model: model,
          multiple: false,
          success: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'return',
              status: 200,
              data: false,
              returnContent: `${model} was successfully updated!`
            },
          ],
          error: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'error',
              status: 500,
              returnContent: `Error updating ${model}`
            },
          ]
        },
      ]
      break;
    case 'delete':
      newRoute.url = `/${pluralize(model).toLowerCase()}/:id`
      newRoute.handler = 'delete'
      newRoute.verb = 'delete'
      newRoute.logic = [
        {
          id: id + getRandomInt(10000),
          blockVariant: 'delete',
          varName: 'data',
          params: '{ _id: id }',
          model: model,
          multiple: false,
          success: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'return',
              status: 200,
              data: false,
              returnContent: `${model} was successfully deleted`
            },
          ],
          error: [
            {
              id: id + getRandomInt(10000),
              blockVariant: 'error',
              status: 500,
              returnContent: `Error deleting ${model}`
            },
          ]
        },
      ]
      break;
    case 'login':
      newRoute.url = `/auth/login`
      newRoute.handler = 'login'
      newRoute.verb = 'post'
      newRoute.logic = [
        {
          id: ":r1m:242616",
          blockVariant: "query",
          model: model,
          success: [],
          error: [],
          params: "{ username: req.body.username }",
          varName: toCamelCase(model),
          multiple: false
        },
        {
          id: ":r1n:514524",
          blockVariant: "if",
          model: model,
          success: [
            {
              id: ":r1r:760210",
              blockVariant: "error",
              model: model,
              success: [],
              error: [],
              returnContent: "Invalid username",
              status: "400"
            }
          ],
          error: [],
          condition: `!${toCamelCase(model)}`
        },
        {
          id: ":r1p:206332",
          blockVariant: "BCrypt",
          model: model,
          success: [],
          error: [],
          varName: 'checkpassword',
          bcryptVariant: "compare",
          plainText: "req.body.password",
          hash: `${toCamelCase(model)}.password`,
          saltRounds: 10
        },
        {
          id: ":r21:861147",
          blockVariant: "if",
          model: model,
          success: [
            {
              id: ":r2p:782585",
              blockVariant: "error",
              model: model,
              success: [],
              error: [],
              returnContent: "Incorrect password",
              status: "400"
            }
          ],
          error: [],
          condition: "!checkpassword"
        },
        {
          id: ":r4i:677099",
          blockVariant: "JWT",
          model: model,
          success: [
            {
              id: ":r5b:728547",
              blockVariant: "return",
              model: model,
              success: [],
              error: [],
              data: true,
              returnContent: `{ id: ${toCamelCase(model)}._id.toString(), token: 'Bearer ' + token }`,
              status: 200
            }
          ],
          error: [
            {
              id: ":r5r:345859",
              blockVariant: "error",
              returnContent: "Error generating token",
              model: model,
              success: [],
              error: []
            }
          ],
          jwtVariant: "sign",
          payload: `{ id: ${toCamelCase(model)}._id.toString(), username: ${toCamelCase(model)}.username }`,
          secret: "'PLEASE_CHANGE'",
          token: '',
          expiration: 86400
        }
      ]
      break;
    case 'register':
      newRoute.url = `/auth/register`
      newRoute.handler = 'register'
      newRoute.verb = 'post'
      newRoute.logic = [
        {
          id: ":rd:302872",
          blockVariant: "custom",
          model: "User",
          success: [],
          error: [],
          code: "const user = req.body;"
        },
        {
          id: ":ra:109605",
          blockVariant: "query",
          model: "User",
          success: [],
          error: [],
          params: "{ username: user.username }",
          varName: "takenUsername",
          multiple: false
        },
        {
          id: ":rc:457039",
          blockVariant: "query",
          model: "User",
          success: [],
          error: [],
          params: "{ email: user.email }",
          varName: "takenEmail",
          multiple: false
        },
        {
          id: ":rd:240898",
          blockVariant: "if",
          model: "User",
          success: [
            {
              id: ":rh:480136",
              blockVariant: "error",
              model: "User",
              success: [],
              error: [],
              returnContent: "Username already exists",
              status: 500
            }
          ],
          error: [],
          condition: "takenUsername"
        },
        {
          id: ":rf:337148",
          blockVariant: "if",
          model: "User",
          success: [
            {
              id: ":rm:684382",
              blockVariant: "error",
              model: "User",
              success: [],
              error: [],
              returnContent: "Email already exists",
              status: 500
            }
          ],
          error: [],
          condition: "takenEmail"
        },
        {
          id: ":rj:967444",
          blockVariant: "BCrypt",
          varName: 'userpassword',
          bcryptVariant: 'hash',
          plainText: 'req.body.password',
          saltRounds: 10,
          success: [],
          error: []
        },
        {
          id: ":rd:145775",
          blockVariant: "custom",
          model: "User",
          success: [],
          error: [],
          code: "user.password = userpassword"
        },
        {
          id: ":r1u:452583",
          blockVariant: "create",
          model: "User",
          success: [
            {
              "id": ":r3k:834337",
              "blockVariant": "return",
              "model": "User",
              "success": [],
              "error": [],
              "data": true,
              "returnContent": "{ data: data, message: 'Succesfully registered new account' }",
              "status": "201"
            }
          ],
          error: [
            {
              "id": ":r3d:330729",
              "blockVariant": "error",
              "model": "User",
              "success": [],
              "error": [],
              "returnContent": "Error registering new user",
              "status": 500
            }
          ],
          varName: "dbUser",
          fields: "...user"
        }
      ]
      break;
    default:
      return;
  }

  return newRoute;
}

/**
 * @export
 * @param {string} type
 * @param {string} model
 * @param {string} id
 * @return {object} newRoute
 */
 export const initMiddlewareObject = (type, model, id, modelData=null) => {
  let newMiddleware = {
    id: id,
    middleware: [],
    logic: []
  }

  let modelParams = modelData ? modelData.params : null
  let paramsString = ''
  if (modelData) {
    let i = 0;
    for (let p of modelParams) {
      paramsString += p['name'] + `${i < modelParams.length-1 ? ', ' : ''}`;
      i++;
    }
  }

  switch (type) {
    case 'verifyJWT':
      newMiddleware.handler = 'verifyJWT';
      newMiddleware.logic = [
        {
          id: ":rcq:422458",
          blockVariant: "if",
          success: [
            {
              id: ":rcv:767903",
              blockVariant: "error",
              model: "User",
              success: [],
              error: [],
              returnContent: "No Token Given",
              status: "401"
            }
          ],
          error: [],
          condition: "!req.headers['authorization']"
        },
        {
          id: ":rdl:360297",
          blockVariant: "custom",
          success: [],
          error: [],
          code: "const token = req.headers['authorization'].split(' ')[1];"
        },
        {
          id: ":rdp:736519",
          blockVariant: "ifelse",
          success: [
            {
              id: ":re2:606786",
              blockVariant: "JWT",
              model: "User",
              success: [
                {
                  id: ":reb:704227",
                  blockVariant: "custom",
                  model: "User",
                  success: [],
                  error: [],
                  code: "req.user = {};\nreq.user.id = decoded.id;\nreq.user.username = decoded.username;"
                },
                {
                  id: ":rem:424161",
                  blockVariant: "next",
                  model: "User",
                  success: [],
                  error: []
                }
              ],
              error: [
                {
                  id: ":rev:169680",
                  blockVariant: "error",
                  model: "User",
                  success: [],
                  error: [],
                  returnContent: "Failure to auth",
                  status: "401"
                }
              ],
              jwtVariant: "verify",
              payload: "",
              secret: "'PLEASE_CHANGE'",
              token: "token",
              expiration: 86400
            }
          ],
          error: [
            {
              id: ":rf4:350254",
              blockVariant: "error",
              success: [],
              error: [],
              returnContent: "Invalid token given",
              status: "401"
            }
          ],
          condition: "token"
        },
      ]
      break;
    default:
      return;
  }

  return newMiddleware;
}