import React from 'react';
import {getColor} from './util';
import {useDrag} from 'react-dnd';
import {ItemTypes} from '../../dnd/types'
import {Typography} from '@mui/material';
import {useSelector} from "react-redux";

export default function NewBlockMarketplace({blockVariant, editorVariant, mailerId}) {
    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);

    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.SIDEBAR_LOGIC_BLOCK,
        item: {blockVariant: blockVariant, itemType: ItemTypes.SIDEBAR_LOGIC_BLOCK, mailerId: mailerId},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }), [])

    const getMailerObject = (mailerId) => {
        for (let m of mailers)
            if (m.id === mailerId) return m;
    }

    const getBlockContent = () => {
        if (mailerId) {
            let obj = getMailerObject(mailerId);
            return obj ? obj.name : "undefined mailer"
        }
        return blockVariant
    }

    return (
        <div
            style={{
                ...styles.main,
                backgroundColor: getColor(blockVariant)
            }}
            ref={drag}
        >
            <Typography variant="caption" display="block" style={{fontWeight: "bold"}}>
                {getBlockContent()}
            </Typography>
        </div>
    )
}

const styles = {
    main: {
        color: "white",
        padding: ".5em",
        paddingLeft: "1em",
        borderRadius: ".25em",
        cursor: "pointer",
        textAlign: "left"
    }
}