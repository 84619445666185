import {
  ADD_TO_CURR_LINE,
  SWITCH_COMMAND_INDEX,
  TOGGLE_TERMINAL,
  APPEND_COMMAND,
  CLEAR_LINE,
  BACKSPACE,
  SET_LINE,
} from '../Types';

const initialState = {
  terminalOpen: false,
  commands: [],
  commandIndex: 0,
  currLine: '',
};

const TerminalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TERMINAL:
      return {
        ...state,
        terminalOpen: !state.terminalOpen,
      };

    case ADD_TO_CURR_LINE:
      return {
        ...state,
        currLine: state.currLine + action.data,
      };

    case CLEAR_LINE:
      return {
        ...state,
        currLine: '',
      };

    case SET_LINE:
      return {
        ...state,
        currLine: action.data,
      };

    case BACKSPACE:
      return {
        ...state,
        currLine: state.currLine.slice(0,-1),
      };

    case APPEND_COMMAND:
      return {
        ...state,
        commands: state.commands.concat(state.currLine),
        currLine: '',
        commandIndex: state.commands.length+1,
      };

    case SWITCH_COMMAND_INDEX:
      let newIdx = state.commandIndex + action.data;
      if (newIdx < 0) newIdx = -1;
      if (newIdx >= state.commands.length) newIdx = state.commands.length ;

      return {
        ...state,
        commandIndex: newIdx,
      };

    default:
      return state;
  }
};

export default TerminalReducer;
