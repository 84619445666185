import {
  SET_ACTIVE_CONTROLLER,
  SET_ACTIVE_MODEL,
  SET_SIDEBAR_OPEN,
  SWITCH_DEV_SIDEBAR_DISPLAY,
  TOGGLE_DEV_SIDEBAR
} from '../Types';

const initialState = {
  activeModel: null,
  activeController: null,
  sidebarOpen: false,
  devSidebarOpen: false,
  devSidebarDisplay: 'models',
};

const SidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODEL:
      return {
        ...state,
        activeModel: action.data,
      };
    case SET_ACTIVE_CONTROLLER:
      return {
        ...state,
        activeController: action.data,
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: action.data
      };
    case TOGGLE_DEV_SIDEBAR:
      return {
        ...state,
        devSidebarOpen: !state.devSidebarOpen
      };
    case SWITCH_DEV_SIDEBAR_DISPLAY:
      return {
        ...state,
        devSidebarDisplay: action.data,
      };
  
    default:
      return state;
  }
};

export default SidebarReducer;
