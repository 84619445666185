import React, {
  useEffect, useId, useState,
} from 'react';
import {
  Button, Typography, TextField, FormControl, Stack, FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import Modal from './Modal';
import {
  getRandomInt, initRouteObject, isAlphaNumeric, pluralize,
} from '../services/helpers';
import { toPascalCase } from '../util/caseMatching';
import Select from '../components/Atoms/Select'
import TextInput from './Atoms/TextInput';
import {addModelRelation, editModelRelation, removeModelRelation} from '../redux/actions/Relations';
import {getModelFromId} from "../util/modelsUtil";


const relationTypes = [
  { name: 'one-to-many' },
  { name: 'many-to-many' },
  // { name: 'one-to-one' },
]


function RelationModal({
  open, handleClose, models, addRelation, editRelation, removeRelation, relationData, variant
}) {
  const id = useId();
  const [name, setName] = useState(relationData ? relationData.relation_name : '');
  const [relationType, setRelationType] = useState(relationData ? { name: relationData.relation_type } : relationTypes[0]);
  const [modelA, setModelA] = useState(relationData ? getModelFromId(models, relationData.model_a) : models[0] || null);
  const [modelB, setModelB] = useState(relationData ? getModelFromId(models, relationData.model_b) : models[0] || null);
  const [nameA, setNameA] = useState(relationData ? relationData.field_a : '');
  const [nameB, setNameB] = useState(relationData ? relationData.field_b : '');

  useEffect(() => {
    setName(relationData ? relationData.relation_name : '');
    setRelationType(relationData ? { name: relationData.relation_type } : relationTypes[0])
    setModelA(relationData ? getModelFromId(models, relationData.model_a) : models[0] || null)
    setModelB(relationData ? getModelFromId(models, relationData.model_b) : models[0] || null)
    setNameA(relationData ? relationData.field_a : '')
    setNameB(relationData ? relationData.field_b : '')
  }, [relationData])


  const submitDisabled = (name === '');

  const handleChangeName = (e) => {
    if (isAlphaNumeric(e.target.value)) {
      setName(toPascalCase(e.target.value));
    }
  };

  const handleSubmit = () => {
    if (variant === "new") {
      addRelation({
        id: getRandomInt(999999999999),
        relation_name: name,
        relation_type: relationType.name,
        model_a: modelA.id,
        model_b: modelB.id,
        field_a: nameA,
        field_b: nameB,
      });
    } else if (variant === "edit") {
      editRelation(
        relationData.id,
        {
          ...relationData,
          relation_name: name,
          relation_type: relationType.name,
          model_a: modelA.id,
          model_b: modelB.id,
          field_a: nameA,
          field_b: nameB,
        })
    }
    setName('');
    handleClose();
  };

  const handleDeleteRelation = () => {
    removeRelation(relationData && relationData.id)
    handleClose()
  }

  const getRelationString = () => {
    switch(relationType.name) {
      case 'one-to-many':
        return `${modelA && modelA.name} has many ${modelB && pluralize(modelB.name)}`
      case 'many-to-many':
        return `${modelA && pluralize(modelA.name)} have many ${modelB && pluralize(modelB.name)}`
      case 'one-to-one':
        return `one ${modelA && modelA.name} has one ${modelB && modelB.name}`
      default:
        break;
    }
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      submitDisabled={submitDisabled}
      title={`${variant[0].toUpperCase()}${variant.substring(1)} Relation`} // capitalize first letter
      handleSubmit={handleSubmit}
    >

      <Stack spacing={2}>

        <FormControl size="small">
          <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
            relation name
          </Typography>
          <TextField
            value={name}
            size="small"
            placeholder="RelationHandler"
            fullWidth
            onChange={handleChangeName}
          />
          <FormHelperText>e.g. FollowingHandler, AuthorshipHandler, etc.</FormHelperText>
        </FormControl>

        <Stack spacing={2} direction={'row'}>

          {/* Model A */}
          <div style={{flex: 2}}>
            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
              model A
            </Typography>
            <div className='outline outline-1 outline-slate-300 rounded-sm p-2'>
              <Select 
                items={models} 
                labelValue={'name'} 
                selected={modelA} 
                onChange={(m) => setModelA(m)}
              />

              <TextInput
                className={'mt-2'}
                label={'field name'}
                value={nameA}
                onChange={(x) => setNameA(x.target.value)}
              />
            </div>
          </div>
          
          {/* Relation Type */}
          <div className='w-full flex items-center h-full justify-center' style={{flex: 2}}>
            <div className='w-full mt-6'>
              <Select
                items={relationTypes}
                labelValue={'name'}
                selected={relationType}
                onChange={(r) => setRelationType(r)}
              />
              <FormHelperText style={{textAlign: 'center'}}>
                {getRelationString()}
              </FormHelperText>
            </div>
          </div> 
          
          {/* Model B */}
          <div style={{flex: 2}}>
            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
              model B
            </Typography>
            <div className='outline outline-1 outline-slate-300 rounded-sm p-2'>
              <Select 
                items={models} 
                labelValue={'name'} 
                selected={modelB} 
                onChange={(m) => setModelB(m)}
              />
              <TextInput
                className={'mt-2'}
                label={'field name'}
                value={nameB}
                onChange={(x) => setNameB(x.target.value)}
              />
            </div>
          </div>
        </Stack>
        { variant === "edit" &&
          <div>
            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
              delete relation
            </Typography>
            <Button
                variant={'contained'}
                color={'error'}
                size={'small'}
                onClick={handleDeleteRelation}
            >delete</Button>
          </div>
        }
      </Stack>
    </Modal>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
});
const mapDispatchToProps = (dispatch) => ({
  addRelation: (data) => dispatch(addModelRelation(data)),
  editRelation: (key, data) => dispatch(editModelRelation(key, data)),
  removeRelation: (key) => dispatch(removeModelRelation(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelationModal);

const styles = {
  header: {
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
};
