import React, {useId, useState} from 'react';
import {Chip, FormControl, FormHelperText, MenuItem, Select, Stack, TextField, Typography} from '@mui/material';
import {connect} from 'react-redux';
import Modal from './Modal';
import {getRandomInt, isAlphaNumeric} from '../services/helpers';
import {addRoute} from '../redux/actions/Routes';

function RouteModal({
                        open,
                        handleClose,
                        controllers,
                        middlewares,
                        addRoute,
                    }) {
    const id = useId();
    const [methodName, setMethodName] = useState('');
    const [url, setUrl] = useState('');
    const [method, setMethod] = useState('get');
    const [controller, setController] = useState('');
    const [mids, setMids] = useState(Array(middlewares.length).fill(false));

    const toggleMiddleware = (idx) => {
        let newArr = JSON.parse(JSON.stringify(mids));
        newArr[idx] = !newArr[idx]
        setMids(newArr)
    }

    const handleFinishRoute = () => {
        const initMids = [];
        for (let i = 0; i < middlewares.length; i++) {
            if (mids[i]) {
                initMids.push(middlewares[i].id)
            }
        }

        const newRoute = {
            id: id + getRandomInt(1000000),
            controller,
            url,
            handler: methodName,
            verb: method,
            middleware: initMids,
            logic: [],
        };
        addRoute(newRoute);
        handleClose();
    };

    const handleSetUrl = (e) => {
        const str = e.target.value;
        if (!str.includes(' ')) {
            setUrl(str);
        }
    };

    const handleSetMethodName = (e) => {
        const str = e.target.value;
        if (isAlphaNumeric(str)) {
            setMethodName(str);
        }
    };

    const submitDisabled = (controller === '' || methodName === '' || url === '');

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            handleSubmit={handleFinishRoute}
            submitDisabled={submitDisabled}
            title="New Route"
            // buttonGroup={buttonGroup}
        >
            <Stack spacing={2}>
                <FormControl size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        route handler method name
                    </Typography>
                    <TextField
                        value={methodName}
                        size="small"
                        fullWidth
                        onChange={handleSetMethodName}
                    />
                    <FormHelperText>e.g. showUser</FormHelperText>
                </FormControl>

                <FormControl size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        route url
                    </Typography>
                    <TextField
                        // disabled={modelAffiliation !== ''}
                        value={url}
                        size="small"
                        fullWidth
                        onChange={handleSetUrl}
                    />
                    <FormHelperText>e.g. users/:id</FormHelperText>
                </FormControl>

                <FormControl sx={{width: '35%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        HTTP method
                    </Typography>
                    <Select
                        value={method}
                        onChange={(e) => setMethod(e.target.value)}
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        <MenuItem value="get">
                            GET
                        </MenuItem>
                        <MenuItem value="post">
                            POST
                        </MenuItem>
                        <MenuItem value="put">
                            PUT
                        </MenuItem>
                        <MenuItem value="delete">
                            DELETE
                        </MenuItem>
                    </Select>
                    {/* <FormHelperText>model controllers are initialized with CRUD routes</FormHelperText> */}
                </FormControl>

                <FormControl sx={{width: '35%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        Route Group
                    </Typography>
                    <Select
                        value={controller}
                        onChange={(e) => setController(e.target.value)}
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        {controllers.map((controller) => (
                            <MenuItem value={controller.id} key={controller.name}>
                                {controller.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* <FormHelperText>model controllers are initialized with CRUD routes</FormHelperText> */}
                </FormControl>

                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        Middlewares
                    </Typography>
                    <div className='flex flex-wrap row w-full'>
                        {middlewares.map((m, idx) => (
                            <Chip
                                label={m.handler}
                                size="small"
                                style={{margin: '.25em'}}
                                onClick={() => toggleMiddleware(idx)}
                                color={mids[idx] ? 'primary' : 'default'}
                            />
                        ))}
                    </div>
                    {/* <FormHelperText>model controllers are initialized with CRUD routes</FormHelperText> */}
                </FormControl>
            </Stack>
        </Modal>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    controllers: state.controllersReducer.controllers,
    middlewares: state.middlewaresReducer.middlewares,
});
const mapDispatchToProps = (dispatch) => ({
    addRoute: (data) => dispatch(addRoute(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteModal);