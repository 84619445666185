import React, { useId, useState } from 'react';
import {
  Button, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Stack, FormHelperText,
} from '@mui/material';
import { connect } from 'react-redux';
import Modal from './Modal';
import {
  getRandomInt, initRouteObject, isAlphaNumeric, pluralize,
} from '../services/helpers';
import { addModel, removeModel } from '../redux/actions/Models';
import { toPascalCase } from '../util/caseMatching';
import { addController } from '../redux/actions/Controllers';
import { addRoute } from '../redux/actions/Routes';

function ControllerModal({
  open, handleClose, models, addController, addRoute,
}) {
  const id = useId();
  const [modelAffiliation, setModelAffiliation] = useState('');
  const [name, setName] = useState('New');

  const handleModelChange = (event) => {
    setModelAffiliation(event.target.value);
    if (event.target.value !== '') {
      const model = models.find((m) => m.id === event.target.value);
      const modelName = model ? model.name : 'invalidModel';

      setName(modelName);
    }
  };

  const handleChangeName = (e) => {
    if (isAlphaNumeric(e.target.value)) {
      setName(toPascalCase(e.target.value));
    }
  };

  const handleSubmit = () => {
    const newId = id + getRandomInt(10000);
    addController(name, modelAffiliation, newId);

    if (modelAffiliation !== '') {
      const model = models.find((m) => m.id === modelAffiliation);
      const modelName = model ? model.name : 'invalidModel';

      const indexRoute = initRouteObject('index', modelName, id + getRandomInt(10000), newId);
      const showRoute = initRouteObject('show', modelName, id + getRandomInt(10000), newId);
      const createRoute = initRouteObject('create', modelName, id + getRandomInt(10000), newId);
      const updateRoute = initRouteObject('update', modelName, id + getRandomInt(10000), newId);
      const deleteRoute = initRouteObject('delete', modelName, id + getRandomInt(10000), newId);

      addRoute(indexRoute);
      addRoute(showRoute);
      addRoute(createRoute);
      addRoute(updateRoute);
      addRoute(deleteRoute);
    }

    handleClose();
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={`${name}Controller`}
      handleSubmit={handleSubmit}
    >

      <Stack spacing={2}>

        <FormControl size="small">
          <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
            group name
          </Typography>
          <TextField
            disabled={modelAffiliation !== ''}
            value={name}
            size="small"
            fullWidth
            onChange={handleChangeName}
          />
          <FormHelperText>'Controller' is automatically appended to the end of the route group name</FormHelperText>
        </FormControl>

        <FormControl sx={{ width: '45%' }} size="small">
          <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
            model affiliation
          </Typography>
          <Select
            value={modelAffiliation}
            onChange={handleModelChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {models.map((model) => (
              <MenuItem value={model.id} key={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>model controllers are initialized with CRUD routes</FormHelperText>
        </FormControl>

      </Stack>
    </Modal>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  addController: (name, affiliation, id) => dispatch(addController(name, affiliation, id)),
  addRoute: (data) => dispatch(addRoute(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControllerModal);

const styles = {
  header: {
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
};
