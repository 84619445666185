import React, { useState } from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BUILDER_BACKEND_URL } from '../constants/constants';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = () => {
    setError(null);
    setLoading(true);
    axios.put(`${BUILDER_BACKEND_URL}/resetpassword`, {
      resetLink: token,
      newPassword,
    })
      .then((res) => {
        setLoading(false);
        setResponse(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };

  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">
        
        <Stack spacing={3} style={{width: '100%'}}>
          <h1 className="title text-center">Reset Password</h1>
          {error
            && <div style={{ color: 'red' }}>{error}</div>}
          {response
            && <div style={{ color: 'blue' }}>{response}</div>}
          <TextField
            label="new password"
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            size="small"
          />

          {/* Submit */}
          <Button
            variant="contained"
            onClick={handleResetPassword}
          >
            {loading ? 'Loading...' : 'Reset Password'}

          </Button>
        </Stack>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}
