import React, {
    useEffect, useId, useState,
} from 'react';
import {
    Button, Typography, TextField, FormControl, Stack
} from '@mui/material';
import Modal from './Modal';
import {
    getRandomInt
} from '../services/helpers';
import DOMPurify from "dompurify";
import { addTemplate, editTemplate, removeTemplate} from "../redux/MailersSlice";
import {useDispatch, useSelector} from "react-redux";
import TextAreaWithPreview from "./Atoms/TextAreaWithPreview";

export default function MailerTemplateModal({
                                        open,
                                        handleClose,
                                        activeMailerId,
                                        templateData,
                                        variant
                                    }) {
    const id = useId();
    const dispatch = useDispatch()

    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);
    const models = useSelector((state) => state.modelsReducer.models);

    // local state
    const [name, setName] = useState(templateData ? templateData.name : '');
    const [content, setContent] = useState(templateData ? templateData.content : '');

    useEffect(() => {
        setName(templateData ? templateData.name : '');
        setContent(templateData ? templateData.content : '');
    }, [templateData, variant, open])

    const submitDisabled = (content && (content.trim() === ''));

    const handleSubmit = () => {
        if (variant === "new" || !variant) {
            dispatch(addTemplate({
                key: activeMailerId,
                data: {
                    id: id + "-" + getRandomInt(999999999999),
                    name: name,
                    content: DOMPurify.sanitize(content)
                }
            }));
        } else if (variant === "edit") {
            dispatch(editTemplate({
                mailerId: activeMailerId,
                templateId: templateData.id,
                templateData: {
                    name: name,
                    content: DOMPurify.sanitize(content),
                }
            }));
        }
        setName('');
        setContent('');
        handleClose();
    };

    const handleDeleteTemplate = () => {
        dispatch(removeTemplate({
            mailerId: activeMailerId,
            templateId: templateData.id,
        }));
        handleClose()
    }

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            submitDisabled={submitDisabled}
            title={`${variant ? variant[0].toUpperCase() + variant.substring(1) : 'New'} Mailer`} // capitalize first letter
            handleSubmit={handleSubmit}
        >

            <FormControl size="small">
                <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                    template name
                </Typography>
                <TextField
                    value={name}
                    size="small"
                    placeholder={`WelcomeEmail`}
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>

            <TextAreaWithPreview
                className={"mt-3 flex-1"}
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />

            <Stack spacing={2}>

                {/* Delete Template */}
                { variant === "edit" &&
                    <div>
                        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                            delete template
                        </Typography>
                        <Button
                            variant={'contained'}
                            color={'error'}
                            size={'small'}
                            onClick={handleDeleteTemplate}
                        >delete</Button>
                    </div>
                }
            </Stack>
        </Modal>
    );
}
