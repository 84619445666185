import {
    ADD_MODEL_RELATION,
    REMOVE_MODEL_RELATION,
    EDIT_MODEL_RELATION,
    SET_MODEL_RELATIONS,
} from '../Types';

const initialState = {
    relations: [],
};

const RelationsReducer = (state = initialState, action) => {
    let newRelations = JSON.parse(JSON.stringify(state.relations));

    switch (action.type) {
        case SET_MODEL_RELATIONS:
            console.log("here?")
            return {
                ...state,
                relations: action.data,
            };
        case ADD_MODEL_RELATION:
            return {
                ...state,
                relations: state.relations.concat({
                id: action.id,
                ...action.data,
                }),
            };

        case EDIT_MODEL_RELATION:
            newRelations.forEach(function (rel, index) {
                if (rel.id === action.key) {
                    this[index] = action.data;
                }
            }, newRelations);

            return {
                ...state,
                relations: newRelations,
            };

        case REMOVE_MODEL_RELATION:
            newRelations = newRelations.filter((rel) => (rel.id !== action.key));
            return {
                ...state,
                relations: newRelations,
            };

        default:
            return state;
    }
};

export default RelationsReducer;
