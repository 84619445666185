import {
  ADD_BLOCK,
  ADD_ROUTE,
  DELETE_BLOCK,
  DELETE_ROUTE,
  EDIT_BLOCK,
  EDIT_ROUTE,
  MOVE_BLOCK,
  RESET_ROUTES,
  SAVE_ACTIVE_ROUTE,
  SET_ACTIVE_ROUTE,
  SET_ACTIVE_ROUTE_FROM_ID,
  SET_DRAGGING,
  SET_ROUTE_MIDDLEWARES,
  SET_ROUTES,
  TOGGLE_CALLBACK_BLOCK,
  TOGGLE_MIDDLEWARE,
  TOGGLE_OPEN_BLOCK,
  TRIGGER_REFRESH,
} from '../Types';
import {addBlockAsChild, deleteBlock, editBlock, findBlock,} from '../../util/recurseBlock';
import {remove, set} from "./util";

const initialState = {
    routes: [],
    activeSequenceBlockCache: [],
    activeSequence: [],
    activeRoute: {
        controller: null,
        url: '',
        methodName: '',
        httpMethod: '',
        middleware: [],
    },
    refresh: 0,
    saveCount: 0,
    isDragging: false,
    closed: [],
    withCallback: [],
};

const RoutesReducer = (state = initialState, action) => {
    const newRoutes = JSON.parse(JSON.stringify(state.routes));
    let newSequence = JSON.parse(JSON.stringify(state.activeSequence));

    let updatedActiveSequence = [...state.activeSequence];
    let temp = null;

    switch (action.type) {
        case SET_ROUTES:
            return {
                ...state,
                routes: action.data,
            };

        case TRIGGER_REFRESH:
            return {
                ...state,
                refresh: state.refresh + 1,
            };

        case TOGGLE_MIDDLEWARE:
            for (let i = 0; i < newRoutes.length; i++) {
                if (newRoutes[i].id === action.key) {
                    if (newRoutes[i].middleware.includes('verifyJWT')) {
                        newRoutes[i].middleware = newRoutes[i].middleware.filter((m) => m !== 'verifyJWT');
                    } else {
                        newRoutes[i].middleware = newRoutes[i].middleware.concat('verifyJWT');
                    }
                }
            }
            return {
                ...state,
                routes: newRoutes,
                refresh: state.refresh + 1,
            };

        case TOGGLE_OPEN_BLOCK:
            temp = state.closed;
            if (state.closed.includes(action.key)) {
                // temp.delete(action.key);
                temp = remove(temp, action.key);
            } else {
                // temp.add(action.key);
                temp.append(action.key)
            }

            return {
                ...state,
                closed: temp,
                refresh: state.refresh + 1,
            };

        case TOGGLE_CALLBACK_BLOCK:
            temp = state.withCallback;
            if (state.withCallback.includes(action.key)) {
                // temp.delete(action.key);
                temp = temp.remove(temp, action.key);
            } else {
                // temp.add(action.key);
                temp.append(action.key);
            }

            return {
                ...state,
                withCallback: temp,
                refresh: state.refresh + 1,
            };

        case SET_ROUTE_MIDDLEWARES:
            for (let i = 0; i < newRoutes.length; i++) {
                if (newRoutes[i].id === action.key) {
                    newRoutes[i].middleware = action.data;
                }
            }
            return {
                ...state,
                routes: newRoutes,
                refresh: state.refresh + 1,
            };

        case SET_DRAGGING:
            return {
                ...state,
                isDragging: action.data,
            };

        case ADD_BLOCK:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            if (action.parentId) {
                newSequence = addBlockAsChild(
                    newSequence,
                    action.data,
                    action.parentId,
                    action.index,
                    action.variant,
                );
            } else {
                newSequence.splice(action.index, 0, action.data);
            }

            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
            };

        case MOVE_BLOCK:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            // store block and delete

            temp = findBlock(newSequence, action.blockId);
            if (!action.parentId) {
                temp.parentId = null;
            }
            newSequence = deleteBlock(newSequence, action.blockId);

            // add block again in right index
            if (action.parentId) {
                newSequence = addBlockAsChild(
                    newSequence,
                    temp,
                    action.parentId,
                    action.index,
                    action.variant,
                );
            } else {
                newSequence.splice(action.index, 0, temp);
            }

            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
                activeSequenceBlockCache: [],
            };

        /*
         * Explanation: you cant edit the block straight to active sequence because it'll
         * cause unlimited rerenders and breaks app.
         * This stores a cache of all the edits, that we then save when user saves active
         * sequence to routes
         */
        case EDIT_BLOCK:
            // const newCache = state.activeSequenceBlockCache.set(action.key, action.data);
            const newCache = set(state.activeSequenceBlockCache, action.key, action.data);
            return {
                ...state,
                activeSequenceBlockCache: newCache,
            };

        case DELETE_BLOCK:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            newSequence = deleteBlock(newSequence, action.key);
            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
            };

        /*
         * Saves active sequence to routes
         */
        case SAVE_ACTIVE_ROUTE:
            for (const b of state.activeSequenceBlockCache) {
                updatedActiveSequence = editBlock(updatedActiveSequence, b[0], b[1]);
            }

            const updatedRoutes = [...state.routes];
            for (let i = 0; i < updatedRoutes.length; i++) {
                const route = updatedRoutes[i];
                if (state.activeRoute.id === route.id) {
                    updatedRoutes[i] = {
                        ...state.activeRoute,
                        logic: updatedActiveSequence,
                    };
                }
            }

            return {
                ...state,
                routes: updatedRoutes,
                activeSequence: updatedActiveSequence,
                saveCount: state.saveCount + 1,
            };

        case SET_ACTIVE_ROUTE:
            return {
                ...state,
                activeRoute: action.data,
                saveCount: state.saveCount + 1,
            };

        case SET_ACTIVE_ROUTE_FROM_ID:
            let tempRoute = null;
            for (const route of newRoutes) {
                if (route.id === action.id) {
                    temp = route.logic;
                    tempRoute = route;
                }
            }

            return {
                ...state,
                activeRoute: tempRoute,
                activeSequence: temp,
                activeSequenceBlockCache: [],
            };

        case ADD_ROUTE:
            return {
                ...state,
                routes: state.routes.concat(action.data),
            };

        case EDIT_ROUTE:
            for (let i = 0; i < newRoutes.length; i++) {
                if (newRoutes[i].id === action.key) {
                    newRoutes[i] = {...newRoutes[i], ...action.data};
                }
            }

            return {
                ...state,
                routes: newRoutes,
                refresh: state.refresh + 1,
            };

        case DELETE_ROUTE:
            return {
                ...state,
                routes: state.routes.filter((r) => r.id !== action.key),
            };

        case RESET_ROUTES:
            return initialState;

        default:
            return state;
    }
};

export default RoutesReducer;
