import React, {useEffect, useRef} from 'react';
import {IconButton, Typography,} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RouteDisplay from '../pages/Routes/RouteDisplay';

export default function ControllerDisplay({controller, routes, activeController, openMenu}) {
    const cref = useRef();

    useEffect(() => {
        console.log(cref)
        if (controller.id === activeController) {
            cref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [activeController]);

    return (
        <div key={controller.id} className='flex-1 m-8 mb-0' ref={cref}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{
                        fontWeight: 'bold', opacity: 0.8, fontSize: '.9em', textAlign: 'left', paddingLeft: '1em',
                    }}
                >
                    {controller.name}
                    Controller
                </Typography>
                <div style={{flex: 1}}/>
                <IconButton aria-label="delete" onClick={openMenu} size="small">
                    <MoreHorizIcon/>
                </IconButton>
            </div>

            <div
                key={controller.name}
                style={styles.controllerMain}
            >
                {routes.map((route, index) => (
                    <div key={route.id}>
                        {
                            route.controller === controller.id
                            && <RouteDisplay model={null} route={route}/>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    controllerMain: {
        border: '1px solid lightgray',
        padding: '.5em',
        borderRadius: '.5em',
    },
};
