import React from 'react';
import { connect } from 'react-redux';
import { setActiveModel } from '../redux/actions/Sidebar';
import { Typography } from '@mui/material';

function ModelSidebar({ models, selectModel }) {
  return (
    <div className=''>
      {models.map((m) => (
        <div
          key={m.id}
          className="width-full bg-offwhite text-black p-1 pl-2 mb-2 flex"
          style={{borderRadius: '.25em 0 0 .25em', borderRightWidth: 0, cursor: 'pointer'}}
          onClick={() => selectModel(m.id)}
        >
          <Typography variant="caption" display="block" style={{ fontWeight: 'bold', opacity: 0.8, fontSize: '.75em' }}>
            {m.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
});
const mapDispatchToProps = (dispatch) => ({
  selectModel: (id) => dispatch(setActiveModel(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelSidebar);