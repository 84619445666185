import React, {
    useEffect, useId, useState,
} from 'react';
import {
    Button, Typography, TextField, FormControl, Stack, FormHelperText,
} from '@mui/material';
import Modal from './Modal';
import {
    getRandomInt, isAlphaNumeric
} from '../services/helpers';
import { toPascalCase } from '../util/caseMatching';
import { addMailer, editMailer } from "../redux/MailersSlice";
import {useDispatch, useSelector} from "react-redux";

export default function MailerModal({
                                        open,
                                        handleClose,
                                        mailerData,
                                        variant
                                    }) {
    const id = useId();
    const dispatch = useDispatch()

    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);
    const models = useSelector((state) => state.modelsReducer.models);

    // local state
    const [name, setName] = useState(mailerData ? mailerData.name : '');
    const [sender, setSender] = useState(mailerData ? mailerData.sender : '');

    useEffect(() => {
        setName(mailerData ? mailerData.name : '');
        setSender(mailerData ? mailerData.sender : '')
    }, [mailerData, open])


    const submitDisabled = (name.trim() === '' || sender.trim() === '');

    const handleChangeName = (e) => {
        if (isAlphaNumeric(e.target.value)) {
            setName(toPascalCase(e.target.value));
        }
    };

    const handleSubmit = () => {
        if (variant === "new" || !variant) {
            dispatch(addMailer({
                id: getRandomInt(999999999999),
                name: name,
                sender: sender,
                templates: [],
            }));
        } else if (variant === "edit") {
            dispatch(editMailer({
                key: mailerData.id,
                data: {
                    name: name,
                    sender: sender,
                }
            }));
        }
        setName('');
        handleClose();
    };

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            submitDisabled={submitDisabled}
            title={`${variant ? variant[0].toUpperCase() + variant.substring(1) : 'New'} Mailer`} // capitalize first letter
            handleSubmit={handleSubmit}
        >

            <Stack spacing={2}>

                <FormControl size="small">
                    <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                        mailer name
                    </Typography>
                    <TextField
                        value={name}
                        size="small"
                        placeholder={`${models.length > 0 ? models[0].name : 'Untitled'}Mailer`}
                        fullWidth
                        onChange={handleChangeName}
                    />
                    <FormHelperText>e.g. EmailVerificationMailer, NotificationMailer, etc.</FormHelperText>

                    <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                        sender address
                    </Typography>
                    <TextField
                        value={sender}
                        size="small"
                        placeholder={'myemail@email.com'}
                        fullWidth
                        onChange={(e) => setSender(e.target.value)}
                    />
                    <FormHelperText>e.g. noreply@neutrinoapp.com</FormHelperText>
                </FormControl>

            </Stack>
        </Modal>
    );
}
