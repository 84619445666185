export const getColor = (blockVariant) => {
  switch (blockVariant) {
    case 'delete':
      return '#0D0D0D';
    case 'update':
      return '#034C8C';
    case 'query':
      return '#246B73';
    case 'create':
      return '#0468BF';
    case 'error':
      return '#BF3832';
    case 'if':
      return '#3a1a52';
    case 'ifelse':
      return '#3a1a52';
    case 'return':
      return '#238C6E';
    case 'BCrypt':
      return '#2b354e'
    case 'JWT':
      return '#ff7d01'
    case 'mailer':
      return '#d56262'
    default:
      return '#000';
  }
};

export const getBlockStyle = (blockVariant) => {
  switch (blockVariant) {
    case 'delete':
      return {
        background: 'rgb(121,10,10)',
        background: 'radial-gradient(circle, rgba(121,10,10,1) 0%, rgba(120,8,44,1) 50%, rgba(55,0,0,1) 100%)',
      };
    case 'update':
      return {
        background: 'rgb(62,129,181)',
        background: 'radial-gradient(circle, rgba(62,129,181,1) 0%, rgba(36,81,112,1) 50%, rgba(0,35,62,1) 100%)',
      };
    case 'query':
      return {
        background: 'rgb(4,0,68)',
        background: 'linear-gradient(90deg, rgba(4,0,68,1) 0%, rgba(9,9,121,1) 50%, rgba(7,67,84,1) 100%)',
      };
    case 'create':
      return {
        background: 'rgb(4,0,68)',
        background: 'linear-gradient(90deg, rgba(4,0,68,1) 0%, rgba(9,9,121,1) 50%, rgba(7,67,84,1) 100%)',
      };
    default:
      return {
        background: 'rgb(4,0,68)',
        background: 'linear-gradient(90deg, rgba(4,0,68,1) 0%, rgba(9,9,121,1) 50%, rgba(7,67,84,1) 100%)',
      };
  }
};
