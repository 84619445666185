import { Tab } from '@headlessui/react'
import DOMPurify from "dompurify";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextAreaWithPreview({ value, onChange, className, style }) {

    return (
        <form action="#" className={className} style={style}>
            <Tab.Group>
                {({ selectedIndex }) => (
                    <>
                        <Tab.List className="flex items-center">
                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        selected
                                            ? 'text-gray-900 bg-gray-100 hover:bg-gray-200'
                                            : 'text-gray-500 hover:text-gray-900 bg-white hover:bg-gray-100',
                                        'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                                    )
                                }
                            >
                                Write
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        selected
                                            ? 'text-gray-900 bg-gray-100 hover:bg-gray-200'
                                            : 'text-gray-500 hover:text-gray-900 bg-white hover:bg-gray-100',
                                        'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                                    )
                                }
                            >
                                Preview
                            </Tab>
                        </Tab.List>

                        <Tab.Panels className="mt-2 h-full">
                            <Tab.Panel className="-m-0.5 rounded-lg p-0.5 h-5/6">
                                <label htmlFor="comment" className="sr-only">
                                    Comment
                                </label>
                                <div className={"h-full"}>
                                    <textarea
                                      rows={5}
                                      name="comment"
                                      id="comment"
                                      className="block h-full w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Add your comment..."
                                      defaultValue={''}
                                      onChange={onChange}
                                      value={value}
                                    />
                                </div>
                            </Tab.Panel>
                            <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                                <div className="ml-2">
                                    {value && value.trim() === ""
                                        ? <div className="mx-px mt-px px-3 pt-2 pb-12 text-sm leading-5 text-gray-800">
                                            Preview content will render here.
                                        </div>
                                        : <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
                                    }
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </>
                )}
            </Tab.Group>
        </form>
    )
}
