/* SET TESTING MODE */
const TESTING = false;

/* CONSTANTS */
// const BUILDER_BACKEND_URL_PRODUCTION = 'https://neutrino-projects.herokuapp.com';
// const BUILDER_BACKEND_URL_PRODUCTION = 'https://neutrinoidentitycore.azurewebsites.net' // Azure
const BUILDER_BACKEND_URL_PRODUCTION = 'https://neutrino-builder-backend.herokuapp.com' // Heroku
const BUILDER_BACKEND_URL_TESTING = 'http://localhost:8080';

// const GENERATOR_BACKEND_URL_PRODUCTION = 'https://neutrinogenerator.azurewebsites.net'; // Azure
const GENERATOR_BACKEND_URL_PRODUCTION = 'https://neutrino-generator-backend.herokuapp.com';
const GENERATOR_BACKEND_URL_TESTING = 'http://127.0.0.1:8000';

const AI_BACKEND_URL_PRODUCTION = 'https://neutrino-generator.herokuapp.com'; 
const AI_BACKEND_URL_TESTING = 'http://127.0.0.1:8001';

/* EXPORTS */
export const ENV = TESTING ? 'dev' : 'prod'
export const BUILDER_BACKEND_URL = TESTING
  ? BUILDER_BACKEND_URL_TESTING
  : BUILDER_BACKEND_URL_PRODUCTION;
export const GENERATOR_BACKEND_URL = TESTING
  ? GENERATOR_BACKEND_URL_TESTING 
  : GENERATOR_BACKEND_URL_PRODUCTION;
export const AI_BACKEND_URL = TESTING
  ? AI_BACKEND_URL_TESTING 
  : AI_BACKEND_URL_PRODUCTION;
