import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import {
  addModel,
} from '../../redux/actions/Models';
import FolderDisplay from '../../components/FolderDisplay';
import LogicBlockContainer from '../../components/LogicBlocks/LogicBlockContainer';
import RouteMarketplace from '../../components/RouteMarketplace';
import TrashIcon from '../../components/LogicBlocks/TrashIcon';
import { saveActiveRoute, setActiveRoute, toggleMiddleware } from '../../redux/actions/Routes';
import ResizeableSisebar from '../../components/ResizeableSidebar';
import { GENERATOR_BACKEND_URL } from '../../constants/constants';
import { saveActiveMiddleware } from '../../redux/actions/Middlewares';

function MiddlewareEdit(props) {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [codeView, setCodeView] = useState(false);

  const getCodeFromLogic = () => {
    axios.post(`${GENERATOR_BACKEND_URL}/logiccodepreview`, {
      logic: props.activeSequence,
    })
      .then((res) => {
        if (res.data.code) {
          setCode(res.data.code);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };

  useEffect(() => () => {
    props.saveActiveMiddleware();
  }, []);

  useEffect(() => {
    getCodeFromLogic();
  }, [codeView, props.activeSequence, props.routes]);

  const handleSetCodeView = () => {
    setCodeView(!codeView);
  };

  const handleSave = () => {
    props.saveActiveMiddleware();
  };

  return (
    <div style={{
      height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden',
    }}
    >

      <div className="row" style={{ flex: 1, alignItems: 'flex-start' }}>

        {/* CODE PREVIEW */}
        <div style={styles.mainContainer}>
          <div style={{
            display: 'flex', justifyContent: 'flex-end', marginTop: '1em', marginBottom: '0em', alignItems: 'center',
          }}
          >

            {/* MIDDLEWARE NAME HEADER */}
            <h3
              style={{ margin: 0 }}
            >
              {props.activeMiddleware && props.activeMiddleware.handler}
              ()

            </h3>

            {/* SAVE BUTTON */}
            <IconButton
              onClick={handleSave}
            >
              <SaveIcon color="primary" />
            </IconButton>

            <div style={{ flex: 1 }} />

            {/* CODE VIEW BUTTON */}
            <Button
              variant="outlined"
              size="small"
              onClick={handleSetCodeView}
            >
              code view
            </Button>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: codeView ? '2em' : '0' }}>
              <LogicBlockContainer variant={"middleware"}/>
            </div>

            {/* CODE DISPLAY */}
            { codeView
              && (
              <div style={{
                flex: 1, height: '100%', overflowY: 'hidden', overflowX: 'hidden', display: 'flex', flexDirection: 'column', fontSize: 14,
              }}
              >
                <SyntaxHighlighter language="javascript" style={dark} customStyle={styles.code}>
                  {`\nconst ${props.activeMiddleware.handler} = (req, res, next) => {\n${code}}`}
                </SyntaxHighlighter>
              </div>
              )}
          </div>
        </div>
      </div>

      {/* DELETE TRASH ICON */}
      <div style={{ position: 'absolute', bottom: '2em', left: '7.5em' }}>
        { props.isDragging
          && <TrashIcon editorVariant={'middlewares'} />}
      </div>
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  isDragging: state.routesReducer.isDragging,
  activeMiddleware: state.middlewaresReducer.activeMiddleware,
  activeSequence: state.middlewaresReducer.activeSequence,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  saveActiveMiddleware: () => dispatch(saveActiveMiddleware()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddlewareEdit);

const styles = {
  sidebar: {
    backgroundColor: 'white',
    height: '100%',
    minWidth: '20em',
    paddingTop: 15,
    minHeight: '50vh',
    borderLeft: '1px solid rgba(0,0,0,0.15)',
  },
  mainContainer: {
    flex: 1,
    height: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    padding: '0 2em 0 2em',
    textAlign: 'left',
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: 'lightgray',
    opacity: 0.75,
    margin: '25px 0 25px 0',
  },
  code: {
    backgroundColor: '#00101a',
    padding: '0 25px 15px 25px',
    borderRadius: 10,
    margin: '1em 0px 0px 0px',
    flex: 1,
    textAlign: 'left',
    borderWidth: 0,
    overflowY: 'scroll',
  },
  paper: {
    padding: '15px 25px 15px 25px',
    marginBottom: 50,
  },
};
