import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import {
  AnnotationIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline';
import { SimpleBlogTemplate, NewBlogTemplate, TwoSidedMarketplaceTemplate } from '../constants/templates';
import {handleResetProject, handleSetActiveProject} from "../util/initializeProject";

const items = [
  {
    title: 'Simple Blog',
    description: "Users can make posts and comment on others' posts.",
    icon: AnnotationIcon,
    background: 'bg-pink-500',
    disabled: false,
  },
  {
    title: 'E-commerce Marketplace',
    description: 'Coming soon.',
    icon: ShoppingBagIcon,
    background: 'bg-yellow-500',
    disabled: true,
  },
  {
    title: 'Two-sided Marketplace',
    description: 'Coming soon.',
    icon: ShoppingCartIcon,
    background: 'bg-green-500',
    disabled: true,
  },
  {
    title: 'Social Media',
    description: 'Coming soon.',
    icon: UserGroupIcon,
    background: 'bg-indigo-500',
    disabled: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
};

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('');


  const handleInitTemplate = () => {
    switch (selected) {
      case 'Simple Blog':
        handleSetActiveProject(NewBlogTemplate)
        navigate('/models')
        break;
      default:
        break;
    }
  }

  function handleNewProject() {
    handleResetProject();
    navigate('/models');
  }

  return (
    <div className='m-8 flex justify-center'>
      <div className='w-full lg:w-1/2'>
        <h2 className="text-lg font-medium text-gray-900">Projects</h2>
        <p className="mt-1 text-sm text-gray-500">
          You haven’t created a project yet. Get started by selecting a template or start from an empty project.
        </p>

        <ul role="list" className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div 
                onClick={() => setSelected(item.title)}
                className={classNames(
                  !item.disabled && selected === item.title ? 'bg-maingreen' : '',
                  !item.disabled && selected !== item.title ? "hover:bg-gray-100" : '',
                  item.disabled ? "cursor-default" : "cursor-pointer",
                  "relative -m-2 p-2 flex items-center space-x-4 rounded-xl",
                )}
              >
                <div
                  className={classNames(
                    item.disabled ? "bg-gray-300" : item.background,
                    'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                  )}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 
                    className={classNames(
                      !item.disabled && selected === item.title ? 'text-white' : 'text-gray-800',
                      "text-sm font-medium"
                    )}
                  >
                    <a href="#" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.title}
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </h3>
                  <p 
                    className={classNames(
                      !item.disabled && selected === item.title ? 'text-gray-200' : 'text-gray-600',
                      "mt-1 text-sm"
                    )}
                  >{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>

        { items.some((i) => !i.disabled && selected === i.title) && 
          <div className="mt-4 flex">
            <div
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              onClick={handleInitTemplate}
            >
              Initialize template<span aria-hidden="true"> &rarr;</span>
            </div>
          </div>
        }
        
        <div className="mt-4 flex">
          <div
            className="text-sm font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
            onClick={handleNewProject}
          >
            Or start from an empty project<span aria-hidden="true"> &rarr;</span>
          </div>
        </div>
      </div>
    </div>
  )
}
