import React, {
  useState, useRef, useLayoutEffect, useContext, useEffect
} from 'react';
import {
  Typography, Stack, Grid
} from '@mui/material';
import { connect } from 'react-redux';
import { isAlphaNumeric } from '../../services/helpers';
import { editBlock, toggleCallback } from '../../redux/actions/Routes';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import { EditorContext } from './EditorContext';
import { editBlockMiddleware, toggleCallbackMiddleware } from '../../redux/actions/Middlewares';
import CheckboxInput from './CheckboxInput';
import CallbackDisplay from './CallbackDisplay';

const jwtVariations = ['verify', 'sign']

function JwtBlock(props) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [jwtVariant, setJwtVariant] = useState(props.blockData.jwtVariant || jwtVariations[0]);
  const [payload, setPayload] = useState(props.blockData.payload || '');
  const [secret, setSecret] = useState(props.blockData.secret || "'pleasechange'");
  const [token, setToken] = useState('');
  const [expiration, setExpiration] = useState(86400);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // let modelName = props.models.find(m => m.id === model);
    if (editorVariant === 'middleware') {
      props.editBlockMiddleware(props.id, {
        ...props.blockData, jwtVariant, payload, secret, token, expiration
      });
    } else {
      props.editBlock(props.id, {
        ...props.blockData, jwtVariant, payload, secret, token, expiration
      });
    };
  }, [jwtVariant, payload, secret, token, expiration]);

  const handleSaveBlock = () => {
    props.editBlock(props.id, {
      params, varName, model, multiple,
    });
  };

  useEffect(() => {
  }, [props.refresh])

  const handleToggleCallback = () => {
    if (editorVariant === 'middleware') {
      console.log('here')
      props.toggleCallbackMiddleware(props.id)
    } else {
      props.toggleCallback(props.id)
    };
  }

  return (
    <div style={{ ...styles.main, padding: props.opened ? '1em' : '0' }}>
      { props.opened
    && (
    <Stack spacing={0}>

      <Grid container spacing={0}>

        {/* MODEL SELECT */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            variant:
          </Typography>
          <SelectInput
            value={jwtVariant}
            onChange={(e) => setJwtVariant(e.target.value)}
          >
            {jwtVariations.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </SelectInput>
        </Grid>

        {/* SIGN INPUTS */}
        { jwtVariant === 'sign' && 
        <Grid item xs={10} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            payload:
          </Typography>
          <TextInput 
            placeholder={"{ id: user._id.toString(), username: user.username }"}
            value={payload}
            onChange={(e) => setPayload(e.target.value)}
          />
        </Grid>
        }

        { jwtVariant === 'sign' && 
        <Grid item xs={5} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            secret:
          </Typography>
          <TextInput 
            placeholder={'"generic_secret"'}
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          />
        </Grid>
        }

        { jwtVariant === 'sign' && 
        <Grid item xs={5} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            expiration:
          </Typography>
          <TextInput 
            type='number'
            value={expiration}
            onChange={(e) => setExpiration(e.target.value)}
          />
        </Grid>
        }

        {/* VERIFY INPUTS */}
        { jwtVariant === 'verify' && 
        <Grid item xs={7} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            token:
          </Typography>
          <TextInput 
            placeholder={"Bearer S0M3ToKEN"}
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
        </Grid>
        }

        { jwtVariant === 'verify' && 
        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            secret:
          </Typography>
          <TextInput
            placeholder={'"generic_secret"'}
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
          />
        </Grid>
        }

        {/* ENABLE CALLBACK */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            callback:
          </Typography>
          <CheckboxInput
            checked={props.showCallback}
            onChange={handleToggleCallback}
          />
        </Grid>

      </Grid>

      { props.showCallback
      && <CallbackDisplay parentId={props.id} />}
    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  saveCount: state.routesReducer.saveCount,
  refresh: state.routesReducer.refresh,
  blockCache: state.routesReducer.activeSequenceBlockCache,
  isDragging: state.routesReducer.isDragging,
  callbackSet: state.middlewaresReducer.withCallback,
});
const mapDispatchToProps = (dispatch) => ({
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
  toggleCallback: (key) => dispatch(toggleCallback(key)),
  toggleCallbackMiddleware: (key) => dispatch(toggleCallbackMiddleware(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JwtBlock);

const styles = {
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    marginLeft: '.5em',
    marginTop: 0,
  },
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: '1em',
  },
};
