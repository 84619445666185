import {
  ADD_CONTROLLER,
  EDIT_CONTROLLER,
  DELETE_CONTROLLER,
  SET_CONTROLLERS,
} from '../Types';

export const setControllers = (data) => ({
  type: SET_CONTROLLERS,
  data,
});

export const addController = (name, affiliation, id) => ({
  type: ADD_CONTROLLER,
  name,
  affiliation,
  id,
});

export const editController = (id, name, data) => ({
  type: EDIT_CONTROLLER,
  id,
  name,
  data,
});

export const deleteController = (id) => ({
  type: DELETE_CONTROLLER,
  key: id,
});
