import {
  SET_WARNINGS,
  REMOVE_WARNING,
} from '../Types';

const initialState = {
  warnings: [],
};

const WarningsReducer = (state = initialState, action) => {
  let warnings = JSON.parse(JSON.stringify(state.warnings));
  switch (action.type) {
    case SET_WARNINGS:
      return {
        ...state,
        warnings: action.data,
      };
    case REMOVE_WARNING:
      warnings = warnings.filter((warning) => (warning.message !== action.data));
      return {
        ...state,
        warnings,
      };
    default:
      return state;
  }
};

export default WarningsReducer;
