import React from 'react';

export default function CheckboxInput({ checked, onChange }) {
  return (
    <input
      name="isGoing"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className="focus:ring-indigo-500 text-indigo-600 border-gray-300 rounded w-5 h-5 m-1 ml-2 cursor-pointer"
    />
  )
}