import React, {useState} from 'react';
import {Button, Chip, FormControl, Menu, MenuItem, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import MemoryIcon from '@mui/icons-material/Memory';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  setRouteLogic,
  setRouteMiddleware,
  togglePagination,
  toggleRouteEnable,
  toggleRouteProtected,
} from '../../redux/actions/Models';
import {
  deleteRoute,
  editRoute,
  setActiveRouteFromId,
  setRouteMiddlewares,
  toggleMiddleware,
} from '../../redux/actions/Routes';
import {isAlphaNumeric} from '../../services/helpers';
import Modal from '../../components/Modal';

function RouteDisplay(props) {
    const {route} = props;
    const [editMode, setEditMode] = useState(false);
    const [url, setUrl] = useState(route.url);
    const [handler, setHandler] = useState(route.handler);
    const [mids, setMids] = useState(Array(props.middlewares.length).fill(false));
    const navigate = useNavigate();

    const toggleMiddleware = (idx) => {
        let newArr = JSON.parse(JSON.stringify(mids));
        newArr[idx] = !newArr[idx]
        setMids(newArr)
    }

    /* Edit Menu */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickMenu = (option) => {
        switch (option) {
            case 'config':
                setEditMode(true);
                break;
            case 'delete':
                props.deleteRoute(route.id);
                break;
            case 'logic':
                handleClickEdit(route.id);
                break;
            default:
                setEditMode(false);
        }
        setAnchorEl(null);
    };
    // edit button
    const handleClickEditButton = (e) => {
        // SAVE
        if (editMode) {
            setEditMode(false);
            props.editRoute(route.id, {url, handler});
        } else {
            setAnchorEl(e.currentTarget);
        }
    };
    // gear icon
    const handleClickEdit = (id) => {
        navigate('/route-editor');
        props.setActiveRouteFromId(id);
    };

    function title(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getRouteColor(route) {
        switch (route.verb) {
            case 'get':
                return ['#e5f0f9', '#bfd9ef', '#0064ba'];
            case 'post':
                return ['#e3f8eb', '#b9ebd0', '#00ad41'];
            case 'put':
                return ['#faf2e8', '#f2e1c8', '#ce8600'];
            case 'delete':
                return ['#f8e7e7', '#eec4c5', '#b10006'];
            default:
                return ['ghostwhite', 'ghostwhite', 'ghostwhite'];
        }
    }

    const handleChangeUrl = (e) => {
        const str = e.target.value;
        if (!str.includes(' ')) {
            setUrl(e.target.value);
        }
    };

    const handleChangeHandler = (e) => {
        const str = e.target.value;
        if (isAlphaNumeric(str)) {
            setHandler(e.target.value);
        }
    };

    const [openMidModal, setOpenMidModal] = useState(false);
    const handleOpenMids = () => setOpenMidModal(true);
    const handleCloseMids = () => setOpenMidModal(false);

    const handleSubmitMidsModal = () => {
        const initMids = [];
        for (let i = 0; i < props.middlewares.length; i++) {
            if (mids[i]) {
                initMids.push(props.middlewares[i].id);
            }

        }


        props.setRouteMiddlewares(route.id, initMids);
        handleCloseMids();
    }

    const cbxWid = '4em';

    return (
        <div>
            <div
                className="row"
                style={{
                    opacity: route.disabled ? 0.5 : 1,
                    borderWidth: 1,
                    borderColor: getRouteColor(route)[1],
                    backgroundColor: getRouteColor(route)[0],
                    borderRadius: 2,
                    borderStyle: 'solid',
                    marginTop: 5,
                    padding: 3,
                }}
            >

                {/* VERB */}
                <div style={{flex: 1}}>
                    {route.verb !== '-'
                        ? (
                            <div
                                className="row"
                                style={{
                                    width: 70,
                                    height: 35,
                                    justifyContent: 'center',
                                    color: 'white',
                                    borderRadius: 2,
                                    backgroundColor: getRouteColor(route)[2],
                                }}
                            >
                                {route.verb.toUpperCase()}
                            </div>
                        )
                        : route.verb.toUpperCase()}
                </div>

                {/* URL */}
                <div style={{flex: 2, textAlign: 'left', marginLeft: '1em'}}>
                    {editMode
                        ? (
                            <input
                                type="data"
                                placeholder={route.url}
                                style={styles.textInput}
                                value={url}
                                onChange={handleChangeUrl}
                            />
                        )
                        : url}
                </div>
                <div style={{flex: 2, textAlign: 'left', marginLeft: '1em'}}>
                    {editMode
                        ? (
                            <input
                                type="data"
                                placeholder={route.handler}
                                style={styles.textInput}
                                value={handler}
                                onChange={handleChangeHandler}
                            />
                        )
                        : `${handler}()`}
                </div>

                {/* disabled */}
                {/* <div style={{ width: cbxWid, display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            disabled={props.disableChanges || route.verb === '-'}
            checked={route.disabled}
            // onChange={() => props.toggleRouteEnable(model.id, route.name)}
            icon={<CodeOffIcon style={{ opacity: 0.5 }} />}
            checkedIcon={<CodeOffIcon color="error" />}
            size="small"
          />
        </div> */}

                <div className='flex-1 flex justify-end'>
                    {route.middleware.map((m, idx) => (
                        <div className='mr-1 text-xs'>{
                            props.middlewares.find(x => x.id === m) &&
                            props.middlewares.find(x => x.id === m).handler
                        }{idx < route.middleware.length - 1 ? "," : ""}</div>
                    ))}
                </div>

                {/* protected */}
                <div style={{width: cbxWid, display: 'flex', justifyContent: 'center'}}>
                    <Checkbox
                        onChange={(e) => {
                            handleOpenMids()
                        }}
                        checked={route.middleware.length > 0}
                        icon={<MemoryIcon style={{opacity: 0.5}}/>}
                        checkedIcon={<MemoryIcon color="primary"/>}
                        disabled={route.disabled || props.disableChanges || route.verb === '-'}
                        size="small"
                    />
                </div>

                {/* logic */}
                <div style={{
                    width: cbxWid, display: 'flex', justifyContent: 'center', opacity: route.disabled ? 0.5 : 1,
                }}
                >
                    <IconButton
                        // disabled={!route.protected || route.disabled || props.disableChanges}
                        // onClick={() => setOpenLogic(!openLogic)}
                        // color={route.logic === '' ? 'warning' : 'primary'}
                        color={props.routes.find((r) => r.id === route.id).logic.length > 0 ? 'primary' : 'warning'}
                        onClick={() => handleClickEdit(route.id)}
                    >
                        <SettingsIcon style={{fontSize: 20}}/>
                    </IconButton>
                </div>

                {/* Middleware */}
                {/* <div style={{ width: cbxWid, display: 'flex', justifyContent: 'center' }}>
          <div>{route.middleware === 'verifyJWT' ? route.middleware : 'none'}</div>
        </div> */}

                {/* EDIT BUTTON */}
                <Button
                    style={{marginRight: '.5em', marginLeft: '1em'}}
                    variant={editMode ? 'contained' : 'outlined'}
                    size="small"
                    onClick={handleClickEditButton}
                >
                    {editMode ? 'save' : 'edit'}
                </Button>

            </div>

            <Modal
                style={{height: '40%'}}
                open={openMidModal}
                handleClose={handleCloseMids}
                // submitDisabled={submitDisabled}
                title="Middlewares"
                handleSubmit={handleSubmitMidsModal}
            >
                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        Middlewares
                    </Typography>
                    <div className='flex flex-wrap row w-full'>
                        {props.middlewares.map((m, idx) => (
                            <Chip
                                label={m.handler}
                                size="small"
                                style={{margin: '.25em'}}
                                onClick={() => toggleMiddleware(idx)}
                                color={mids[idx] ? 'primary' : 'default'}
                            />
                        ))}
                    </div>
                    {/* <FormHelperText>model controllers are initialized with CRUD routes</FormHelperText> */}
                </FormControl>
            </Modal>

            {/* <Modal
        open={openMidModal}
        onClose={handleCloseMids}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalBox}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            middlewares
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal> */}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClickMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClickMenu('config')}>Config</MenuItem>
                <MenuItem onClick={() => handleClickMenu('logic')}>Logic</MenuItem>
                <MenuItem onClick={() => handleClickMenu('delete')}>Delete</MenuItem>
            </Menu>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    routes: state.routesReducer.routes,
    middlewares: state.middlewaresReducer.middlewares,
});
const mapDispatchToProps = (dispatch) => ({
    togglePagination: (key) => dispatch(togglePagination(key)),
    toggleRouteEnable: (modelId, routeName) => dispatch(toggleRouteEnable(modelId, routeName)),
    toggleRouteProtected: (modelId, routeName) => dispatch(toggleRouteProtected(modelId, routeName)),
    setRouteLogic: (modelId, routeName, logic) => dispatch(setRouteLogic(modelId, routeName, logic)),
    setRouteMiddleware: (modelId, routeName, data) => dispatch(setRouteMiddleware(modelId, routeName, data)),
    setActiveRouteFromId: (id) => dispatch(setActiveRouteFromId(id)),
    toggleMiddleware: (id) => dispatch(toggleMiddleware(id)),
    deleteRoute: (id) => dispatch(deleteRoute(id)),
    editRoute: (id, data) => dispatch(editRoute(id, data)),
    setRouteMiddlewares: (id, data) => dispatch(setRouteMiddlewares(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteDisplay);

const styles = {
    textInput: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'black',
        borderWidth: 1,
        padding: 3,
        borderRadius: 2,
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        borderRadius: '.25em',
        p: 4,
    }
};