import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import {connect} from 'react-redux';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import {validateEmail} from '../util/backendPrep';
import {setEmail, setProjectName,} from '../redux/actions/Models';
import {BUILDER_BACKEND_URL, GENERATOR_BACKEND_URL} from '../constants/constants';
import {UserContext} from '../hooks/UserContext';
import {isAlphaNumeric} from '../services/helpers';
import {getPreppedProjectData} from "../util/initializeProject";

const _ = require('lodash');

function Export(props) {
    const {authUser, setAuthUser} = useContext(UserContext);
    const [sent, setSent] = useState(false); // success
    const [serverError, setServerError] = useState(false);
    const [warning, setWarning] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [hasFatalError, setHasFatalError] = useState(false);

    useEffect(() => {
        let toggle = false;
        props.warnings.forEach((w) => {
            if (w.severity && w.severity === 'Error') {
                toggle = true;
            }
        });
        setHasFatalError(toggle);
    }, [props.warnings]);

    const checkError = () => {
        props.models.forEach((model) => {
            if (model.params.length === 0) {
                setError(`[ in ${model.name} ] : Models must have at least 1 data parameter`);
                return true;
            }
        });
        return false;
    };

    const width = '45%';


    function handleExport() {
        setWarning(null);
        setServerError(false);
        const cerr = checkError();

        if (cerr) {

        } else if ((validateEmail(props.email) || authUser) && props.projectName !== '' && !error) {
            const preppedData = JSON.stringify(getPreppedProjectData());
            const preppedDataNoMongo = JSON.stringify(getPreppedProjectData(true));

            let buildFailed = false;
            setLoading(true);

            /*
              make post request here with body = prepareData(props.models)
              downloadTxtFile for testing only
              */
            fetch(`${GENERATOR_BACKEND_URL}/generator`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: preppedData,
            })
                .then((response) => response.blob())
                .then((data) => {
                    if (data.type !== 'application/x-zip-compressed' && data.type !== 'application/zip') {
                        buildFailed = true;
                    }

                    /*
                     * Send project build report
                     */
                    axios.post(`${BUILDER_BACKEND_URL}/projectreports`, {
                        email: authUser ? authUser.email : props.email,
                        project_params: preppedDataNoMongo,
                        failed: buildFailed,
                    }, {
                        headers: {'Content-Type': 'application/json'},
                    });

                    setLoading(false);
                    setSent(!buildFailed);
                    setServerError(buildFailed);

                    if (data.type === 'application/x-zip-compressed' || data.type === 'application/zip') {
                        return downloadZipFile(data);
                    }
                }).catch((e) => {
                console.log(`server error: ${e.response.data.message}`);
            });
        } else {
            setSent(false);
            if (props.projectName === '') {
                setWarning('please enter a project name');
            } else if (!validateEmail(props.email) && !authUser) {
                setWarning('please enter a valid email');
            }
        }
    }

    const downloadZipFile = (data) => {
        const element = document.createElement('a');
        const file = new Blob([data], {type: 'application/octet-stream'});
        element.href = URL.createObjectURL(file);
        element.download = `${props.projectName}.zip`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    const handleChangeProjectName = (newName) => {
        if (isAlphaNumeric(newName)) {
            props.setProjectName(newName);
        }
    };

    return (
        <div className='flex flex-col items-center mt-8 pb-32'>

            <div className='w-full flex'>

                {/* HEADING */}
                <div className="row flex flex-1 mr-8 ml-8">
                    <h1>Export</h1>
                    <div className='flex-1'/>
                </div>
            </div>

            {/* WARNINGS */}
            {props.warnings.length > 0
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="warning">Project will be compiled with warnings, which may cause errors</Alert>
                    </div>
                )}

            {/* WARNINGS */}
            {props.models.length === 0
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="warning">You are exporting an empty project</Alert>
                    </div>
                )}

            {/* SUCCESS */}
            {sent
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="success">
                            Check your downloads for your code!
                            <br/>
                            We would appreciate any feedback by filling out this
                            {' '}
                            <a href="https://forms.gle/dv6ZwzjHD8bNSa6J6" target="_blank" rel="noreferrer">form</a>
                        </Alert>
                    </div>
                )}

            {/* FATAL ERROR */}
            {hasFatalError
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="error">Error in project, build will fail</Alert>
                    </div>
                )}

            {/* SERVER ERROR */}
            {serverError
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="error">Server Failure, try again</Alert>
                    </div>
                )}

            {/* ERROR */}
            {error
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert severity="error">{error}</Alert>
                    </div>
                )}

            {/* INVALID EMAIL */}
            {warning
                && (
                    <div style={{margin: '15px 0 0 0', width}}>
                        <Alert
                            severity="error"
                        >
                            {warning}

                        </Alert>
                    </div>
                )}

            <div style={{
                display: 'flex', flexDirection: 'column', width, marginTop: 15,
            }}
            >

                {/* LOADING */}
                {loading
                    && (
                        <div style={{marginBottom: 15}}>
                            <LinearProgress/>
                        </div>
                    )}

                <TextField
                    label="Project Name"
                    variant="outlined"
                    size="small"
                    // style={{marginRight: 15}}
                    value={props.projectName}
                    onChange={(e) => handleChangeProjectName(e.target.value)}
                />

                <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                    {authUser

                        ? <div style={{flex: 1, margin: '0px 0 15px 0'}}/>
                        : (
                            <TextField
                                style={{flex: 1, margin: '0px 0 0 0'}}
                                label="email"
                                variant="outlined"
                                size="small"
                                // style={{marginRight: 15}}
                                value={props.email}
                                onChange={(e) => props.setEmail(e.target.value)}
                            />
                        )}
                    <Button
                        style={{marginLeft: 15, height: 40}}
                        onClick={_.debounce(handleExport, 1000)}
                        variant="contained"
                        // style={{width: "60%"}}
                    >
                        EXPORT
                    </Button>
                </div>
            </div>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    warnings: state.warningsReducer.warnings,
    serverPort: state.modelsReducer.serverPort,
    mongostr: state.modelsReducer.mongostr,
    email: state.modelsReducer.email,
    projectName: state.modelsReducer.projectName,
    activeProject: state.activeProjectReducer.activeProject,
    currProjectParams: state.modelsReducer,
});
const mapDispatchToProps = (dispatch) => ({
    setEmail: (email) => dispatch(setEmail(email)),
    setProjectName: (name) => dispatch(setProjectName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Export);
