import React, { useState, useRef, useLayoutEffect, useContext } from 'react';
import {
  Typography, Stack, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux';
import { addModel, removeModel } from '../../redux/actions/Models';
import { isAlphaNumeric } from '../../services/helpers';
import CallbackDisplay from './CallbackDisplay';
import { editBlock } from '../../redux/actions/Routes';
import TextInput from './TextInput';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';
import { EditorContext } from './EditorContext';

function ConditionalBlock({
  models, 
  blockVariant, 
  opened, 
  id, 
  variant, 
  editBlock, 
  editBlockMiddleware, 
  blockData,
}) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [condition, setCondition] = useState(blockData.condition || '');

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (editorVariant === 'middleware') {
      editBlockMiddleware(id, { ...blockData, condition });
    } else {
      editBlock(id, { ...blockData, condition });
    }
  }, [condition]);

  return (
    <div style={{ ...styles.main, padding: opened ? '1em' : '0' }}>
      { opened
    && (
    <Stack spacing={0.5}>

      <Grid container spacing={0}>

        {/* CONDITION */}
        <Grid item xs={12} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            condition:
          </Typography>
          <TextInput
            placeholder="user._id === req.id"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
          />
        </Grid>

      </Grid>

      <CallbackDisplay parentId={id} conditional={variant} />

    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalBlock);

const styles = {
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  callbackBox: {
    borderRadius: '.5em',
    border: '1px dashed lightgray',
    padding: '.25em',
    minHeight: '1em',
  },
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    marginLeft: '.5em',
    marginTop: 0,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: '1em',
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
};
