import {
    ADD_MODEL_RELATION,
    REMOVE_MODEL_RELATION,
    EDIT_MODEL_RELATION,
    SET_MODEL_RELATIONS,
} from '../Types';

export const addModelRelation = (data) => ({
    type: ADD_MODEL_RELATION,
    data,
});

export const removeModelRelation = (key) => ({
    type: REMOVE_MODEL_RELATION,
    key,
});

export const editModelRelation = (key, data) => ({
    type: EDIT_MODEL_RELATION,
    key,
    data,
});

export const setModelRelations = (data) => ({
    type: SET_MODEL_RELATIONS,
    data,
});