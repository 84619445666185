import React, {
  useState, useRef, useLayoutEffect, useContext
} from 'react';
import {
  Typography, Stack, TextField, FormControl, FormHelperText, IconButton, MenuItem, Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { addModel, removeModel } from '../../redux/actions/Models';
import { isAlphaNumeric } from '../../services/helpers';
import { editBlock } from '../../redux/actions/Routes';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';
import { EditorContext } from './EditorContext';

function CustomBlock({
  blockVariant, 
  opened, 
  blockData, 
  id, 
  editBlock,
  editBlockMiddleware,
}) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [text, setText] = useState({ value: blockData.code || '', caret: -1, target: null });
  const spaces = 4;
  const [changed, setChanged] = useState(false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (editorVariant === 'middleware') {
      editBlockMiddleware(id, { ...blockData, code: text.value });
    } else {
      editBlock(id, { ...blockData, code: text.value });
    }
  }, [text]);

  function handleSetLogic(e) {
    setText({ value: e.target.value, caret: -1, target: e.target });
    // props.setRouteLogic(model.id, route.name, e.target.value)
  }

  const handleTab = (e) => {
    const content = e.target.value;
    const caret = e.target.selectionStart;
    if (e.key === 'Tab') {
      e.preventDefault();
      const newText = content.substring(0, caret) + ' '.repeat(spaces) + content.substring(caret);
      setText({ value: newText, caret, target: e.target });
    }
  };

  return (
    <div>
      { opened

      && (
      <TextareaAutosize
        id="textarea"
        value={text.value}
        minRows={3}
        placeholder={'if (req.user.id != data.user._id.toString()) {\n    return res.status(401).send({ message: "error" })\n}'}
        className='w-full text-sm'
        style={{
          color: 'black',
          outline: 'none',
          border: 0,
        }}
        onChange={(e) => handleSetLogic(e)}
        onKeyDown={handleTab}
      />
      )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomBlock);

const styles = {
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
};
