import {
  SET_ACTIVE_CONTROLLER,
  SET_ACTIVE_MODEL,
  SET_SIDEBAR_OPEN,
  SWITCH_DEV_SIDEBAR_DISPLAY,
  TOGGLE_DEV_SIDEBAR,
} from '../Types';

export const setActiveModel = (data) => ({
  type: SET_ACTIVE_MODEL,
  data,
});

export const setActiveController = (data) => ({
  type: SET_ACTIVE_CONTROLLER,
  data,
});

export const setSidebarOpen = (data) => ({
  type: SET_SIDEBAR_OPEN,
  data,
});

export const toggleDevSidebar = () => ({
  type: TOGGLE_DEV_SIDEBAR,
});

export const switchDevSidebarDisplay = (data) => ({
  type: SWITCH_DEV_SIDEBAR_DISPLAY,
  data,
});