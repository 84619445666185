import {Stack} from '@mui/material';
import React from 'react';
import NewBlockMarketplace from './LogicBlocks/NewBlockMarketplace';
import {useDispatch, useSelector} from "react-redux";

const blockMap = [
    'query', 'create', 'update', 'delete', 'if', 'ifelse', 'error', 'return', 'custom', 'next', 'BCrypt', 'JWT'
];

export default function RouteMarketplace(props) {
    const editorVariant = props.editorVariant ? props.editorVariant : 'route'
    const dispatch = useDispatch();
    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);

    return (
        <div>
            <Stack spacing={1} style={{margin: '.5em 1em .5em 1em'}}>
                {blockMap.map((blockType, index) => ((editorVariant !== 'route' || blockType !== 'next') &&
                    <NewBlockMarketplace blockVariant={blockType} key={blockType + index}
                                         editorVariant={editorVariant}/>
                ))}
                {mailers.map((mailer) => (
                    <NewBlockMarketplace
                        key={"mailer-"+mailer.id}
                        mailerId={mailer.id}
                        blockVariant={"mailer"}
                        editorVariant={editorVariant}
                    />
                ))}
            </Stack>
        </div>
    );
}
