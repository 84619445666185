import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import { addBlock } from '../../redux/actions/Routes';
import NewBlock from './NewBlock';
import LogicBlock from './LogicBlock';
import { findBlock } from '../../util/recurseBlock'
import { EditorContext } from './EditorContext';

function CallbackDisplay({ 
  activeSequence, 
  activeSequenceMiddleware, 
  parentId, 
  refresh, 
  conditional 
}) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    let currBlock;
    if (editorVariant === 'route') {
      currBlock = (findBlock(activeSequence, parentId));
    } else {
      currBlock = (findBlock(activeSequenceMiddleware, parentId));
    }
    if (currBlock) {
      if (currBlock.success) {
        setSuccess(currBlock.success);
      }
      if (currBlock.error) {
        setError(currBlock.error);
      }
    }
  }, [refresh])

  return (
    <>
      <div style={{marginTop: '.5em'}}>
        {/* LABEL */}
        {conditional ? 
        <Typography variant="caption" display="block" gutterBottom style={styles.typography}>
        if (condition) {"{"}
        </Typography>
        :
        <Typography variant="caption" display="block" gutterBottom style={styles.typography}>
        on success: <a style={{fontWeight: 'normal'}}>(data) => </a>
        </Typography>
        }
        <div>

        {/* FIRST NEW BLOCK */}
        <div style={{marginBottom: "0em"}}>
        <NewBlock parentId={parentId} variant={'success'} index={-1} separator={success.length > 0}/>
        </div>

        {/* SUCCESS ACTION MAP */}
        {success.map((block, index) => (
          <div key={block.id}>
          <LogicBlock 
            blockData={block}
            style={{marginTop: ".5em"}}
            blockVariant={block.blockVariant}
            params={block.params}
            varName={block.varName}
            model={block.model}
            id={block.id}
            index={index}
          />
          <NewBlock 
            parentId={parentId} 
            variant={'success'} 
            index={index}
            separator={index !== success.length-1}
          />
          </div>
        ))}
        </div>
      </div>
      
      { conditional !== "if" &&
      <div style={{marginTop: "1em"}}>
        {/* LABEL */}
        {conditional ? 
        <Typography variant="caption" display="block" gutterBottom style={styles.typography}>
        else {"{"}
        </Typography>
        :
        <Typography variant="caption" display="block" gutterBottom style={styles.typography}>
        catch error: <a style={{fontWeight: 'normal'}}>(err) => </a>
        </Typography>
        }
        <div>

          {/* FIRST NEW BLOCK */}
          <div style={{marginBottom: ".5em"}}>
          <NewBlock parentId={parentId} variant={'error'} index={-1} separator={error.length > 0}/>
          </div>

          {/* ERROR SEQUENCE MAP */}
          {error.map((block, index) => (
          <div style={{marginBottom: ".05em"}} key={block.id}>
            <LogicBlock 
              blockData={block}
              style={{marginTop: ".5em"}}
              blockVariant={block.blockVariant}
              params={block.params}
              varName={block.varName}
              model={block.model}
              id={block.id}
              index={index}
            />
            <NewBlock 
              parentId={parentId} 
              variant={'error'} 
              index={index}
              separator={index !== success.length-1}
            />
          </div>
          ))}
        </div>
      </div>
      }
    </>
  )
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  activeSequence: state.routesReducer.activeSequence,
  activeSequenceMiddleware: state.middlewaresReducer.activeSequence,
  refresh: state.routesReducer.refresh
});
const mapDispatchToProps = (dispatch) => ({
  addBlock: (routeId, index, block) => dispatch(addBlock(routeId, index, block)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallbackDisplay);

const styles = {
  container: {
    borderRadius: ".5em",
    border: "1px solid lightgray"
  },
  header: {
    padding: ".1em .1em .1em 1em",
    borderTopLeftRadius: ".5em",
    borderTopRightRadius: ".5em",
    color: "white",
    display: "flex",
    alignItems: "center"
  },
  main: {
    // padding: "1em"
  },
  callbackBox: {
    borderRadius: ".5em",
    border: "1px dashed lightgray",
    padding: ".25em",
    minHeight: "1em"
  },
  typography: {
    fontWeight: "bold", 
    opacity: .6,
    marginLeft: ".5em"
  }
}