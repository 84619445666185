/*
 * sequence: data array
 * variant: 'success', or 'error' (for callbacks)
 */
export const addBlockAsChild = (sequence, newBlock, parentId, index, variant="success") => {
  let ret = null;
  for (let b of sequence) {
    ret = recFindBlock(b, parentId);
    if (ret) {
      if (variant === "success") {
        ret.success.splice(index, 0, newBlock);
      } else if (variant === "error") {
        ret.error.splice(index, 0, newBlock);
      }
    }
  }
  return sequence;
}

export const findBlock = (sequence, id) => {
  let ret = null;
  for (let b of sequence) {
    ret = recFindBlock(b, id);
    if (ret) return ret;
  }

  return ret;
}

export const recFindBlock = (currBlock, id) => {
  let ret;
  if (currBlock.id === id) return currBlock;
  if (!currBlock.success || !currBlock.error) return null;

  for (let b of currBlock.success) {
    ret = recFindBlock(b, id);
    if (ret) return ret;
  }

  for (let b of currBlock.error) {
    ret = recFindBlock(b, id);
    if (ret) return ret;
  }

  return null;
}


export const deleteBlock = (sequence, id) => {
  let newSequence = sequence.filter(b => b.id !== id)
  for (let b of newSequence) {
    recDelBlock(b, id);
  }
  return newSequence;
}

const recDelBlock = (currBlock, id) => {
  if (!currBlock.success || !currBlock.error) return;

  currBlock.success = currBlock.success.filter(b => b.id !== id);
  currBlock.error = currBlock.error.filter(b => b.id !== id);

  for (let b of currBlock.success) {
    recDelBlock(b, id);
  }
  for (let b of currBlock.error) {
    recDelBlock(b, id);
  }
}


export const editBlock = (sequence, id, data) => {
  for (let i = 0; i < sequence.length; i++) {
    if (sequence[i].id === id) {
      sequence[i] = {
        ...sequence[i],
        ...data
      }
    }
    recEditBlock(sequence[i], id, data);
  }
  return sequence;
}

const recEditBlock = (currBlock, id, data) => {
  if (!currBlock.success || !currBlock.error) return;

  for (let i = 0; i < currBlock.success.length; i++) {
    if (currBlock.success[i].id === id) {
      currBlock.success[i] = {
        ...currBlock.success[i],
        ...data
      }
    }
    recEditBlock(currBlock.success[i], id, data);
  }

  for (let i = 0; i < currBlock.error.length; i++) {
    if (currBlock.error[i].id === id) {
      currBlock.error[i] = {
        ...currBlock.error[i],
        ...data
      }
    }
    recEditBlock(currBlock.error[i], id, data);
  }
}