import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import configData from '../config.json';
import { BUILDER_BACKEND_URL } from '../constants/constants';

export default function RequestVerificationEmail() {
  const { id, token } = useParams();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const sendEmailRequest = () => {
    setError(null);
    setResponse(null);
    if (email !== '') {
      setLoading(true);
      axios.post(`${BUILDER_BACKEND_URL}/request-verification-link/${email}`)
        .then((res) => {
          setLoading(false);
          setResponse(res.data.message);
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.message);
        });
    } else {
      setError('Please enter an email');
    }
  };

  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">
        
        <Stack spacing={3} style={{width: '100%'}}>
          <h1 className="title text-center" style={{ textAlign: 'center' }}>Verify My Email</h1>
          
          {error
            && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
          {response
            && <div style={{ textAlign: 'center', color: 'limegreen' }}>{response}</div>}

          <TextField
            label="email"
            onChange={(e) => setEmail(e.target.value)}
            size="small"
          />

          {/* Submit */}
          <Button
            variant="contained"
            onClick={() => sendEmailRequest(email)}
          >
            { loading ? 'loading...' : 'Request Email'}
          </Button>
        </Stack>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}
