import '../App.css';
import React, {useState} from 'react';
import {Button} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux'
import MailerModal from "../components/MailerModal";
import MailerBox from "../components/MailerBox";
import MailerTemplateModal from "../components/MailerTemplateModal";

export default function Mailers(props) {
    const dispatch = useDispatch();
    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);
    // local state
    const [openModal, setOpenModal] = useState(false);
    const [activeMailerData, setActiveMailerData] = useState(null);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [activeMailerId, setActiveMailerId] = useState(null);
    const [activeTemplateData, setActiveTemplateData] = useState(null);
    const [modalVariant, setModalVariant] = useState("new");

    const handleOpenMailerModal = (mailer, variant) => {
        setActiveMailerData(mailer);
        setOpenModal(true);
        if (variant === "edit") {
            setModalVariant("edit");
        }
    }

    const handleCloseMailerModal = () => {
        setModalVariant("new");
        setActiveMailerData(null);
        setOpenModal(false);
    }
    const handleOpenTemplateModal = (mailerId, template, variant) => {
        setActiveMailerId(mailerId);
        setActiveTemplateData(template);
        setOpenTemplateModal(true);
        if (variant === "edit") {
            setModalVariant("edit");
        }
    }

    const handleCloseTemplateModal = () => {
        setModalVariant("new");
        setActiveMailerId(null);
        setActiveTemplateData(null);
        setOpenTemplateModal(false);
    }

    return (
        <div className='flex flex-col items-center mt-8 pb-32'>

            <div className='w-full flex'>

                {/* HEADING */}
                <div className="row flex flex-1 mr-8 ml-8">
                    <h1 className='cursor-pointer'>Mailers</h1>
                    <div className='flex-1'/>
                    <Button
                        variant="contained"
                        style={{marginRight: "2em", minWidth: "10em"}}
                        size='small'
                        onClick={() => setOpenModal(true)}
                    >
                        CREATE MAILER
                    </Button>
                </div>
            </div>

            <div className='w-full flex flex-wrap pr-8 pl-8'>
                {mailers.length > 0
                    ? mailers.map((mailer, key) => (
                        <div className='w-full mt-5' key={mailer.id}>
                            <MailerBox
                                mailer={mailer}
                                openMailerModal={() => handleOpenMailerModal(mailer, "edit")}
                                openTemplateModal={(template, variant) => handleOpenTemplateModal(mailer.id, template, variant)}
                            />
                        </div>
                    ))
                    : (
                        <div style={{flex: 1, textAlign: 'left', color: 'gray'}}>
                            This project has no mailers, click on
                            'CREATE MAILER' to get started
                        </div>
                    )}
            </div>

            <MailerModal
                open={openModal}
                handleClose={handleCloseMailerModal}
                mailerData={activeMailerData}
                variant={modalVariant}
            />

            <MailerTemplateModal
                open={openTemplateModal}
                handleClose={handleCloseTemplateModal}
                activeMailerId={activeMailerId}
                templateData={activeTemplateData}
                variant={modalVariant}
            />

        </div>
    );
}
