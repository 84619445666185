import React from 'react';
import {
  Button,
  Typography,
  Modal as MuiModal,
  Box,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  // minHeight: "90%",
  height: '90%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  display: 'flex',
  // p: 4,
};

export default function Modal({
  open, handleClose, children, title, handleSubmit, buttonGroup, submitDisabled, styles
}) {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{...styles, ...defaultStyle, }}>
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >

          {/* HEADER */}
          <div style={modalStyles.header}>
            <Typography>
              {title}
            </Typography>
            <div style={{ flex: 1 }} />
            {buttonGroup}

            <IconButton aria-label="delete" size="small" onClick={handleClose} style={{ marginLeft: '1em' }}>
              <ClearIcon />
            </IconButton>
          </div>

          {/* MAIN BODY DISPLAY */}
          <div style={modalStyles.container}>
            {children}
          </div>

          {/* FOOTER */}
          <div style={modalStyles.footer}>
            <Button
              size="small"
              onClick={handleClose}
            >
              Cancel

            </Button>
            <div style={{ flex: 1 }} />

            <Button
              variant="contained"
              size="small"
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              Done

            </Button>
          </div>

        </div>
      </Box>
    </MuiModal>
  );
}

const modalStyles = {
  header: {
    // backgroundColor: "#f0f0f0",
    backgroundColor: 'white',
    borderTopRightRadius: '.25em',
    borderTopLeftRadius: '.25em',
    padding: '.75em 1em .75em 1em',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // borderBottom: "1px solid #d6d4d4",
    borderBottom: '1px solid #e8e8e8',
  },
  footer: {
    // backgroundColor: "#f0f0f0",
    backgroundColor: 'white',
    borderBottomRightRadius: '.25em',
    borderBottomLeftRadius: '.25em',
    justifySelf: 'flex-end',
    padding: '1em',
    display: 'flex',
    borderTop: '1px solid #e8e8e8',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '2em',
    overflowY: 'auto',
  },
};
