import {setActiveProject} from "../redux/actions/ActiveProject";
import {resetProject, setActiveProjectData} from "../redux/actions/Models";
import {setControllers} from "../redux/actions/Controllers";
import {resetRoutes, setRoutes} from "../redux/actions/Routes";
import {setMiddlewares} from "../redux/actions/Middlewares";
import {setModelRelations} from "../redux/actions/Relations";
import {setAuthReducer} from "../redux/actions/Auth";
import {setMailers} from "../redux/MailersSlice";

import store from "../redux/Store"
import {toSnakeCase} from "./caseMatching";
import {translateModel} from "./backendPrep";

export function handleSetActiveProject(params, id) {
    const parsedParams = JSON.parse(params);
    const reduxModels = parsedParams.modelParams;
    const reduxControllers = parsedParams.controllerParams;
    const reduxRoutes = parsedParams.routeParams;
    const reduxMiddlewares = parsedParams.middlewareParams;
    const reduxRelations = parsedParams.relationParams;
    const reduxMailers = parsedParams.mailerParams;
    const reduxAuth = parsedParams.authParams;
    store.dispatch(setActiveProject(id));
    store.dispatch(setActiveProjectData(reduxModels));
    store.dispatch(setControllers(reduxControllers));
    store.dispatch(setRoutes(reduxRoutes));
    store.dispatch(setMiddlewares(reduxMiddlewares));
    store.dispatch(setModelRelations(reduxRelations));
    store.dispatch(setMailers(reduxMailers));
    store.dispatch(setAuthReducer(reduxAuth));
}

export function handleResetProject() {
    store.dispatch(resetProject());
    store.dispatch(setActiveProject(null));
    store.dispatch(resetRoutes());
    store.dispatch(setControllers([]));
    store.dispatch(setModelRelations([]));
    store.dispatch(setMailers([]));
    store.dispatch(setMiddlewares([]));
}

/*
 * Takes in current project params from redux and returns valid json for backend
 */
export function getPreppedProjectData(skipMongostr = false) {
    const state = store.getState();

    let schema = state.modelsReducer.models;
    let controllers = state.controllersReducer.controllers;
    let routes = state.routesReducer.routes;
    let middlewares = state.middlewaresReducer.middlewares;
    let relations = state.relationsReducer.relations;
    let mailers = state.mailersReducer.mailers;
    let config = state.configReducer; // TODO: configReducer.mediaHandlerConfig?
    let auth = state.authReducer;
    let projectName = state.modelsReducer.projectName;
    let serverPort = state.modelsReducer.serverPort;
    let mongostr = state.modelsReducer.mongostr;
    let email = state.modelsReducer.email;

    const db_params = [];
    let auth_object = null;

    for (const m of schema) {
        db_params.push(translateModel(m));
        if (m.auth) auth_object = m.name;
    }
    const req = {
        project_name: toSnakeCase(projectName),
        db_params,
        controllers: controllers,
        routes: routes,
        middlewares: middlewares,
        relations: relations,
        mailers: mailers,
        config: config,
        auth: auth,
        auth_object,
        server_port: serverPort,
        mongostr: skipMongostr ? 'undefined' : mongostr,
        email: email,
    };
    return req;
}

store.subscribe(getPreppedProjectData)