import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Grid, Stack, Typography,} from '@mui/material';
import {connect, useSelector} from 'react-redux';
import {editBlock} from '../../redux/actions/Routes';
import TextInput from './TextInput';
import {EditorContext} from './EditorContext';
import {editBlockMiddleware} from '../../redux/actions/Middlewares';
import SelectInput from "./SelectInput";
import {findTemplateContextRequired} from "../../util/caseMatching";
import Chip from "@mui/material/Chip";
import {data} from "autoprefixer";

const getDataContextHelperTxt = (arr) => {
    let out = "{ "
    for (let i = 0; i < Math.min(arr.length, 2); i++) {
        if (arr[i].toLowerCase() !== "url") {
            out += `${arr[i]}: req.body.${arr[i]}, `
        }
    }
    let url = arr.includes("URL") ? "URL" : "url"   // covering my bases to not repeat an example
    out += `${url}: "https://demo.com/login" }`
    return out
}

function MailerBlock(props) {
    const editorVariant = useContext(EditorContext);  // middleware or route editor
    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);
    // local state
    const [dataContext, setDataContext] = useState(props.blockData.dataContext || '');
    const [recipient, setRecipient] = useState(props.blockData.recipient || '');
    const [subject, setSubject] = useState(props.blockData.subject || '');
    const [templateId, setTemplateId] = useState(props.blockData.template || '');
    const [template, setTemplate] = useState(null);
    const [mailer, setMailer] = useState(null);
    const [reqContext, setReqContext] = useState([]); // helper text for user to know what needs to be inputted to data context

    const getTemplateObject = (templateId) => {
        let mailerTemplates = mailer ? mailer.templates : []
        for (let t of mailerTemplates) {
            if (t.id === templateId) {
                return t;
            }
        }
    }

    const getMailerObject = (mailerId) => {
        for (let m of mailers)
            if (m.id === mailerId) return m;
    }

    useEffect(() => {
        let mailerObj = getMailerObject(props.mailerId);
        let fstTemplate = mailerObj ? mailerObj.templates[0].id : "";
        setTemplateId(fstTemplate);
        setMailer(mailerObj);
    }, [])

    useEffect(() => {
        setTemplate(getTemplateObject(templateId));
    }, [templateId])

    useEffect(() => {
        // console.log("blocakdata", props.blockData.template)
        // console.log("mailer", props.mailer.templates[0])
        // let parsed = JSON.parse
        // let parsed = template
        let content = getTemplateObject(templateId) ? getTemplateObject(templateId).content : ""
        setReqContext(findTemplateContextRequired(content));
    }, [templateId])

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (editorVariant === 'middleware') {
            props.editBlockMiddleware(props.id, {
                ...props.blockData, dataContext, recipient, subject,
                mailer: mailer ? mailer.name : "",
                template: template ? template.name : "",
                sender: mailer ? mailer.sender : ""
            });
        } else {
            props.editBlock(props.id, {
                ...props.blockData, dataContext, recipient, subject,
                mailer: mailer ? mailer.name : "",
                template: template ? template.name : "",
                sender: mailer ? mailer.sender : ""
            });
        }
    }, [dataContext, recipient, subject, template]);

    // const handleSetVarName = (e) => {
    //     if (isAlphaNumeric(e.target.value)) {
    //         setVarName(e.target.value);
    //     }
    // };
    //
    // const handleSaveBlock = () => {
    //     props.editBlock(props.id, {
    //         params, varName,
    //     });
    // };

    return (
        <div style={{...styles.main, padding: props.opened ? '1em' : '0'}}>
            {props.opened
                && (
                    <Stack spacing={0}>

                        <Grid container spacing={0}>

                            {/* RECIPIENT */}
                            <Grid item xs={6} style={styles.gridItem}>
                                <Typography variant="caption" display="block" style={styles.optionTitle}>
                                    recipient:
                                </Typography>
                                <TextInput
                                    placeholder={"user.email"}
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                />
                            </Grid>

                            {/* TEMPLATE SELECT */}
                            <Grid item xs={6} style={styles.gridItem}>
                                <Typography variant="caption" display="block" style={styles.optionTitle}>
                                    template:
                                </Typography>
                                <SelectInput
                                    onChange={(e) => setTemplateId(e.target.value)}
                                >
                                    {mailer && mailer.templates.map((t) => (
                                        <option value={t.id} key={t.id}>
                                            {t.name}
                                        </option>
                                    ))}
                                </SelectInput>
                            </Grid>

                            {/* SUBJECT */}
                            <Grid item xs={12} style={styles.gridItem}>
                                <Typography variant="caption" display="block" style={styles.optionTitle}>
                                    subject:
                                </Typography>
                                <TextInput
                                    placeholder={"Welcome to Neutrino!"}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </Grid>

                            {/* TEMPLATE CONTEXT */}
                            { reqContext && reqContext.length > 0 &&
                                <Grid item xs={12} style={styles.gridItem}>
                                    <Typography variant="caption" display="block" style={{...styles.optionTitle, width: "15em"}}>
                                        template context:
                                    </Typography>
                                    <TextInput
                                        placeholder={getDataContextHelperTxt(reqContext)}
                                        value={dataContext}
                                        onChange={(e) => setDataContext(e.target.value)}
                                    />
                                </Grid>
                            }

                            {/* CONTEXT HELPER DISPLAY */}
                            { reqContext && reqContext.length > 0 &&
                            <Grid item xs={12} style={styles.gridItem}>
                                <Typography variant="caption" display="block" style={{...styles.optionTitle, width: "15em"}}>
                                    required template context:
                                </Typography>
                                <div className={"flex ml-1 mt-1"}>
                                    {reqContext.map((c) => (
                                        <Chip
                                            label={c}
                                            size={"small"}
                                            color={dataContext.includes(c) ? "primary" : "warning"}
                                            style={{ width: 85, marginRight: 5 }}
                                        />
                                    ))}
                                </div>
                            </Grid>
                            }
                        </Grid>
                    </Stack>
                )}
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    controllers: state.controllersReducer.controllers,
    saveCount: state.routesReducer.saveCount,
    refresh: state.routesReducer.refresh,
    blockCache: state.routesReducer.activeSequenceBlockCache,
    isDragging: state.routesReducer.isDragging,
});
const mapDispatchToProps = (dispatch) => ({
    editBlock: (id, data) => dispatch(editBlock(id, data)),
    editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailerBlock);

const styles = {
    optionTitle: {
        fontWeight: 'bold',
        opacity: 0.6,
        width: '6em',
        marginLeft: '.5em',
        marginTop: 0,
    },
    container: {
        borderRadius: '.5em',
        border: '1px solid lightgray',
    },
    header: {
        padding: '.1em .1em .1em 1em',
        borderTopLeftRadius: '.5em',
        borderTopRightRadius: '.5em',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    main: {
        // padding: "1em"
    },
    textInput: {
        width: '100%',
        height: '1.5em',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: '1em',
    },
};
