import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import './Components.css';
import { WidthContext } from '../hooks/WidthContext';

const INIT_WIDTH = 250

export default function ResizeableSidebar(props) {
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(props.initWidth || INIT_WIDTH);

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right
          - mouseMoveEvent.clientX,

        );
      }
    },
    [isResizing],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <div
      ref={sidebarRef}
      className="hidden lg:flex app-sidebar border-l border-gray-200 overflow-y-auto overflow-x-hidden"
      style={{ ...props.style, width: sidebarWidth, borderRight: 0 }}
    >
      <div className="app-sidebar-resizer" onMouseDown={startResizing} />
      <WidthContext.Provider value={sidebarWidth}>
        <div className="app-sidebar-content">{props.children}</div>
      </WidthContext.Provider>
    </div>
  );
};
