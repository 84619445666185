import React, {
  useState, useRef, useLayoutEffect, useContext
} from 'react';
import {
  Typography, Stack, Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { isAlphaNumeric } from '../../services/helpers';
import { editBlock } from '../../redux/actions/Routes';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import CheckboxInput from './CheckboxInput';
import { EditorContext } from './EditorContext';
import { editBlockMiddleware } from '../../redux/actions/Middlewares';

const bcryptVariations = ['hash', 'compare']

function BcryptBlock(props) {
  const editorVariant = useContext(EditorContext);  // middleware or route editor
  const [bcryptVariant, setBcryptVariant] = useState(props.blockData.bcryptVariant || bcryptVariations[0]);
  const [plainText, setPlainText] = useState(props.blockData.plainText || '');
  const [hash, setHash] = useState(props.blockData.hash || '');
  const [saltRounds, setSaltRounds] = useState(props.blockData.saltRounds || 10);
  const [varName, setVarName] = useState(props.blockData.varName || '');

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // let modelName = props.models.find(m => m.id === model);
    if (editorVariant === 'middleware') {
      props.editBlockMiddleware(props.id, {
        ...props.blockData, bcryptVariant, plainText, hash, saltRounds, varName,
      });
    } else {
      props.editBlock(props.id, {
        ...props.blockData, bcryptVariant, plainText, hash, saltRounds, varName
      });
    };
  }, [bcryptVariant, plainText, hash, saltRounds, varName]);

  const handleSaveBlock = () => {
    props.editBlock(props.id, {
      params, varName, model, multiple,
    });
  };

  return (
    <div style={{ ...styles.main, padding: props.opened ? '1em' : '0' }}>
      { props.opened
    && (
    <Stack spacing={0}>

      <Grid container spacing={0}>

        {/* MODEL SELECT */}
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            variant:
          </Typography>
          <SelectInput
            className="mt-5"
            value={bcryptVariant}
            onChange={(e) => setBcryptVariant(e.target.value)}
          >
            {bcryptVariations.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </SelectInput>
        </Grid>

        {/* COMPARE INPUTS */}
        { bcryptVariant === 'compare' && 
        <Grid item xs={5} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            plaintext:
          </Typography>
          <TextInput 
            placeholder={"req.body.password"}
            value={plainText}
            onChange={(e) => setPlainText(e.target.value)}
          />
        </Grid>
        }

        { bcryptVariant === 'compare' && 
        <Grid item xs={5} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            hash:
          </Typography>
          <TextInput 
            placeholder={"user.password"}
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
        </Grid>
        }

        {/* HASH INPUTS */}
        { bcryptVariant === 'hash' && 
        <Grid item xs={7} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            plaintext:
          </Typography>
          <TextInput 
            placeholder={"req.body.password"}
            value={plainText}
            onChange={(e) => setPlainText(e.target.value)}
          />
        </Grid>
        }

        { bcryptVariant === 'hash' && 
        <Grid item xs={2} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            salts:
          </Typography>
          <TextInput
            type='number'
            value={saltRounds}
            onChange={(e) => setSaltRounds(e.target.value)}
          />
        </Grid>
        }

        <Grid item xs={3} style={styles.gridItem}>
          <Typography variant="caption" display="block" style={styles.optionTitle}>
            var name:
          </Typography>
          <TextInput 
            placeholder={"req.body.password"}
            value={varName}
            onChange={(e) => setVarName(e.target.value)}
          />
        </Grid>

      </Grid>
    </Stack>
    )}
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  saveCount: state.routesReducer.saveCount,
  refresh: state.routesReducer.refresh,
  blockCache: state.routesReducer.activeSequenceBlockCache,
  isDragging: state.routesReducer.isDragging,
});
const mapDispatchToProps = (dispatch) => ({
  editBlock: (id, data) => dispatch(editBlock(id, data)),
  editBlockMiddleware: (id, data) => dispatch(editBlockMiddleware(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BcryptBlock);

const styles = {
  optionTitle: {
    fontWeight: 'bold',
    opacity: 0.6,
    marginLeft: '.5em',
    marginTop: 0,
  },
  container: {
    borderRadius: '.5em',
    border: '1px solid lightgray',
  },
  header: {
    padding: '.1em .1em .1em 1em',
    borderTopLeftRadius: '.5em',
    borderTopRightRadius: '.5em',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    // padding: "1em"
  },
  textInput: {
    width: '100%',
    height: '1.5em',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: '1em',
  },
};
