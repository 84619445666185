/* eslint-disable react/prop-types */
import '../App.css';
import React, {
  useState, useContext, useEffect, Fragment,
} from 'react';
import {
  AppBar, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import {connect, useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { combineReducers } from '../util/backendPrep';

import useAuth from '../hooks/useAuth';
import {
  resetProject,
  setActiveProjectData,
  setManyToMany,
  togglePagination,
} from '../redux/actions/Models';
import { setActiveProject } from '../redux/actions/ActiveProject';
import { UserContext } from '../hooks/UserContext';
import authHeader from '../services/auth-header';
import useApi from '../hooks/useApi';
import { BUILDER_BACKEND_URL, GENERATOR_BACKEND_URL } from '../constants/constants';
import { setWarnings } from '../redux/actions/Warnings';
import { downloadTxtFile } from '../util/downloadTxtFile';
import { setControllers } from '../redux/actions/Controllers';
import { setRoutes } from '../redux/actions/Routes';

const _ = require('lodash');
import { Menu, Transition } from '@headlessui/react';
import {
  MenuAlt2Icon,
  PlusSmIcon,
} from '@heroicons/react/outline';
import { setMiddlewares } from '../redux/actions/Middlewares';
import { setAuthReducer } from '../redux/actions/Auth';
import { setModelRelations } from '../redux/actions/Relations';
import {setMailers} from "../redux/MailersSlice";
import {getPreppedProjectData, handleSetActiveProject} from "../util/initializeProject";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function TopNav({ setMobileMenuOpen, ...props }) {
  const dispatch = useDispatch();
  // redux state
  const mailers = useSelector((state) => state.mailersReducer.mailers);

  const userNavigation = [
    { name: 'Profile', action: () => { navigate(`/users/${authUser}`); handleClose() }},
    // { name: 'Settings', action: () => { navigate('/settings'); handleClose() }},
    { name: 'Sign out', action: () => { logout(); handleClose() }},
  ];

  const { authUser, setAuthUser } = useContext(UserContext);
  const {
    result: user, loading, error, refresh,
  } = useApi(`${BUILDER_BACKEND_URL}/users/${authUser}`);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    result: currActiveProject,
    loading: projectLoading,
    error: projectError,
    refresh: projectRefresh,
  } = useApi(`${BUILDER_BACKEND_URL}/projects/${props.activeProject && props.activeProject}`);

  useEffect(() => {
    projectRefresh();
  }, [props.activeProject]);

  useEffect(() => {
    refresh();
  }, [authUser]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateProject = (params, user) => {
    axios.post(`${BUILDER_BACKEND_URL}/projects`, {
      params,
      user,
    }, { headers: authHeader() })
      .then((res) => {
        refresh();
        handleSetActiveProject(res.data.params, res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (id, params) => {
    axios.put(`${BUILDER_BACKEND_URL}/projects/${id}/edit`, {
      params,
    }, { headers: authHeader() })
      .then(() => {})
      .catch((err) => {
        alert(err);
      });
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const [compileLoading, setCompileLoading] = useState(false);
  const [toggleRecompile, setToggleRecompile] = useState(false);
  const [lastCompileData, setLastCompileData] = useState(null);
  const [projectHasChanged, setProjectHasChanged] = useState(false);
  const recompileTimeout = 30000;

  /*
   * On first render:
   *  - initiate recompile timer
   *  - initiate lastCompileData
   */
  useEffect(() => {
    setTimeout(() => {
      setToggleRecompile(!toggleRecompile);
    }, recompileTimeout);

    const projectData = getPreppedProjectData(true);
    setLastCompileData(projectData);
  }, []);

  /*
   *
   */
  useEffect(() => {
    setProjectHasChanged(true);
  }, [props.models, props.projectName, props.serverPort, props.mongostr, props.email]);

  /*
   * Toggles recompile state every x seconds
   */
  useEffect(() => {
    const projectData = getPreppedProjectData(true);

    if (JSON.stringify(lastCompileData) !== JSON.stringify(projectData)) {
      compileProject();
      setLastCompileData(projectData);
      setProjectHasChanged(false);
    }

    setTimeout(() => {
      setToggleRecompile(!toggleRecompile);
    }, recompileTimeout);
  }, [toggleRecompile]);

  async function handleSave() {
    if (props.activeProject || authUser) {
      setSaveLoading(true);
      setTimeout(() => { setSaveLoading(false); }, 300);

      const preppedData = combineReducers(
        props.controllers,
        props.currProjectParams,  // models
        props.routes,
        props.middlewares,
        props.relations,
        mailers,
        props.auth,
      );

      // saving existing project
      if (props.activeProject) {
        handleUpdate(
          props.activeProject,
          JSON.stringify(preppedData),
        );
      }
      // creating new project
      else {
        handleCreateProject(
          JSON.stringify(preppedData),
          authUser,
        );
      }
    } else {
      navigate('/login');
    }
    projectRefresh();
  }

  /*
   * Compile project makes requests to generatr backend to:
   *  - check for warnings
   *  - parse relationships
   */
  const compileProject = async () => {
    setCompileLoading(true);
    const projectData = getPreppedProjectData(true);

    /*
     * For aesthetic purposes, setting a minimum loading time of 1/2 second
     */
    let timeoutDone = false;
    let axiosDone = false;
    setTimeout(() => {
      timeoutDone = true;
      if (axiosDone) {
        setCompileLoading(false);
      }
    }, 500);

    /*
     * Check for warnings
     */
    axios.put(`${GENERATOR_BACKEND_URL}/getwarnings`, projectData)
      .then((res) => {
        props.setWarnings(res.data.warnings);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        axiosDone = true;
        if (timeoutDone) {
          setCompileLoading(false);
        }
      });
  };

  useEffect(() => {
    projectRefresh();
  }, [saveLoading]);

  return (
    <header className="w-full">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          {/* PROJECT NAME */}
          <div className='flex items-center font-medium'>
            {/* (untitled) */}
            {props.projectName === ''
              ? 'UntitledProject'
              : props.projectName}

            {/* (saved/usaved) */}
            {props.activeProject
              ? ''
                : '*'}
          </div>

          <div className='flex-1'/>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">

            

            <div style={{ flex: 1 }} />

            {/* COMPILE BUTTON */}
            {props.models && props.models.length > 0
              && (
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginRight: ".5em", width: 85,
              }}
              >
                {
                  !compileLoading
                    ? (
                      <Button
                        style={{ width: '100%' }}
                        color="primary"
                        variant="outlined"
                        onClick={compileProject}
                      // potentially fix
                        disabled={currActiveProject.params === JSON.stringify(props.currProjectParams)}
                      >
                        COMPILE
                      </Button>
                    )
                    : <CircularProgress />
                }
              </div>
              )}

            {/* SAVE BUTTON */}
            {props.models && props.models.length > 0
              && (
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginRight: ".5em", width: 85,
              }}
              >
                {
                  !saveLoading
                    ? (
                      <Button
                        style={{ width: '100%' }}
                        color="primary"
                      // size='small'
                        variant={currActiveProject.params === JSON.stringify(props.currProjectParams) ? 'outlined' : 'contained'}
                      // onMouseDown={handleSave}
                      // onMouseUp={_.debounce(projectRefresh, 250)}
                        onClick={handleSave}
                        disabled={currActiveProject.params === JSON.stringify(props.currProjectParams)}
                      >
                        SAVE

                      </Button>
                    )
                    : <CircularProgress />
                }
              </div>
              )}

            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span className="sr-only">Open user menu</span>
                  <Avatar>{user && user.username.charAt(0).toUpperCase()}</Avatar>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <div
                          onClick={item.action}
                          style={{cursor: 'pointer'}}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          )}
                        >
                          {item.name}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  controllers: state.controllersReducer.controllers,
  routes: state.routesReducer.routes,
  middlewares: state.middlewaresReducer.middlewares,
  relations: state.relationsReducer.relations,
  currProjectParams: state.modelsReducer,
  projectName: state.modelsReducer.projectName,
  serverPort: state.modelsReducer.serverPort,
  mongostr: state.modelsReducer.mongostr,
  email: state.modelsReducer.email,
  activeProject: state.activeProjectReducer.activeProject,
  auth: state.authReducer
});
const mapDispatchToProps = (dispatch) => ({
  togglePagination: (key) => dispatch(togglePagination(key)),
  setReduxActiveProjectId: (params) => dispatch(setActiveProject(params)),
  setReduxModelsData: (params) => dispatch(setActiveProjectData(params)),
  setReduxControllersData: (params) => dispatch(setControllers(params)),
  setReduxRoutesData: (params) => dispatch(setRoutes(params)),
  setReduxMiddlewaresData: (data) => dispatch(setMiddlewares(data)),
  setReduxRelationsData: (data) => dispatch(setModelRelations(data)),
  setReduxAuthData: (data) => dispatch(setAuthReducer(data)),
  setWarnings: (warnings) => dispatch(setWarnings(warnings)),
  setManyToMany: (modelName, relations) => dispatch(setManyToMany(modelName, relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);