import React from 'react';

export default function TextInput({
  label,
  value,
  onChange,
  type,
  placeholder,
  description,
  style,
  className,
}) {
  return (
    <div style={style} className={className}>
      { label && 
        <label htmlFor="email" className="block text-sm font-normal text-gray-600">
          {label}
        </label>
      }
      
      <div className="mt-1">
        <input
          type={type}
          onChange={onChange}
          id="text-input"
          style={{borderWidth: 1}}
          className="block w-full ml-0 pl-3 pr-2 rounded-md border-slate-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-8"
          value={value}
          placeholder={placeholder}
        />
      </div>
      {description && 
      <p className="mt-2 text-sm text-gray-500" id="email-description">
        {description}
      </p>
      }
    </div>
  )
}