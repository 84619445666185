import {
  SET_ACTIVE_PROJECT,
} from '../Types';

const initialState = {
  activeProject: null,
};

const ActiveProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.data,
      };

    default:
      return state;
  }
};

export default ActiveProjectReducer;
