import {
  SELECT_AUTH, SET_AUTH_REDUCER,
} from '../Types';

const initialState = {
  authConfig: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_AUTH:
      return {
        ...state,
        authConfig: action.data
      };

    case SET_AUTH_REDUCER:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default AuthReducer;
