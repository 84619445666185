import '../App.css';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connect } from 'react-redux';
import {
  addModel, addParam, addRelation, removeModel, addBelongsTo,
} from '../redux/actions/Models';
import { toSnakeCase } from '../util/caseMatching';

function NewParamBox(props) {
  const [dataType, setDataType] = useState('String');
  const [paramType, setParamType] = useState('data');
  const [name, setName] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [modelRelation, setModelRelation] = useState('');

  useEffect(() => {
    if (paramType === 'data') {
      if (name !== '' && dataType !== '' && paramType !== '') {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (name !== '' && paramType !== '' && modelRelation !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, paramType, dataType, modelRelation]);

  /*
   * Alternate between data and has_many / belongs_to relation
   */
  const handleParamSelect = (type) => {
    setParamType(type);
  };

  /*
   * Handles drop down selection.
   *  If paramType == data, select between string, number, boolean, etc.
   *  If paramType == blongs_to or has_many, select between other model objects
   */
  const handleSelect = (event) => {
    if (paramType === 'data') {
      setDataType(event.target.value);
    } else {
      setModelRelation(event.target.value);
    }
  };

  const handleSubmit = () => {
    let param = { name: toSnakeCase(name), paramType };
    if (paramType === 'data') {
      param = { ...param, dataType };
      props.addParam(props.model.id, param);
    } else {
      param = { ...param, dataType: modelRelation };
      if (paramType === 'belongs_to') {
        props.addBelongsTo(props.model.id, modelRelation, toSnakeCase(name));  // name is same as 'alias'
      } else {
        props.addRelation(props.model.id, modelRelation, toSnakeCase(name));   // name is same as 'alias'
      }
    }
    props.close();
  };

  return (
    <div className="row" style={{ marginTop: 15, marginLeft: 15 }}>
      <Paper elevation={0} variant="outlined" style={{ padding: 15, width: '55%', marginRight: 25 }}>

        {/* DATA / RELATION SELCT */}
        <div className="row" style={{ marginBottom: 10 }}>
          {/* DATA SELECT */}
          <Chip
            label="data"
            onClick={() => { handleParamSelect('data'); }}
            color={paramType === 'data' ? 'primary' : 'default'}
            style={{ width: 85 }}
          />
          {/* HAS MANY RELATION SELECT */}
          {/*<Chip*/}
          {/*  label="has many"*/}
          {/*  onClick={() => handleParamSelect('has_many')}*/}
          {/*  color={paramType === 'has_many' ? 'primary' : 'default'}*/}
          {/*  style={{ width: 85, margin: '0px 10px 0px 10px' }}*/}
          {/*/>*/}
          {/* BELONGS TO RELATION SELECT */}
          {/*<Chip*/}
          {/*  label="belongs to"*/}
          {/*  onClick={() => handleParamSelect('belongs_to')}*/}
          {/*  color={paramType === 'belongs_to' ? 'primary' : 'default'}*/}
          {/*  style={{ width: 85 }}*/}
          {/*/>*/}
        </div>

        {/* PARAM NAME (ALIAS if relation) */}
        <div className="row">
          <TextField
            label="param_name"
            variant="outlined"
            size="small"
            style={{ marginRight: 15 }}
            onChange={(e) => setName(e.target.value)}
          />

          {/* DROPDOWN (DATATYPE / MODEL for relation)*/}
          <div
            style={{
              marginBottom: 2, marginRight: 15, flex: 1, textAlign: 'right',
            }}
          >
            {paramType === 'data' ? 'data type:' : 'model: '}
          </div>

          {/* DROPDOWN */}
          {paramType === 'data'
            ? (
              <Select
                size="small"
                value={dataType}
                onChange={handleSelect}
                style={{ width: 115 }}
              >
                <MenuItem value="String">String</MenuItem>
                <MenuItem value="Text">Text</MenuItem>
                <MenuItem value="Number">Number</MenuItem>
                <MenuItem value="Boolean">Boolean</MenuItem>
                <MenuItem value="Date">Date</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
                <MenuItem value="mongoose.Schema.Types.ObjectId">Object ID</MenuItem>
              </Select>
            )
            : (
              <Select
                size="small"
                value={modelRelation}
                onChange={handleSelect}
                style={{ width: 115 }}
              >
                {props.models.map((model, key) => (
                  <MenuItem value={model.name} key={key}>{model.name}</MenuItem>
                ))}
              </Select>
            )}
        </div>
      </Paper>

      {/* SUBMIT */}
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Done
      </Button>
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
});
const mapDispatchToProps = (dispatch) => ({
  addModel: (name) => dispatch(addModel(name)),
  removeModel: (key) => dispatch(removeModel(key)),
  addParam: (modelId, param) => dispatch(addParam(modelId, param)),
  addRelation: (modelId, data, alias) => dispatch(addRelation(modelId, data, alias)),
  addBelongsTo: (modelId, data, alias) => dispatch(addBelongsTo(modelId, data, alias)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewParamBox);
