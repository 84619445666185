import {
  ADD_MODEL,
  ADD_PARAM,
  ADD_RELATION,
  EDIT_MODEL,
  EDIT_PARAM,
  REMOVE_MODEL,
  REMOVE_PARAM,
  REMOVE_RELATION,
  ADD_BELONGS_TO,
  REMOVE_BELONGS_TO,
  SET_EMAIL,
  SET_MONGOSTR,
  SET_DATABASE_TYPE, 
  SET_PROJECT_NAME,
  SET_ROUTE_LOGIC,
  SET_ROUTE_MIDDLEWARE, SET_SERVER_PORT,
  TOGGLE_AUTH_OBJECT,
  TOGGLE_PAGINATION,
  TOGGLE_ROUTE_ENABLE,
  TOGGLE_ROUTE_PROTECTED,
  SET_ACTIVE_PROJECT_DATA,
  RESET_PROJECT,
  SET_MANY_TO_MANY,
} from '../Types';

export const addModel = (name, id, params) => ({
  type: ADD_MODEL,
  name,
  id,
  params,
});

export const editModel = (id, data) => ({
  type: EDIT_MODEL,
  modelId: id,
  data,
});

export const removeModel = (data) => ({
  type: REMOVE_MODEL,
  key: data,
});

export const addParam = (modelId, data) => ({
  type: ADD_PARAM,
  modelId,
  data,
});

export const editParam = (modelId, paramId, data) => ({
  type: EDIT_PARAM,
  modelId,
  paramId,
  data,
});

export const removeParam = (modelId, paramName) => (
  {
    type: REMOVE_PARAM,
    modelId,
    paramName,
  }
);

export const addRelation = (modelId, manyName, alias) => ({
  type: ADD_RELATION,
  modelId,
  manyName,
  alias,
});

export const removeRelation = (modelId, manyName) => ({
  type: REMOVE_RELATION,
  modelId,
  manyName,
});

export const addBelongsTo = (modelId, manyName, alias) => ({
  type: ADD_BELONGS_TO,
  modelId,
  manyName,
  alias,
});

export const removeBelongsTo = (modelId, parent) => ({
  type: REMOVE_BELONGS_TO,
  modelId,
  parent,
});

export const toggleAuth = (modelId, data) => ({
  type: TOGGLE_AUTH_OBJECT,
  modelId,
  data,
});

export const toggleRouteEnable = (modelId, routeName) => ({
  type: TOGGLE_ROUTE_ENABLE,
  modelId,
  routeName,
});

export const toggleRouteProtected = (modelId, routeName) => ({
  type: TOGGLE_ROUTE_PROTECTED,
  modelId,
  routeName,
});

export const setRouteLogic = (modelId, routeName, data) => ({
  type: SET_ROUTE_LOGIC,
  modelId,
  routeName,
  data,
});

export const setRouteMiddleware = (modelId, routeName, data) => ({
  type: SET_ROUTE_MIDDLEWARE,
  modelId,
  routeName,
  data,
});

export const togglePagination = (modelId) => ({
  type: TOGGLE_PAGINATION,
  modelId,
});

export const setServerPort = (port) => ({
  type: SET_SERVER_PORT,
  data: port,
});

export const setMongostr = (data) => ({
  type: SET_MONGOSTR,
  data,
});

export const setDatabaseType = (data) => ({
  type: SET_DATABASE_TYPE,
  data,
});

export const setProjectName = (name) => ({
  type: SET_PROJECT_NAME,
  data: name,
});

export const setEmail = (data) => ({
  type: SET_EMAIL,
  data,
});

export const setActiveProjectData = (data) => ({
  type: SET_ACTIVE_PROJECT_DATA,
  data,
});

export const resetProject = () => ({
  type: RESET_PROJECT,
});

export const setManyToMany = (modelName, relations) => ({
  type: SET_MANY_TO_MANY,
  modelName,
  data: relations,
});
