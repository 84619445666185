import React, { useState } from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import '../App.css';
import './AuthStyles.css';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, loading, error } = useAuth();
  const navigate = useNavigate();
  const verifyEmailError = 'Please verify your email by following the link sent';

  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">

        <Stack spacing={3} style={{ width: '100%' }}>
          <h1 className="title text-center">Login</h1>

          {/* ERROR DISPLAY */}
          {error && (
            <div style={{ color: 'red', textAlign: 'center' }}>
              {error}
              {' '}
              <br />

              {error === verifyEmailError
              && (
              <a
                style={{
                  textAlign: 'center', color: 'blue', cursor: 'pointer', textDecoration: 'underline', marginTop: 10,
                }}
                onClick={() => navigate('/request-verification-email')}
              >
                request verification email
              </a>
              )}
            </div>
          )}

          {/* USERNAME / EMAIL */}
          <TextField
            label="username or email"
            onChange={(e) => setUsername(e.target.value)}
            size="small"
          />

          {/* PASSWORD */}
          <TextField
            label="password"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            size="small"
          />

          {/* Submit */}
          <Button
            variant="contained"
            onClick={() => login(username, password)}
          >
            { loading ? 'loading...' : 'login'}
          </Button>

          {/* <div
            className="clickable"
            style={{textAlign: "center"}}
            onClick={() => navigate('/register')}
          >
            Create an Account!
          </div> */}

          <div
            className="clickable"
            style={{ textAlign: 'center' }}
            onClick={() => navigate('/forgotpassword')}
          >
            Forgot Password?
          </div>

          <div className='text-center'>
            Don't have an account? {" "}
            <a 
              onClick={() => navigate('/register')}
              className="underline cursor-pointer"
            >sign up</a>
          </div>
        </Stack>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}
