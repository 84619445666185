import '../App.css';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {Menu, MenuItem, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {removeMailer} from "../redux/MailersSlice";
import {useDispatch} from "react-redux";

export default function MailerBox({ mailer, openTemplateModal, openMailerModal }) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const openMore = Boolean(anchorEl);
    const handleClickMore = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setAnchorEl(null);
    };

    const handleDeleteMailer = () => {
        dispatch(removeMailer(mailer.id));
        handleCloseMore();
    }

    return (
        <div className="col">
            <div className={"flex items-center"}>
                <Typography variant="caption" display="block" style={styles.idTitle}>
                    {mailer.id}
                </Typography>
                <div style={{ flex: 1 }} />
                <IconButton aria-label="delete"
                            onClick={handleClickMore}
                            size="small">
                    <MoreHorizIcon />
                </IconButton>
            </div>

            <div className="row">
                <Paper
                    elevation={0}
                    variant="outlined"
                    style={{
                        padding: '1em', flex: 1
                    }}
                >

                    {/* MODEL NAME TEXT FIELD */}
                    <div className="row">
                        <div className={"mr-6 "}>
                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6}}>
                                mailer name
                            </Typography>
                            <Typography gutterBottom>
                                {mailer.name}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6 }}>
                                sender address
                            </Typography>
                            <Typography gutterBottom>
                                {mailer.sender}
                            </Typography>
                        </div>
                    </div>

                    {/* Templates  */}
                    <div className={"mr-6 "}>
                        <Typography variant="caption" display="block" gutterBottom style={{ fontWeight: 'bold', opacity: 0.6}}>
                            templates
                        </Typography>
                        <Button
                            variant={"outlined"}
                            size={"small"}
                            onClick={openTemplateModal}
                        >
                            add template
                        </Button>
                        <div>
                            {mailer.templates.length > 0
                                ? mailer.templates.map((template, key) => (
                                    <div
                                        key={`template-${key}`}
                                        className={"rounded-md bg-gray-100 p-1 pl-5 ml-2 mt-3 cursor-pointer"}
                                        onClick={() => openTemplateModal(template, "edit")}
                                    >{template.name}</div>
                                ))
                                :
                                <div className={"opacity-60 mt-2 ml-1"}>
                                    {`This mailer has no templates... create an email template to start using 
                                    ${mailer.name} to send emails`}
                                </div>
                            }
                        </div>
                    </div>
                </Paper>
            </div>

            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={openMore}
                onClose={handleCloseMore}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={openMailerModal}>edit mailer</MenuItem>
                <MenuItem onClick={handleDeleteMailer}>delete mailer</MenuItem>
            </Menu>
        </div>
    );
}


const styles = {
    idTitle: {
        fontWeight: 'bold',
        opacity: 0.4,
        marginLeft: '.5em',
        marginTop: '1em',
        textAlign: 'left',
        fontSize: '.5em',
    },
};
