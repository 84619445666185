import React, {useState} from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import {connect} from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import JavascriptIcon from '@mui/icons-material/Javascript';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {GENERATOR_BACKEND_URL} from '../constants/constants';
import {getPreppedProjectData} from "../util/initializeProject";

function FolderDisplay(props) {
    const navigate = useNavigate();
    const [directory, setDirectory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useState(() => {
        const projectData = getPreppedProjectData(true);

        let cancelled = false;
        setLoading(true);
        axios.put(`${GENERATOR_BACKEND_URL}/builddirectory`, projectData)
            .then((r) => {
                if (!cancelled) {
                    setDirectory(r.data);
                    setLoading(false);
                    setError(null);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(err.response.data.message);
            });

        return () => {
            cancelled = true;
        };
    }, []);

    const handleClickNode = (node) => {
        if (node.type === 'file') {
            navigate(`?page=${node.id}`);
        }
    };

    const renderTree = (nodes) => {
        console.log(nodes)
        return (

        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={nodes.filename || nodes.name}
            onClick={() => handleClickNode(nodes)}
            endIcon={nodes.type === 'folder' ? <FolderIcon style={{ color: 'gray' }} /> : <JavascriptIcon />}
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    )};

    if (loading || !directory) {
        return <div>loading...</div>;
    }
    if (error) {
        return <div>error building project directory</div>;
    }
    return (
        <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpanded={['server']}
            defaultExpandIcon={<ChevronRightIcon/>}
            sx={{
                flexGrow: 1, maxWidth: 400, overflowY: 'hidden', overflowX: 'hidden', color: 'black',
            }}
        >
            {renderTree(directory)}
        </TreeView>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    controllers: state.controllersReducer.controllers,
    routes: state.routesReducer.routes,
    middlewares: state.middlewaresReducer.middlewares,
    relations: state.relationsReducer.relations,
    auth: state.authReducer,
    projectName: state.modelsReducer.projectName,
    serverPort: state.modelsReducer.serverPort,
    mongostr: state.modelsReducer.mongostr,
    email: state.modelsReducer.email,
});
const mapDispatchToProps = (dispatch) => ({
    // addModel: (name) => dispatch(addModel(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FolderDisplay);
