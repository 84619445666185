import {
  ADD_MODEL,
  ADD_PARAM,
  ADD_RELATION,
  EDIT_MODEL,
  EDIT_PARAM,
  REMOVE_MODEL,
  REMOVE_PARAM,
  REMOVE_RELATION,
  RESET_PROJECT,
  SET_ACTIVE_PROJECT_DATA,
  ADD_BELONGS_TO, SET_EMAIL, SET_MONGOSTR,
  SET_PROJECT_NAME,
  SET_ROUTE_LOGIC,
  SET_ROUTE_MIDDLEWARE, SET_SERVER_PORT,
  TOGGLE_AUTH_OBJECT,
  TOGGLE_PAGINATION,
  TOGGLE_ROUTE_ENABLE,
  TOGGLE_ROUTE_PROTECTED,
  REMOVE_BELONGS_TO,
  SET_MANY_TO_MANY,
} from '../Types';
import { toPascalCase, toSnakeCase } from '../../util/caseMatching';

const routes = [
  {
    name: 'index', verb: 'get', path: '', action: '.index()', pagination: true, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'show', verb: 'get', path: '/:id', action: '.show()', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'create', verb: 'post', path: '', action: '.create()', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'update', verb: 'put', path: '/:id', action: '.update()', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'destroy', verb: 'delete', path: '/:id', action: '.destroy()', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'edit', verb: '-', path: '/edit', action: '-', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
  {
    name: 'new', verb: '-', path: '/new', action: '-', pagination: false, protected: false, disabled: false, logic: '', middleware: '',
  },
];

const initialState = {
  models: [
    /* {
      id: 0, name: "User", params: [{name: "age", paramType: "data", dataType: "Number"}],
      belongsTo: [],
      hasMany: ["Course"],
      manyToMany: [],
      routes: routes,
      pagination: false,
      auth: false
       }, */
  ],
  count: 0,
  mongostr: '',
  serverPort: 8080,
  projectName: '',
  email: '',
};

const ModelsReducer = (state = initialState, action) => {
  let newModels = JSON.parse(JSON.stringify(state.models));
  const toSnake = '';

  switch (action.type) {
    /*
     * ***** MODEL HANDLING *****
     */
    case ADD_MODEL:
      return {
        ...state,
        models: state.models.concat({
          id: action.id,
          name: toPascalCase(action.name),
          params: action.params ? action.params : [],
          belongsTo: [],
          hasMany: [],
          manyToMany: [],
          pagination: false,
          auth: false,
          warnings: [],
        }),
        count: state.count + 1,
      };
    case EDIT_MODEL:
      newModels.forEach(function (model, index) {
        if (model.id === action.modelId) {
          action.data.name = toPascalCase(action.data.name);
          this[index] = action.data;
        }
      }, newModels);

      return {
        ...state,
        models: newModels,
      };
    case REMOVE_MODEL:
      newModels = newModels.filter((model) => (model.id !== action.key));
      return {
        ...state,
        models: newModels,
      };

    /*
     * AUTH HANDLING
     */
    case TOGGLE_AUTH_OBJECT:
      newModels.forEach((model) => {
        if (model.id === action.modelId) {
          model.auth = action.data;

          /*
           * If setting auth object, then add missing required params
           */
          if (action.data) {
            let hasUsername = false;
            let hasEmail = false;
            let hasPassword = false;

            for (const param of model.params) {
              if (param.name.toLowerCase() === 'username') {
                hasUsername = true;
              } else if (param.name.toLowerCase() === 'email') {
                hasEmail = true;
              } else if (param.name.toLowerCase() === 'password') {
                hasPassword = true;
              }
            }

            if (!hasUsername) {
              model.params.push({ name: 'username', paramType: 'data', dataType: 'String' });
            }
            if (!hasEmail) {
              model.params.push({ name: 'email', paramType: 'data', dataType: 'String' });
            }
            if (!hasPassword) {
              model.params.push({ name: 'password', paramType: 'data', dataType: 'String' });
            }
          }
        }
      });

      return {
        ...state,
        models: newModels,
      };

    /*
     * ***** RELATION HANDLING *****
     */
    case ADD_RELATION:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].hasMany.push([action.manyName, action.alias]);
        }
      }
      return {
        ...state,
        models: newModels,
      };

    case REMOVE_RELATION:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].hasMany = newModels[i].hasMany.filter((many) => (many[0].toLowerCase()
          !== action.manyName.toLowerCase()));
        }
      }
      return {
        ...state,
        models: newModels,
      };

    case ADD_BELONGS_TO:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].belongsTo.push([action.manyName, action.alias]);
        }
      }
      return {
        ...state,
        models: newModels,
      };

    case REMOVE_BELONGS_TO:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].belongsTo = newModels[i].belongsTo.filter((parent) => (parent[0].toLowerCase() !== action.parent.toLowerCase()));
        }
      }

      return {
        ...state,
        models: newModels,
      };

    case SET_MANY_TO_MANY:
      newModels.forEach(function (model, index) {
        if (model.name.toLowerCase() === action.modelName.toLowerCase()) {
          this[index].manyToMany = action.data;
        }
      }, newModels);

      return {
        ...state,
        models: newModels,
      };

    case ADD_RELATION:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].hasMany.push([action.manyName, action.alias]);
        }
      }
      return {
        ...state,
        models: newModels,
      };

    /*
     * *** PARAM HANDLING ***
     */
    case ADD_PARAM:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].params.push(action.data);
        }
      }
      return {
        ...state,
        models: newModels,
      };
    case EDIT_PARAM:
      return {
        ...state,
      };
    case REMOVE_PARAM:
      for (let i = 0; i < newModels.length; i++) {
        if (newModels[i].id === action.modelId) {
          newModels[i].params = newModels[i].params.filter((param) => (param.name !== action.paramName));
        }
      }
      return {
        ...state,
        models: newModels,
      };

    case SET_MONGOSTR:
      return {
        ...state,
        mongostr: action.data,
      };

    case SET_SERVER_PORT:
      return {
        ...state,
        serverPort: action.data,
      };

    case SET_PROJECT_NAME:
      return {
        ...state,
        projectName: action.data,
      };

    case SET_EMAIL:
      return {
        ...state,
        email: action.data,
      };

    case SET_ACTIVE_PROJECT_DATA:
      return action.data;

    case RESET_PROJECT:
      return {
        ...state,
        models: [],
        count: 0,
        relationships: {
          otm: [],
          mtm: [],
        },
        warnings: [],
        mongostr: '',
        serverPort: 8080,
        projectName: '',
      };

    default:
      return state;
  }
};

export default ModelsReducer;
