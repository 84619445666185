import '../App.css';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '@mui/material';
import Alert from '@mui/material/Alert';
import ModelBox from '../components/ModelBox';
import {removeWarning} from '../redux/actions/Warnings';
import ModelModal from '../components/ModelModal';
import RelationModal from '../components/RelationModal';

export default function Models() {
    const dispatch = useDispatch()

    // redux state
    const models = useSelector((state) => state.modelsReducer.models);
    const warnings = useSelector((state) => state.warningsReducer.warnings);
    // local state
    const [openModal, setOpenModal] = useState(false);
    const [openRelationModal, setOpenRelationModal] = useState(false);
    const [relModalVariant, setRelModalVariant] = useState("new");
    const [editRelData, setEditRelData] = useState(null);

    const handleOpenEditRelationModal = (data) => {
        setEditRelData(data);
        setRelModalVariant("edit");
        setOpenRelationModal(true);
    }

    const handleCloseRelationModal = () => {
        setEditRelData(null);
        setRelModalVariant("new");
        setOpenRelationModal(false);
    }

    return (
        <div className='flex flex-col items-center mt-8 pb-32'>

            <div className='w-full flex'>

                {/* HEADING */}
                <div className="row flex flex-1 mr-8 ml-8">
                    <h1 className='cursor-pointer'>Models</h1>
                    <div className='flex-1'/>
                    <Button
                        variant="contained"
                        style={{marginRight: "2em", minWidth: "10em"}}
                        size='small'
                        onClick={() => setOpenModal(true)}
                    >
                        Add Model
                    </Button>
                    <Button
                        style={{minWidth: '10em'}}
                        variant="contained"
                        size='small'
                        onClick={() => setOpenRelationModal(true)}
                    >
                        Add Relation
                    </Button>
                </div>
            </div>


            {/* WARNINGS */}
            {warnings.length > 0
                && <h4 style={{textAlign: 'left'}}>Warnings</h4>}
            <div className='w-full flex flex-col'>
                {warnings.map((warning, i) => (
                    <div className="m-8 mb-0 mt-4" key={i}>
                        {warning.severity && warning.severity === 'Error'
                            ? (
                                <Alert
                                    severity="error"
                                >
                                    {warning.message}
                                </Alert>
                            )
                            : (
                                <Alert
                                    onClose={() => dispatch(removeWarning(warning.message))}
                                    severity="warning"
                                >
                                    {warning.message}
                                </Alert>
                            )}
                    </div>
                ))}
            </div>

            <div className='w-full flex flex-wrap pr-8 pl-8'>
                {models.length > 0
                    ? models.map((model, key) => (
                        <div className='w-full mt-5' key={model.id}>
                            <ModelBox model={model} key={key}
                                      openRelEdit={(relData) => handleOpenEditRelationModal(relData)}/>
                        </div>
                    ))
                    : (
                        <div style={{flex: 1, textAlign: 'left', color: 'gray'}}>
                            This project has no models, click on
                            'ADD MODEL' to get started
                        </div>
                    )}
            </div>

            <ModelModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
            />

            <RelationModal
                open={openRelationModal}
                handleClose={handleCloseRelationModal}
                variant={relModalVariant}
                relationData={editRelData}
            />
        </div>
    );
}
