import React, {
  useState, useEffect,
} from 'react';
import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BUILDER_BACKEND_URL } from '../constants/constants';

export default function VerifyEmail() {
  const { id, token } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);

  const sendVerifyRequest = () => {
    setError(null);
    setLoading(true);
    axios.get(`${BUILDER_BACKEND_URL}/users/${id}/verify/${token}`)
      .then((res) => {
        setLoading(false);
        setResponse(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    sendVerifyRequest();
  }, []);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">
        <Stack spacing={3} style={{width: '100%'}}>
          {error
            ? <h1 style={{ color: 'red' }} className="title">{error}</h1>
            : <h1 style={{ color: 'limegreen' }} className="title">Your Email has been Verified!</h1>}
        </Stack>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}
