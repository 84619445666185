import React, { useState, useContext } from 'react';
import './Styles.css';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import StoreIcon from '@mui/icons-material/Store';
import TagIcon from '@mui/icons-material/Tag';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';
import {
  setMongostr, setProjectName, setServerPort, setDatabaseType, setActiveProjectData, resetProject,
} from '../redux/actions/Models';
import { isAlphaNumeric } from '../services/helpers';
import { SimpleBlogTemplate, NewBlogTemplate, TwoSidedMarketplaceTemplate } from '../constants/templates';
import { setActiveProject } from '../redux/actions/ActiveProject';

const steps = ['Project Name', 'Template', 'Options'];

function NewProject(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { authUser, setAuthUser } = useContext(UserContext);
  const navigate = useNavigate();

  const isStepOptional = (step) => false;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const templates = [
    { label: 'simple blog', icon: <RateReviewIcon sx={{ fontSize: 40 }} />, disabled: false },
    { label: 'social media', icon: <TagIcon sx={{ fontSize: 40 }} />, disabled: true },
    { label: 'two-sided marketplace', icon: <StoreIcon sx={{ fontSize: 40 }} />, disabled: false },
    { label: 'blank project', icon: <NoteAddIcon sx={{ fontSize: 40 }} />, disabled: false },
  ];

  const [projectName, setProjectName] = useState('');
  const [template, setTemplate] = useState(null);
  const [port, setPort] = useState(8080);
  const [mongostr, setMongostr] = useState('');
  const [databaseType, setDatabaseType] = useState(''); 

  const handleFinish = () => {
    props.setActiveProject(null);
    props.resetProject();
    switch (template) {
      case 'simple blog':
        props.setActiveProjectData(JSON.parse(NewBlogTemplate));
        break;
      case 'two-sided marketplace':
        props.setActiveProjectData(JSON.parse(TwoSidedMarketplaceTemplate));
        break;
      default:
        break;
    }
    props.setProjectName(projectName);
    props.setServerPort(port);
    props.setMongoStr(mongostr);
    props.setDatabaseType(databaseType);
    navigate('/models');
  };

  const isStepFinished = (index) => {
    switch (index) {
      case 0:
        return projectName !== '' && isAlphaNumeric(projectName);
      case 1:
        return template !== null;
      case 2:
        return true;
      default:
        break;
    }
  };

  const getStepContent = (index) => {
    switch (index) {
      /* PROJECT NAME */
      case 0:
        return (
          <div>
            <TextField
              id="project-name"
              label="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              size="small"
              style={{ width: '50%' }}
              error={!isAlphaNumeric(projectName)}
              helperText={
                !isAlphaNumeric(projectName)
                  ? 'Project name must be alphanumeric'
                  : ''
}
            />
          </div>
        );

      /* TEMPLATES */
      case 1:
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {templates.map((t, i) => (
              <div
                key={i}
                className="templateButton"
                style={{
                  padding: 10,
                  opacity:
                    (!t.disabled && (authUser || t.label === 'blank project'))
                      ? 1 // if enabled and either logged in or blank template
                      : 0.5,
                  cursor:
                    (!t.disabled && (authUser || t.label === 'blank project'))
                      ? 'pointer' // if enabled and either logged in or blank template
                      : 'default',
                  // if selected
                  backgroundColor:
                    template === t.label
                      ? '#0a171f' // if selected
                      : null,
                  color:
                    template === t.label
                      ? 'white' // if selected
                      : null,
                }}
                onClick={() => {
                  // if enabled and either logged in or blank template
                  (!t.disabled && (authUser || t.label === 'blank project'))
                  && setTemplate(t.label);
                }}
              >
                <div>
                  { authUser
                    // if disabled
                    ? (t.disabled && 'Coming Soon!')
                    // if not blank template
                    : (t.label !== 'blank project' && 'sign in to use this template!')}
                </div>

                <div style={{
                  flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}
                >
                  {t.icon}
                </div>

                <div>
                  {t.label}
                </div>

              </div>
            ))}
          </div>
        );

      /* CONFIG */
      case 2:
        return (
          <div>
            <Stack spacing={2} alignItems="center">
              {/* <TextField
                id="project-name"
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                size="small"
                style={{width: "50%"}}
              /> */}

              <TextField
                id="mongo-id"
                label="Mongo Connection String (optional)"
                placeholder="mongodb+srv://..."
                value={mongostr}
                onChange={(e) => setMongostr(e.target.value)}
                size="small"
                style={{ width: '50%' }}
              />

              <TextField
                id="server-port"
                label="Server Port"
                value={port}
                type="number"
                onChange={(e) => setPort(e.target.value)}
                size="small"
                style={{ width: '50%' }}
              />
              
              <TextField
                id="database-type"
                label="Database Cloud Storage (Required only for image, media hosting)"
                placeholder="Choose between AWS or Firebase"
                value={databaseType}
                onChange={(e) => setDatabaseType(e.target.value)}
                size="small"
                style={{ width: '50%' }}
              />

            </Stack>

          </div>
        );
      default:
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }} style={{ margin: 25 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
              {/* {getStepContent(index)} */}
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            {activeStep === steps.length - 1
              ? (
                <Button
                  onClick={handleFinish}
                  disabled={!isStepFinished(activeStep)}
                  variant="contained"
                >
                  Finish
                </Button>
              )
              : (
                <Button
                  onClick={handleNext}
                  disabled={!isStepFinished(activeStep)}
                >
                  Next
                </Button>
              )}
          </Box>

          <div style={{
            display: 'flex', flexDirection: 'column', height: '20em', marginTop: 25,
          }}
          >
            {getStepContent(activeStep)}
          </div>

        </>
      )}
    </Box>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  currProjectParams: state.modelsReducer,
  projectName: state.modelsReducer.projectName,
  activeProject: state.activeProjectReducer.activeProject,
});
const mapDispatchToProps = (dispatch) => ({
  setActiveProjectData: (params) => dispatch(setActiveProjectData(params)),
  setActiveProject: (project) => dispatch(setActiveProject(project)),
  setProjectName: (name) => dispatch(setProjectName(name)),
  setDatabaseType: (databaseType) => dispatch(setDatabaseType(databaseType)),
  setMongoStr: (str) => dispatch(setMongostr(str)),
  setServerPort: (port) => dispatch(setServerPort(port)),
  resetProject: () => dispatch(resetProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
