export function toPascalCase(string) {
  string = string.trim()
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export function toCamelCase(string) {
  let str = toPascalCase(string)
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const toSnakeCase = (str = '') => {
  const strArr = str.split(' ');
  const snakeArr = strArr.reduce((acc, val) => acc.concat(val.toLowerCase()), []);
  return snakeArr.join('_');
};

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

/*
 * Uses a regular expression to find all occurrences of words surrounded by {{}} in the input string.
 * Returns list of all words surrounded by {{}}, ignoring words after a period
 */
export function findTemplateContextRequired(str) {
  const words = [];
  const regex = /\{\{(\w+)(?:\.\w+)?\}\}/g;
  let match;

  while (match = regex.exec(str)) {
    words.push(match[1]);
  }

  return words;
}