export const ADD_MODEL = 'ADD_MODEL';
export const REMOVE_MODEL = 'REMOVE_MODEL';
export const EDIT_MODEL = 'EDIT_MODEL';
export const ADD_PARAM = 'ADD_PARAM';
export const EDIT_PARAM = 'EDIT_PARAM';
export const REMOVE_PARAM = 'REMOVE_PARAM';
export const TOGGLE_ROUTE_ENABLE = 'TOGGLE_ROUTE_ENABLE';
export const TOGGLE_ROUTE_PROTECTED = 'TOGGLE_ROUTE_PROTECTED';
export const SET_ROUTE_LOGIC = 'SET_ROUTE_LOGIC';
export const TOGGLE_PAGINATION = 'TOGGLE_PAGINATION';
export const SET_ROUTE_MIDDLEWARE = 'SET_ROUTE_MIDDLEWARE';
export const ADD_RELATION = 'ADD_RELATION';
export const REMOVE_RELATION = 'REMOVE_RELATION';
export const ADD_BELONGS_TO = 'ADD_BELONGS_TO';
export const REMOVE_BELONGS_TO = 'REMOVE_BELONGS_TO';
export const TOGGLE_AUTH_OBJECT = 'TOGGLE_AUTH_OBJECT';
export const SET_MANY_TO_MANY = 'SET_MANY_TO_MANY';

/* CONFIG */ 
export const SET_SERVER_PORT = 'SET_SERVER_PORT';
export const SET_MONGOSTR = 'SET_MONGOSTR';
export const SET_DATABASE_TYPE = 'SET_DATABASE_TYPE';

export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_EMAIL = 'SET_EMAIL';

export const SET_ACTIVE_PROJECT_DATA = 'SET_ACTIVE_PROJECT_DATA';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';

export const SET_MODIFIED = 'SET_MODIFIED';

export const SET_WARNINGS = 'SET_WARNINGS';
export const REMOVE_WARNING = 'REMOVE_WARNING';

export const ADD_CONTROLLER = 'ADD_CONTROLLER';
export const EDIT_CONTROLLER = 'EDIT_CONTROLLER';
export const DELETE_CONTROLLER = 'DELETE_CONTROLLER';

export const ADD_BLOCK = 'ADD_BLOCK';
export const DELETE_BLOCK = 'DELETE_BLOCK';
export const EDIT_BLOCK = 'EDIT_BLOCK';
export const MOVE_BLOCK = 'MOVE_BLOCK';
export const SET_CURR_BLOCK_DATA = 'SET_CURR_BLOCK_DATA';
export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE';
export const SAVE_ACTIVE_ROUTE = 'SAVE_ACTIVE_ROUTE';
export const SET_ACTIVE_ROUTE_FROM_ID = 'SET_ACTIVE_ROUTE_FROM_ID';
export const ADD_ROUTE = 'ADD_ROUTE';
export const DELETE_ROUTE = 'DELETE_ROUTE';
export const EDIT_ROUTE = 'EDIT_ROUTE';
export const SAVE_BLOCK = 'SAVE_BLOCK';
export const TOGGLE_MIDDLEWARE = 'TOGGLE_MIDDLEWARE';
export const SET_ROUTE_MIDDLEWARES = "SET_ROUTE_MIDDLEWARES";
export const RESET_ROUTES = 'RESET_ROUTES';

export const SET_MIDDLEWARES = 'SET_MIDDLEWARES';
export const ADD_BLOCK_MIDDLEWARE = 'ADD_BLOCK_MIDDLEWARE';
export const DELETE_BLOCK_MIDDLEWARE = 'DELETE_BLOCK_MIDDLEWARE';
export const EDIT_BLOCK_MIDDLEWARE = 'EDIT_BLOCK_MIDDLEWARE';
export const MOVE_BLOCK_MIDDLEWARE = 'MOVE_BLOCK_MIDDLEWARE';
export const ADD_MIDDLEWARE = "ADD_MIDDLEWARE";
export const DELETE_MIDDLEWARE = "DELETE_MIDDLEWARE";
export const EDIT_MIDDLEWARE = "EDIT_MIDDLEWARE";
export const SET_ACTIVE_MIDDLEWARE_FROM_ID = "SET_ACTIVE_MIDDLEWARE_FROM_ID";
export const SAVE_ACTIVE_MIDDLEWARE = "SAVE_ACTIVE_MIDDLEWARE";
export const TOGGLE_CALLBACK_BLOCK_MIDDLEWARE = "TOGGLE_CALLBACK_BLOCK_MIDDLEWARE"
export const RESET_MIDDLEWARES = 'RESET_MIDDLEWARES';


export const SET_DRAGGING = 'SET_DRAGGING';

export const TOGGLE_OPEN_BLOCK = 'TOGLE_OPEN_BLOCK';
export const TOGGLE_CALLBACK_BLOCK = 'TOGGLE_CALLBACK_BLOCK';

export const TRIGGER_REFRESH = 'TRIGGER_REFRESH';

export const SET_ROUTES = 'SET_ROUTES';
export const SET_CONTROLLERS = 'SET_CONTROLLERS';

export const SET_ACTIVE_MODEL = 'SET_ACTIVE_MODEL';
export const SET_ACTIVE_CONTROLLER = 'SET_ACTIVE_CONTROLLER';
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";

export const TOGGLE_DEV_SIDEBAR = "TOGGLE_DEV_SIDEBAR"
export const SWITCH_DEV_SIDEBAR_DISPLAY = "SWITCH_DEV_SIDEBAR_DISPLAY";

export const TOGGLE_TERMINAL = "TOGGLE_TERMINAL";
export const CLEAR_LINE = "CLEAR_LINE";
export const BACKSPACE = "BACKSPACE";
export const SET_LINE = "SET_LINE";
export const APPEND_COMMAND = "APPEND_COMMAND";
export const SWITCH_COMMAND_INDEX = "SWITCH_COMMAND_INDEX";
export const ADD_TO_CURR_LINE = "ADD_TO_CURR_LINE";

/* Auth Reducer */
export const SELECT_AUTH = "SELECT_AUTH";
export const SET_AUTH_REDUCER = "SET_AUTH_REDUCER";

/* Relations */
export const ADD_MODEL_RELATION = "ADD_MODEL_RELATION";
export const REMOVE_MODEL_RELATION = "REMOVE_MODEL_RELATION";
export const EDIT_MODEL_RELATION = "EDIT_MODEL_RELATION";
export const SET_MODEL_RELATIONS = "SET_MODEL_RELATIONS";

/* Actions */
export const ADD_MAILER = "ADD_MAILER";
export const REMOVE_MAILER = "REMOVE_MAILER";
export const EDIT_MAILER = "EDIT_MAILER";
export const SET_MAILERS = "SET_MAILERS";
