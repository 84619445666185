import React, {useEffect, useState} from 'react';
import {FormHelperText, IconButton, Popover, Typography,} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpIcon from '@mui/icons-material/Help';
import {connect, useSelector} from 'react-redux';
import {useDrag} from 'react-dnd';
import {addModel} from '../../redux/actions/Models';
import {isAlphaNumeric} from '../../services/helpers';
import DeleteBlock from './DeleteBlock';
import QueryBlock from './QueryBlock';
import UpdateBlock from './UpdateBlock';
import CreateBlock from './CreateBlock';
import CustomBlock from './CustomBlock';
import {ItemTypes} from '../../dnd/types';
import {getColor} from './util';
import {saveActiveRoute, setDragging, toggleOpenBlock} from '../../redux/actions/Routes';
import ConditionalBlock from './ConditionalBlock';
import {
  CREATE_HELP,
  CUSTOM_HELP,
  DELETE_HELP,
  ERROR_HELP,
  QUERY_HELP,
  RETURN_HELP,
  UPDATE_HELP,
} from './block_helper_text';
import ErrorBlock from './ErrorBlock';
import ReturnBlock from './ReturnBlock';
import BcryptBlock from './BcryptBlock';
import JwtBlock from './JwtBlock';
import MailerBlock from "./MailerBlock";

function LogicBlock(props) {
    // redux state
    const mailers = useSelector((state) => state.mailersReducer.mailers);
    // local state
    const [id, setId] = useState(props.id);
    const [opened, setOpened] = useState(true); // Is block expanded?
    const [varName, setVarName] = useState('');
    const [model, setModel] = useState('');
    const [params, setParams] = useState('');

    /*
     * HELP MENU FUNCTIONS
     */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClickHelp = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseHelp = () => {
        setAnchorEl(null);
    };
    const openHelp = Boolean(anchorEl);
    const helpId = openHelp ? 'simple-popover' : undefined;

    /*
     * React DnD: Handle Drag block
     */
    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.LOGIC_BLOCK,
        item: {id, index: props.index},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [id]);

    useEffect(() => {
        setId(props.id);
    }, [props.id]);

    useEffect(() => {
        // props.saveActiveRoute();
        props.setDragging(isDragging);
    }, [isDragging]);

    useEffect(() => (() => {
        props.setDragging(false);
    }), []);

    useEffect(() => {
        setOpened(!props.closed.includes(id));
    }, [props.refresh]);

    useEffect(() => {
        if (props.varName) {
            setVarName(props.varName);
        }
        if (props.model) {
            setModel(props.model);
        }
        if (props.params) {
            setParams(props.params);
        }
    }, [props]);

    const getMailerObject = (mailerId) => {
        for (let m of mailers)
            if (m.id === mailerId) return m;
    }

    const getBlockContent = () => {
        if (props.mailerId) {
            let obj = getMailerObject(props.mailerId);
            return obj ? obj.name : "undefined mailer"
        }
        return props.blockVariant
    }

    const handleSetVarName = (e) => {
        if (isAlphaNumeric(e.target.value)) {
            setVarName(e.target.value);
        }
    };

    function BlockSelect({opened}) {
        if (props.mailerId) {
            return (
                <MailerBlock
                    opened={opened}
                    id={props.id}
                    mailerId={props.mailerId}
                    blockData={props.blockData}
                />
            )
        }

        switch (props.blockVariant) {
            case 'delete':
                return (
                    <DeleteBlock
                        opened={opened}
                        id={props.id}
                        showCallback={!props.withCallback.includes(id) || !props.withCallbackMiddleware.includes(id)}
                        blockData={props.blockData}
                    />
                );
            case 'if':
                return (
                    <ConditionalBlock
                        opened={opened}
                        id={props.id}
                        variant="if"
                        blockData={props.blockData}
                    />
                );
            case 'ifelse':
                return (
                    <ConditionalBlock
                        opened={opened}
                        id={props.id}
                        variant="ifelse"
                        blockData={props.blockData}
                    />
                );
            case 'query':
                return (
                    <QueryBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                    />
                );
            case 'update':
                return (
                    <UpdateBlock
                        opened={opened}
                        id={props.id}
                        showCallback={!props.withCallback.includes(id) || !props.withCallbackMiddleware.includes(id)}
                        blockData={props.blockData}
                    />
                );
            case 'create':
                return (
                    <CreateBlock
                        opened={opened}
                        id={props.id}
                        showCallback={!props.withCallback.includes(id) || !props.withCallbackMiddleware.includes(id)}
                        blockData={props.blockData}
                    />
                );
            case 'custom':
                return (
                    <CustomBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                    />
                );
            case 'error':
                return (
                    <ErrorBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                    />
                );
            case 'return':
                return (
                    <ReturnBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                    />
                );
            case 'BCrypt':
                return (
                    <BcryptBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                    />
                );
            case 'JWT':
                return (
                    <JwtBlock
                        opened={opened}
                        id={props.id}
                        blockData={props.blockData}
                        showCallback={!props.withCallback.includes(id) || !props.withCallbackMiddleware.includes(id)}
                    />
                );
            default:
                return <></>;
        }
    }

    return (
        <div
            style={{
                ...styles.container,
                position: isDragging ? 'absolute' : 'static',
                opacity: isDragging ? 0 : 1,
            }}
        >

            {/* Header */}
            <div
                ref={drag}
                style={{
                    ...styles.header,
                    backgroundColor: getColor(props.mailer ? "mailer" : props.blockVariant),
                    borderRadius: opened ? '.5em .5em 0 0' : '.5em',
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'move',
                }}
            >
                {/* TITLE */}
                <Typography variant="caption" display="block" style={{fontWeight: 'bold'}}>
                    {getBlockContent()}
                </Typography>

                <div style={{flex: 1}}/>

                {/* BLOCK ID */}
                <Typography variant="caption" style={{fontSize: '.6em', marginRight: '2em'}}>
                    {props.id}
                </Typography>

                {/* Help Button */}
                <IconButton onClick={handleClickHelp}>
                    <HelpIcon style={{color: 'white', fontSize: '.8em', opacity: 0.5}}/>
                </IconButton>

                {/* Expand Button */}
                <IconButton onClick={() => props.toggleOpenBlock(id)}>
                    {opened
                        ? <ExpandLessIcon style={{color: 'white', fontSize: '.8em'}}/>
                        : <ExpandMoreIcon style={{color: 'white', fontSize: '.8em'}}/>}
                </IconButton>
            </div>

            {/* SHOW BLOCK CONTENT */}
            <BlockSelect opened={opened}/>

            {/* HELPER MENU */}
            <Popover
                id={helpId}
                open={openHelp}
                anchorEl={anchorEl}
                size="small"
                onClose={handleCloseHelp}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{padding: '.75em'}}>
                    <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{fontWeight: 'bold', opacity: 0.6}}
                    >
                        {getHelperText(props.blockVariant).title}
                    </Typography>
                    <FormHelperText>
                        ex.
                    </FormHelperText>
                    <FormHelperText>
                        {getHelperText(props.blockVariant).example}
                    </FormHelperText>
                </div>
            </Popover>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    controllers: state.controllersReducer.controllers,
    closed: state.routesReducer.closed,
    refresh: state.routesReducer.refresh,
    withCallback: state.routesReducer.withCallback,
    withCallbackMiddleware: state.middlewaresReducer.withCallback,
});
const mapDispatchToProps = (dispatch) => ({
    addModel: (name) => dispatch(addModel(name)),
    setDragging: (data) => dispatch(setDragging(data)),
    toggleOpenBlock: (id) => dispatch(toggleOpenBlock(id)),
    saveActiveRoute: () => dispatch(saveActiveRoute()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogicBlock);

const styles = {
    container: {
        borderRadius: '.5em',
        border: '1px solid lightgray',
    },
    header: {
        padding: '.1em .1em .1em 1em',
        borderTopLeftRadius: '.5em',
        borderTopRightRadius: '.5em',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    main: {
        // padding: "1em"
    },
};

/*
 * HELPER FUNCTION TO RETURN HELP INFO
 */
const getHelperText = (variant) => {
    switch (variant) {
        case 'query':
            return QUERY_HELP;
        case 'create':
            return CREATE_HELP;
        case 'update':
            return UPDATE_HELP;
        case 'delete':
            return DELETE_HELP;
        case 'error':
            return ERROR_HELP;
        case 'return':
            return RETURN_HELP;
        case 'custom':
            return CUSTOM_HELP;
        default:
            return 'help not available';
    }
};
