import '../App.css';
import React, {useId, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Menu, MenuItem,} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ControllerModal from '../components/ControllerModal';
import RouteModal from '../components/RouteModal';
import {addMiddleware, deleteMiddleware} from '../redux/actions/Middlewares';
import MiddlewareDisplay from './Routes/MiddlewareDisplay';
import {getRandomInt} from '../services/helpers';

function MiddlewaresPage(props) {
    const id = useId();
    const cbxWid = 95;
    const [openController, setOpenController] = useState(false);
    const [openRoute, setOpenRoute] = useState(false);
    const handleOpenController = () => setOpenController(true);
    const handleCloseController = () => setOpenController(false);
    const handleOpenRoute = () => setOpenRoute(true);
    const handleCloseRoute = () => setOpenRoute(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedController, setSelectedController] = useState(null);
    const openMore = Boolean(anchorEl);
    const handleClickMore = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedController(id);
    };
    const handleCloseMore = () => {
        setAnchorEl(null);
        setSelectedController(null);
    };

    function deleteController() {
        props.deleteController(selectedController);
        handleCloseMore();
    }

    function getUntitledName() {
        let num = '';
        let testStr = 'untitledMiddleware';

        while (props.middlewares.some(m => (m.handler === testStr + num))) {
            if (num === '') {
                num = 2;
            } else {
                num += 1;
            }
        }
        ;

        return num === 1 ? testStr : testStr + num;
    }

    function createNewMiddleware() {
        const newId = id + getRandomInt(10000);

        const newMiddleware = {
            id: newId,
            logic: [],
            handler: getUntitledName(),
        };

        props.addMiddleware(newMiddleware);
    }

    return (
        <div className="flex flex-col items-center w-full mt-8 pb-32">

            <div className='w-full flex flex-col'>

                {/* HEADER */}
                <div className="row flex-1 mr-8 ml-8">
                    <h1>Middlewares</h1>
                    <div style={{flex: 1}}/>

                    <Button
                        style={{marginRight: '2em', width: '12em'}}
                        size="small"
                        variant="contained"
                        onClick={createNewMiddleware}
                    >
                        New Middleware
                    </Button>

                    <a href="https://www.neutrinojs.dev/docs#routes" target="_blank" rel="noreferrer" className='ml-5'>
                        <InfoIcon style={{color: 'lightgray'}}/>
                    </a>
                </div>
            </div>

            <div>
                <ControllerModal
                    open={openController}
                    handleClose={handleCloseController}
                />
                <RouteModal
                    open={openRoute}
                    handleClose={handleCloseRoute}
                />
            </div>

            {/* SHOW MIDDLEWARES */}
            <div className='w-full mt-4'>
                {props.middlewares.map((middleware, index) => (
                    <MiddlewareDisplay
                        key={middleware.id}
                        middleware={middleware}
                    />
                ))}
            </div>

            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={openMore}
                onClose={handleCloseMore}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={deleteController}>delete middleware</MenuItem>
            </Menu>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    middlewares: state.middlewaresReducer.middlewares,
});
const mapDispatchToProps = (dispatch) => ({
    addMiddleware: (name) => dispatch(addMiddleware(name)),
    editMiddleware: (key, data) => dispatch(addMiddleware(key, data)),
    deleteMiddleware: (key) => dispatch(deleteMiddleware(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddlewaresPage);

const styles = {
    controllerMain: {
        border: '1px solid lightgray',
        padding: '.5em',
        borderRadius: '.5em',
    },
};
