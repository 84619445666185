import React, {useContext, useEffect, useId, useState} from 'react';
import {Button, Chip, FormControl, Stack, Typography,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {connect} from 'react-redux';
import {useDrop} from 'react-dnd';
import {addBlock, moveBlock, triggerRefresh} from '../../redux/actions/Routes';
import {ItemTypes} from '../../dnd/types';
import {getRandomInt} from '../../services/helpers';
import {EditorContext} from './EditorContext';
import {addBlockMiddleware, moveBlockMiddleware} from '../../redux/actions/Middlewares';

/*
 * variant: 'success', or 'error' (for callbacks)
 */
function NewBlock({
                      models,
                      separator,
                      addBlock,
                      moveBlock,
                      addBlockMiddleware,
                      moveBlockMiddleware,
                      index,
                      parentId,
                      variant,
                      triggerRefresh,
                  }) {
    const newId = useId();
    const editorVariant = useContext(EditorContext);  // middleware or route editor
    const [scale, setScale] = useState(1);
    const [opened, setOpened] = useState(false);
    const [blockType, setBlockType] = useState('query');
    const [showSeparator, setShowSeparator] = useState(false);

    const getNewBlockData = (id, blockVariant, mailer=null) => {
        const data = {
            id,
            blockVariant,
            model: models.length > 0 ? models[0].name : '',
            success: [],
            error: [],
            mailerId: mailer
        };

        return data;
    };

    /*
     * Drag and drop handler - Drop
     */
    const [{isOver}, drop] = useDrop(
        () => ({
            accept: [ItemTypes.LOGIC_BLOCK, ItemTypes.SIDEBAR_LOGIC_BLOCK],
            drop: (monitor) => {
                if (monitor.itemType === ItemTypes.SIDEBAR_LOGIC_BLOCK) {
                    triggerRefresh();
                    if (editorVariant === 'route') {
                        addBlock(
                            index + 1,
                            getNewBlockData(newId + getRandomInt(1000000), monitor.blockVariant, monitor.mailerId),
                            parentId,
                            variant,
                        );
                    } else if (editorVariant === 'middleware') {
                        addBlockMiddleware(
                            index + 1,
                            getNewBlockData(newId + getRandomInt(1000000), monitor.blockVariant, monitor.mailerId),
                            parentId,
                            variant,
                        );
                    } else {
                        console.error('Invalid editor variant:', editorVariant)
                    }
                } else {
                    // moveBlock(monitor.id, monitor.index, index);
                    // triggerRefresh()
                    if (editorVariant === 'route') {
                        moveBlock(monitor.id, index + 1, parentId, variant);
                    } else if (editorVariant === 'middleware') {
                        moveBlockMiddleware(monitor.id, index + 1, parentId, variant);
                    }
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [],
    );

    const handleCloseSelect = () => {
        setOpened(false);
        setShowSeparator(false);
    };

    /*
     * Submit block, creates a new block in its place
     */
    const handleSubmit = (variant) => {
        triggerRefresh();
        if (editorVariant === 'route') {
            addBlock(
                index + 1,
                getNewBlockData(newId + getRandomInt(1000000), blockType),
                parentId,
                variant,
            );
        } else if (editorVariant === 'middleware') {
            addBlockMiddleware(
                index + 1,
                getNewBlockData(newId + getRandomInt(1000000), blockType),
                parentId,
                variant,
            );
        }
        setOpened(false);
        setShowSeparator(false);
    };

    const AddBlockButton = (
        <div
            style={{...styles.mainUnopened, transform: `scale(${scale})`, marginTop: separator ? '0' : '1em'}}
            onMouseEnter={() => setScale(1.005)}
            onMouseLeave={() => setScale(1)}
            onClick={() => setOpened(true)}
        >
            <AddIcon style={{color: 'gray', fontSize: '1em'}}/>
        </div>
    );

    useEffect(() => {
        setShowSeparator(isOver);
    }, [isOver]);

    if (separator && !opened) {
        return (
            <div
                onMouseEnter={() => setShowSeparator(true)}
                onMouseLeave={() => setShowSeparator(false)}
                style={{
                    minHeight: '.5em',
                    cursor: 'pointer',
                    marginTop: '.5em',
                }}
                ref={drop}
            >
                {showSeparator
                    ? AddBlockButton
                    : <div/>}
            </div>
        );
    }

    if (!opened) {
        return (
            <div
                ref={drop}
                style={{transform: isOver ? 'scale(1.005)' : 'scale(1)'}}
            >
                {AddBlockButton}
            </div>
        );
    }

    return (
        <div
            style={{...styles.mainOpened, marginTop: '1em'}}
        >
            <Stack spacing={2} style={{width: '100%'}}>

                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        return blocks
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="throw error"
                            size="small"
                            onClick={() => setBlockType('error')}
                            color={blockType === 'error' ? 'primary' : 'default'}
                        />
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="return data"
                            size="small"
                            onClick={() => setBlockType('return')}
                            color={blockType === 'return' ? 'primary' : 'default'}
                        />
                    </Stack>
                </FormControl>

                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        database actions
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="query"
                            size="small"
                            onClick={() => setBlockType('query')}
                            color={blockType === 'query' ? 'primary' : 'default'}
                        />
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="create"
                            size="small"
                            onClick={() => setBlockType('create')}
                            color={blockType === 'create' ? 'primary' : 'default'}
                        />
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="update"
                            size="small"
                            onClick={() => setBlockType('update')}
                            color={blockType === 'update' ? 'primary' : 'default'}
                        />
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="delete"
                            size="small"
                            onClick={() => setBlockType('delete')}
                            color={blockType === 'delete' ? 'primary' : 'default'}
                        />
                    </Stack>
                </FormControl>

                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        conditionals
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="if"
                            size="small"
                            onClick={() => setBlockType('if')}
                            color={blockType === 'if' ? 'primary' : 'default'}
                        />
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="if else"
                            size="small"
                            onClick={() => setBlockType('ifelse')}
                            color={blockType === 'ifelse' ? 'primary' : 'default'}
                        />
                    </Stack>
                </FormControl>

                <FormControl sx={{width: '100%'}} size="small">
                    <Typography variant="caption" display="block" gutterBottom
                                style={{fontWeight: 'bold', opacity: 0.6}}>
                        custom
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            style={{padding: '0 0.3em 0 0.3em'}}
                            label="custom"
                            size="small"
                            onClick={() => setBlockType('custom')}
                            color={blockType === 'custom' ? 'primary' : 'default'}
                        />
                    </Stack>
                </FormControl>

                {/* BUTTONS */}
                <Stack direction="row" spacing={2} style={{width: '100%'}}>
                    <div style={{flex: 1}}/>
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        style={{width: '6em'}}
                        onClick={handleCloseSelect}
                    >
                        cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        style={{width: '6em'}}
                        onClick={() => handleSubmit(variant)}
                    >
                        done
                    </Button>
                </Stack>
            </Stack>
        </div>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    controllers: state.controllersReducer.controllers,
});
const mapDispatchToProps = (dispatch) => ({
    addBlock: (index, block, parentId, variant) => dispatch(addBlock(index, block, parentId, variant)),
    moveBlock: (blockId, index, parentId, variant) => dispatch(moveBlock(blockId, index, parentId, variant)),
    addBlockMiddleware: (index, block, parentId, variant) => dispatch(addBlockMiddleware(index, block, parentId, variant)),
    moveBlockMiddleware: (blockId, index, parentId, variant) => dispatch(moveBlockMiddleware(blockId, index, parentId, variant)),
    triggerRefresh: () => dispatch(triggerRefresh()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewBlock);

const styles = {
    mainUnopened: {
        border: '1px dashed lightgray',
        borderRadius: '.5em',
        padding: '.5em',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transform: 'scale(1.01)',
    },
    mainOpened: {
        border: '1px solid lightgray',
        borderRadius: '.5em',
        padding: '1em',
        display: 'flex',
        alignItems: 'center',
        margin: '1em 0 1em 0',
    },
};
