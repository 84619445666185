import {
  ADD_CONTROLLER,
  EDIT_CONTROLLER,
  DELETE_CONTROLLER,
  SET_CONTROLLERS,
} from '../Types';

const initialState = {
  controllers: [],
};

const ControllersReducer = (state = initialState, action) => {
  let newControllers = JSON.parse(JSON.stringify(state.controllers));

  switch (action.type) {
    case SET_CONTROLLERS:
      return {
        ...state,
        controllers: action.data,
      };

    case ADD_CONTROLLER:
      return {
        ...state,
        controllers: state.controllers.concat({
          name: action.name,
          affiliation: action.affiliation,
          id: action.id,
        }),
      };

    case EDIT_CONTROLLER:
      newControllers.forEach(function (controller, index) {
        if (controller.id === action.id) {
          this[index] = action.data;
        }
      }, newControllers);

      return {
        ...state,
        controllers: newControllers,
      };

    case DELETE_CONTROLLER:
      newControllers = newControllers.filter((c) => (c.id !== action.key));
      return {
        ...state,
        controllers: newControllers,
      };

    default:
      return state;
  }
};

export default ControllersReducer;
