import React from 'react';
import { connect } from 'react-redux';
import { TextField, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { setMongostr, setServerPort } from '../redux/actions/Models';
import {useDispatch, useSelector} from "react-redux";

function Config(props) {

  const mediaHandlerConfig = useSelector((state) => state.configReducer.mediaHanderConfig);

  function handleSave() {
    props.setServerPort(8081);
  }

  return (
    <div className='flex flex-col items-center mt-8 pb-32'>

      <div className='w-full flex'>

        {/* HEADING */}
        <div className="row flex flex-1 mr-8 ml-8">
          <h1>Config</h1>
          <div className='flex-1'/>
          <a href="https://www.neutrinojs.dev/docs#database" target="_blank" rel="noreferrer">
            <InfoIcon style={{ color: 'lightgray' }} />
          </a>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>

        <Stack spacing={2}>

          <TextField
            label="MongoDB Atlas Connect String"
            variant="outlined"
            size="small"
            fullWidth
            value={props.mongostr}
            onChange={(e) => props.setMongostr(e.target.value)}
          />

          <TextField
            label="Server Port"
            placeholder="8080"
            variant="outlined"
            size="small"
            type="number"
            value={props.serverPort}
            onChange={(e) => props.setServerPort(e.target.value)}
          />

          
          <TextField
            label="Database Cloud Storage"
            placeholder="Choose between AWS or Firebase"
            variant="outlined"
            size="small"
            fullWidth
            value={props.databaseType}
            onChange={(e) => props.setDatabaseType(e.target.value)}
          />

        </Stack>
      </div>
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  serverPort: state.modelsReducer.serverPort,
  mongostr: state.modelsReducer.mongostr,
  databaseType: state.modelsReducer.databaseType,
});
const mapDispatchToProps = (dispatch) => ({
  setServerPort: (data) => dispatch(setServerPort(data)),
  setMongostr: (data) => dispatch(setMongostr(data)),
  setDatabaseType: (data) => dispatch(setDatabaseType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Config);
