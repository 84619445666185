import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import { BUILDER_BACKEND_URL } from '../constants/constants';

export default function useAuth() {
  const navigate = useNavigate();
  const { setAuthUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const setUserContext = () => {
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      setAuthUser(auth.user);
      navigate('../');
    }
  };

  const login = (username, password) => {
    setLoading(true);
    setError(null);
    axios.post(`${BUILDER_BACKEND_URL}/login`, {
      username,
      password,
    })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('auth', JSON.stringify(res.data));
          setUserContext();
        }
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };

  // logout
  const logout = () => {
    localStorage.removeItem('auth');
    setAuthUser(null);
    navigate('/');
  };

  // register user
  const registerUser = async (data) => {
    const {
      name, address, username, email, password,
    } = data;
    return axios.post(`${BUILDER_BACKEND_URL}/register`, {
      name, address, username, email, password,
    })
      .then(() => {
        setUserContext();
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return {
    login,
    logout,
    registerUser,
    loading,
    error,
  };
};