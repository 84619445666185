import React from 'react';

export default function NotFound() {
  return (
    <h1 style={{ marginTop: 25 }}>
      {'< '}
      {' '}
      NotFound
      {' '}
      {'/>'}
    </h1>
  );
}
