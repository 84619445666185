import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import {
  CogIcon,
  HomeIcon,
  XIcon,
  DatabaseIcon,
  ServerIcon,
  DownloadIcon,
  CodeIcon,
  ChipIcon,
  LockClosedIcon,
  LightningBoltIcon,
  MailIcon,
} from '@heroicons/react/outline';
import logo from '../assets/neutrinologowhite.svg'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const sidebarNavigation = [
  {
    name: 'Database', href: '/models', icon: DatabaseIcon, current: false,
  },
  {
    name: 'Routes', href: '/controllers', icon: ServerIcon, current: false,
  },
  {
    name: 'Mailers', href: '/mailers', icon: MailIcon, current: false,
  },
  {
    name: 'Middlewares', href: '/middlewares', icon: ChipIcon, current: false,
  },
  {
    name: 'Auth', href: '/auth', icon: LockClosedIcon, current: false,
  },
  {
    name: 'Preview', href: '/preview', icon: CodeIcon, current: false,
  },
  {
    name: 'Config', href: '/config', icon: CogIcon, current: false,
  },
  {
    name: 'Export', href: '/export', icon: DownloadIcon, current: false,
  },
];

export default function SidebarNav({ 
  variant, 
  displayPage, 
  handleChange, 
  mobileMenuOpen, 
  setMobileMenuOpen 
}) {
  const navigate = useNavigate();

  const isActive = (page) => {
    return (displayPage === page);
  }

  const handleNavigate = (page, link) => {
    handleChange(page);
    navigate(link);
  }

  if (variant === 'mobile') {
    return (
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20 md:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative max-w-xs w-full bg-darkbg pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div
                    className="flex-shrink-0 px-4 flex items-center cursor-pointer"
                    onClick={() => handleNavigate('Home', '/')}
                >
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <div
                          key={item.name}
                          style={{cursor: 'pointer'}}
                          className={classNames(
                            isActive(item.name)
                              ? 'bg-offdarkbg text-white'
                              : 'text-gray-300 hover:bg-mainblue hover:text-white',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onClick={() => handleNavigate(item.name, item.href)}
                        >
                          <item.icon
                            className={classNames(
                              isActive(item.name) ? 'text-white' : 'text-gray-300 group-hover:text-white',
                              'mr-3 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </div>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="hidden w-28 bg-darkbg overflow-y-auto md:block">
      <div className="w-full py-6 flex flex-col items-center">
        <div
            className="flex-shrink-0 flex items-center cursor-pointer"
            onClick={() => handleNavigate('Home', '/')}
        >
          <img
            className="h-8 w-auto"
            src={logo}
            alt="Workflow"
          />
        </div>
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {sidebarNavigation.map((item) => (
            <div
              key={item.name}
              style={{cursor: 'pointer'}}
              className={classNames(
                isActive(item.name) ? 'bg-offdarkbg text-white' : 'text-gray-300 hover:bg-mainblue hover:text-white',
                'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium',
              )}
              aria-current={item.current ? 'page' : undefined}
              onClick={() => handleNavigate(item.name, item.href)}
            >
              <item.icon
                className={classNames(
                  isActive(item.name) ? 'text-white' : 'text-gray-300 group-hover:text-white',
                  'h-6 w-6',
                )}
                aria-hidden="true"
              />
              <span className="mt-2">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};