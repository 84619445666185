import { createSlice } from '@reduxjs/toolkit'

export const mailersSlice = createSlice({
    name: 'mailers',
    initialState: {
        mailers: [],
    },
    reducers: {
        addMailer: (state, action) => {
            state.mailers.push(action.payload)
        },
        removeMailer: (state, action) => {
            state.mailers = state.mailers.filter((m) => m.id !== action.payload)
        },
        editMailer: (state, action) => {
            for (let i = 0; i < state.mailers.length; i++) {
                if (state.mailers[i].id === action.payload.key) {
                    state.mailers[i] = { ...state.mailers[i], ...action.payload.data };
                }
            }
        },
        addTemplate: (state, action) => {
            for (let i = 0; i < state.mailers.length; i++) {
                if (state.mailers[i].id === action.payload.key) {
                    state.mailers[i].templates.push(action.payload.data)
                }
            }
        },
        removeTemplate: (state, action) => {
            for (let i = 0; i < state.mailers.length; i++) {
                if (state.mailers[i].id === action.payload.mailerId) {
                    state.mailers[i].templates = state.mailers[i].templates.filter((t) => t.id !== action.payload.templateId)
                }
            }
        },
        editTemplate: (state, action) => {
            for (let i = 0; i < state.mailers.length; i++) {
                if (state.mailers[i].id === action.payload.mailerId) {
                    for (let j = 0; j < state.mailers[i].templates.length; j++) {
                        if (state.mailers[i].templates[j].id === action.payload.templateId) {
                            state.mailers[i].templates[j] = {...state.mailers[i].templates[j], ...action.payload.templateData}
                        }
                    }
                }
            }
        },
        setMailers: (state, action) => {
            state.mailers = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { addMailer, removeMailer, editMailer, addTemplate, removeTemplate, editTemplate, setMailers } = mailersSlice.actions

export default mailersSlice.reducer