import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { deleteBlock } from '../../redux/actions/Routes';
import { ItemTypes } from '../../dnd/types';
import { deleteBlockMiddleware } from '../../redux/actions/Middlewares';

function TrashIcon(props) {
  /*
   * Drag and drop handler - Drop
   */
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [ItemTypes.LOGIC_BLOCK],
      drop: (monitor) => {
        if (props.editorVariant === 'middlewares') {
          props.deleteBlockMiddleware(monitor.id);
        } else {
          props.deleteBlock(monitor.id);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [],
  );

  return (
    <div
      ref={drop}
      style={{ backgroundColor: '#ff000075', borderRadius: 100 }}
    >
      <IconButton style={{ opacity: 1 }}>
        <DeleteIcon sx={{ fontSize: isOver ? '2.1em' : '2em' }} style={{ color: 'white' }} />
      </IconButton>
    </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  isDragging: state.routesReducer.isDragging,
});
const mapDispatchToProps = (dispatch) => ({
  deleteBlock: (id) => dispatch(deleteBlock(id)),
  deleteBlockMiddleware: (id) => dispatch(deleteBlockMiddleware(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrashIcon);
