import './App.css';
import React, {useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import ResizeableSidebar from './components/ResizeableSidebar';
import SidebarNav from './layout/SidebarNav';
import TopNav from './layout/TopNav';
import Models from './pages/Models';
/* Main */
import Config from './pages/Config';
import Home from './pages/Home';
import Export from './pages/Export';
import NewProject from './pages/NewProject';
import Preview from './pages/Preview';
import NotFound from './pages/NotFound';
import Controllers from './pages/Controllers';
import RouteEdit from './pages/Routes/RouteEdit';
import FolderDisplay from './components/FolderDisplay';
import RouteMarketplace from './components/RouteMarketplace';
import ModelSidebar from './sidebarPages.js/ModelSidebar';
import ControllerSidebar from './sidebarPages.js/ControllerSidebar';
import MiddlewaresMain from './pages/MiddlewaresMain';
import XtermTerminal from './terminal/Terminal';

import {BeakerIcon, TerminalIcon, ViewBoardsIcon} from '@heroicons/react/outline';
import {toggleTerminal} from './redux/actions/Terminal';
import {connect} from 'react-redux';
import {setSidebarOpen, switchDevSidebarDisplay, toggleDevSidebar} from './redux/actions/Sidebar';
import MiddlewareEdit from './pages/Routes/MiddlewareEdit';
import {ENV} from './constants/constants'
import ProfileMain from './pages/Profile/ProfileMain';
import AuthPage from './pages/Auth';
import Mailers from "./pages/Mailers";
import {getPreppedProjectData} from "./util/initializeProject";

const withSidebar = [
    {
        path: '/models',
        optional: true,
    },
    {
        path: '/controllers',
        optional: true,
    },
    {
        path: '/preview',
        optional: false,
    },
    {
        path: '/route-editor',
        optional: false,
    },
    {
        path: '/middleware-editor',
        optional: false,
    },
];

const PrettyPrintJson = ({data}) => (<div style={{fontSize: ".75em"}}>
    <pre>{JSON.stringify(data, null, 2)}</pre>
</div>);

function Main(props) {
    const location = useLocation();
    const [displayPage, setDisplayPage] = useState('Home');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const changeDisplay = (page) => {
        setDisplayPage(page);
    }

    const isOptional = () => {
        let curr = withSidebar.find(p => location.pathname.includes(p.path));
        if (curr) {
            return curr.optional;
        }
    };

    const getDevSidebarData = () => {
        switch (props.devSidebarDisplay) {
            case 'models':
                return props.models;
            case 'controllers':
                return props.controllers;
            case 'routes':
                return props.routes;
            case 'middlewares':
                return props.middlewares;
            case 'relations':
                return props.relations;
            case 'mailers':
                return props.mailers;
            case 'config':
                return props.config;
            case 'builder-output':
                return getPreppedProjectData(true);
            default:
                break;
        }
    }

    return (
        <>
            <div className="h-full flex">
                {/* Narrow sidebar */}
                <SidebarNav
                    displayPage={displayPage}
                    handleChange={changeDisplay}
                />

                {/* Mobile menu */}
                <SidebarNav
                    variant={'mobile'}
                    displayPage={displayPage}
                    handleChange={changeDisplay}
                    mobileMenuOpen={mobileMenuOpen}
                    setMobileMenuOpen={(val) => setMobileMenuOpen(val)}
                />

                {/* Content area */}
                <div className="flex-1 flex flex-col overflow-hidden">

                    {/* Top Navigation */}
                    <TopNav
                        setMobileMenuOpen={(val) => setMobileMenuOpen(val)}
                    />

                    {/* Main content */}
                    <div className="flex-1 flex items-stretch overflow-hidden">
                        <main
                            className="justify-center flex-1 overflow-y-auto"
                        >

                            {/* Primary column */}
                            <section aria-labelledby="primary-heading"
                                     className="min-w-0 flex-1 h-full flex flex-col lg:order-last">
                                <Routes>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="/users/:id" element={<ProfileMain/>}/>
                                    <Route path="/models" element={<Models/>}/>
                                    <Route path="/route-editor" element={<RouteEdit/>}/>
                                    <Route path="/middleware-editor" element={<MiddlewareEdit/>}/>
                                    <Route path="/controllers" element={<Controllers/>}/>
                                    <Route path="/middlewares" element={<MiddlewaresMain/>}/>
                                    <Route path="/mailers" element={<Mailers/>}/>
                                    <Route path="/auth" element={<AuthPage/>}/>
                                    <Route path="/config" element={<Config/>}/>
                                    <Route path="/preview" element={<Preview/>}/>
                                    <Route path="/export" element={<Export/>}/>
                                    <Route path="/new" element={<NewProject/>}/>
                                    <Route path="*" element={<NotFound/>}/>
                                </Routes>
                            </section>
                        </main>

                        {/* Secondary column (hidden on smaller screens) */}
                        {(
                                props.sidebarOpen ||
                                !isOptional()
                            ) && withSidebar.some(p => location.pathname.includes(p.path)) &&
                            <ResizeableSidebar>
                                <div className='mt-5'>
                                    <Routes>
                                        <Route path="/" element={<Home/>}/>
                                        <Route path="/models" element={<ModelSidebar/>}/>
                                        <Route path="/route-editor" element={<RouteMarketplace/>}/>
                                        <Route path="/middleware-editor"
                                               element={<RouteMarketplace editorVariant={"middleware"}/>}/>
                                        <Route path="/controllers" element={<ControllerSidebar/>}/>
                                        <Route path="/config" element={<Config/>}/>
                                        <Route path="/preview" element={<FolderDisplay/>}/>
                                        <Route path="/export" element={<Export/>}/>
                                        <Route path="/new" element={<NewProject/>}/>
                                        <Route path="*" element={<NotFound/>}/>
                                    </Routes>
                                </div>
                            </ResizeableSidebar>
                        }

                        {props.terminalOpen &&
                            <ResizeableSidebar
                                style={{backgroundColor: '#000f18'}}
                                initWidth={'30em'}
                            >
                                <XtermTerminal/>
                            </ResizeableSidebar>
                        }

                        {/* Dev Sidebar */}
                        {props.devSidebarOpen && ENV === 'dev' &&
                            <ResizeableSidebar>
                                <select
                                    className='h-7 p-0 pl-2 w-2/3'
                                    value={props.devSidebarDisplay}
                                    onChange={e => props.switchDevSidebarDisplay(e.target.value)}
                                >
                                    <option value="models">Models</option>
                                    <option value="controllers">Controllers</option>
                                    <option value="routes">Routes</option>
                                    <option value="middlewares">Middlewares</option>
                                    <option value="relations">Relations</option>
                                    <option value="mailers">Mailers</option>
                                    <option value="config">Config</option>
                                    <option value="builder-output">Builder Output</option>
                                </select>
                                <PrettyPrintJson data={getDevSidebarData()}/>
                            </ResizeableSidebar>
                        }

                    </div>

                    <div className='hidden lg:block'>
                        <div
                            className='sm:hidden md:hidden lg:hidden bg-maingreen p-1 flex justify-end'
                            style={{display: "flex", justifyContent: 'flex-end'}}
                        >
                            <div className='flex-1'/>

                            {/* Dev Sidebar Button */}
                            {ENV === 'dev' &&
                                <BeakerIcon
                                    onClick={() => props.toggleDevSidebar()}
                                    className='text-white group-hover:text-white mr-3 h-4 w-4 cursor-pointer'
                                />
                            }

                            <ViewBoardsIcon
                                onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
                                className='text-white group-hover:text-white mr-3 h-4 w-4 cursor-pointer'
                            />
                            <TerminalIcon
                                onClick={() => props.toggleTerminal()}
                                className='text-white group-hover:text-white mr-3 h-4 w-4 cursor-pointer'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

/* Redux */
const mapStateToProps = (state) => ({
    models: state.modelsReducer,
    controllers: state.controllersReducer,
    routes: state.routesReducer,
    middlewares: state.middlewaresReducer,
    relations: state.relationsReducer,
    config: state.configReducer,
    mailers: state.mailersReducer,
    auth: state.authReducer,
    terminalOpen: state.terminalReducer.terminalOpen,
    sidebarOpen: state.sidebarReducer.sidebarOpen,
    devSidebarOpen: state.sidebarReducer.devSidebarOpen,
    devSidebarDisplay: state.sidebarReducer.devSidebarDisplay,
});
const mapDispatchToProps = (dispatch) => ({
    toggleTerminal: () => dispatch(toggleTerminal()),
    setSidebarOpen: (b) => dispatch(setSidebarOpen(b)),
    toggleDevSidebar: () => dispatch(toggleDevSidebar()),
    switchDevSidebarDisplay: (data) => dispatch(switchDevSidebarDisplay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
