import React, { useState } from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BUILDER_BACKEND_URL } from '../constants/constants';
import './AuthStyles.css';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    setLoading(true);
    setError(null);
    axios.put(`${BUILDER_BACKEND_URL}/forgotpassword`, {
      email,
    })
      .then((res) => {
        setLoading(false);
        setResponse(res.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  };

  return (
    <div className="root">
      <div style={{ flex: 1 }} />
      <div className="auth-container w-11/12 lg:w-2/5">
        
        <Stack spacing={3} style={{ width: '100%' }}>
          <h1 className="text-center">Forgot Password</h1>
          <div className='text-center'>enter your email to reset your password</div>
          {error
            && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
          {response
            && <div style={{ color: 'blue', textAlign: 'center' }}>{response}</div>}
          <TextField
            label="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
          />

          {/* Submit */}
          <Button
            variant="contained"
            onClick={handleForgotPassword}
          >
            {' '}
            {loading ? 'Loading...' : 'Forgot Password'}

          </Button>
        </Stack>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}
