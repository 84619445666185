import React from 'react';

export default function TextInput({ onChange, placeholder, value, type }) {
  return (
    <input
      type={type === 'number' ? 'number' : 'text'}
      className="focus:ring-indigo-500 focus:border-indigo-500 w-full h-7 sm:text-sm border-gray-300"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}