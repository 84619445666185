import { useEffect, useState } from 'react';
import {
  CodeIcon,
  CogIcon,
  UserIcon,
  LockClosedIcon,
  CurrencyDollarIcon
} from '@heroicons/react/outline'
import { ChevronLeftIcon } from '@heroicons/react/solid';
import useQuery from '../../hooks/useQuery';
import ProfileDisplay from './ProfileDisplay';
import { useNavigate } from 'react-router-dom';
import ProfileSecurity from './ProfileSecurity';
import ProjectsDisplay from './ProjectsDisplay';

const subNavigation = [
  {
    name: 'Projects',
    description: 'Access saved projects',
    href: '#',
    icon: CodeIcon,
    disabled: false,
  },
  {
    name: 'Account',
    description: 'Update your profile information',
    href: '#',
    icon: UserIcon,
    disabled: false,
  },
  {
    name: 'Security',
    description: 'Update your password and security settings',
    href: '#',
    icon: LockClosedIcon,
    disabled: false,
  },
  {
    name: 'Billing',
    description: 'Manage your payment methods',
    href: '#',
    icon: CurrencyDollarIcon,
    disabled: true,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProfileMain() {
  const navigate = useNavigate();
  const query = useQuery()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [pageDisplay, setPageDisplay] = useState(query.get('page') ? query.get('page') : 'Projects');

  const getMainContent = () => {
    switch(pageDisplay) {
      case 'Projects':
        return <ProjectsDisplay />
      case 'Account':
        return <ProfileDisplay />
      case 'Security':
        return <ProfileSecurity />
      // case 'Billing':
      //   return <ProfileDisplay />
      default:
      break;
    };
  };

  useEffect(() => {
    setPageDisplay(query.get('page') ? query.get('page') : 'Projects');
  }, [query])

  return (
    <>
      <div className="flex h-full">
        
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

          <main className="flex flex-1 overflow-hidden">
            <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
              {/* Breadcrumb */}
              <nav aria-label="Breadcrumb" className="border-b border-blue-gray-200 bg-white xl:hidden">
                <div className="mx-auto flex max-w-3xl items-start py-3 px-4 sm:px-6 lg:px-8">
                  <a
                    href="#"
                    className="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-blue-gray-900"
                  >
                    <ChevronLeftIcon className="h-5 w-5 text-blue-gray-400" aria-hidden="true" />
                    <span>Settings</span>
                  </a>
                </div>
              </nav>

              <div className="flex flex-1 xl:overflow-hidden">
                {/* Secondary sidebar */}
                <nav
                  aria-label="Sections"
                  className="hidden w-96 flex-shrink-0 border-r border-blue-gray-200 bg-white xl:flex xl:flex-col"
                >
                  <div className="flex h-16 flex-shrink-0 items-center border-b border-blue-gray-200 px-6">
                    <p className="text-lg font-medium text-blue-gray-900">Settings</p>
                  </div>
                  <div className="min-h-0 flex-1 overflow-y-auto">
                    {subNavigation.map((item) => (
                      <a
                        key={item.name}
                        onClick={!item.disabled ? () => navigate(`?page=${item.name}`) : null}
                        className={classNames(
                          query.get('page') === item.name ? 'bg-blue-50 bg-opacity-50' : '',
                          item.disabled ? 'cursor-default opacity-50' : 'cursor-pointer hover:bg-blue-50 hover:bg-opacity-50',
                          'flex p-6 border-b border-blue-gray-200 cursor-pointer'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400" aria-hidden="true" />
                        <div className="ml-3 text-sm">
                          <p className="font-medium text-blue-gray-900">{item.name}</p>
                          <p className="mt-1 text-blue-gray-500">{item.description}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </nav>

                {/* Main content */}
                <div className="flex-1 xl:overflow-y-auto">
                  {getMainContent()}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
