import {
  ADD_TO_CURR_LINE,
  APPEND_COMMAND,
  BACKSPACE,
  CLEAR_LINE,
  SET_LINE,
  SWITCH_COMMAND_INDEX,
  TOGGLE_TERMINAL
} from '../Types';

export const toggleTerminal = () => ({
  type: TOGGLE_TERMINAL,
});

export const addToCurrLine = (data) => ({
  type: ADD_TO_CURR_LINE,
  data
});

export const setLine = (data) => ({
  type: SET_LINE,
  data
});

export const clearLine = () => ({
  type: CLEAR_LINE,
});

export const backspace = () => ({
  type: BACKSPACE,
});

export const appendCommand = () => ({
  type: APPEND_COMMAND,
});

export const switchCommandIndex = (data) => ({
  type: SWITCH_COMMAND_INDEX,
  data
});