import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useApi from '../../hooks/useApi';
import authHeader from '../../services/auth-header';
import '../../App.css';
import { UserContext } from '../../hooks/UserContext';
import { BUILDER_BACKEND_URL } from '../../constants/constants';
import { isAlphaNumeric, validateEmail } from '../../services/helpers';
import {
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import MuiAlert from '@mui/material/Alert';
import {
  Snackbar,
} from '@mui/material';
import { toPascalCase } from '../../util/caseMatching';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ProfileDisplay() {
  const { setAuthUser } = useContext(UserContext);
  const { id } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const {
    result: user, loading, error, refresh,
  } = useApi(`${BUILDER_BACKEND_URL}/users/${id}`);
  const navigate = useNavigate();
  const [openErr, setOpenErr] = useState(false);
  const [openSucc, setOpenSucc] = useState(false);

  const handleClose = (event, reason, variant='success') => {
    if (reason === 'clickaway') {
      return;
    }
    if (variant === 'error')
      setOpenErr(false);
    else 
      setOpenSucc(false);
  };

  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (user) {
      if (user.first) setFirst(user.first);
      if (user.last) setLast(user.last);
      if (user.username) setUsername(user.username);
      if (user.email) setEmail(user.email);
    }
  }, [user])

  const validate = () => {
    if (username === '' || email === '' || !isAlphaNumeric(username) || !validateEmail(email) || !isAlphaNumeric(first) || !isAlphaNumeric(last)) {
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    setUpdateError(null);
    setOpenErr(false);
    setOpenSucc(false);
    if (validate()) {
      axios.put(
        `${BUILDER_BACKEND_URL}/users/${id}/edit`,
        {
          firstName: toPascalCase(first),
          lastName: toPascalCase(last),
          username,
          email,
        },
        { headers: authHeader() },
      )
        .then((res) => {
        // update local user state
          const auth = JSON.parse(localStorage.getItem('auth'));
          if (auth) {
            auth.user = res.data;
            localStorage.setItem('auth', JSON.stringify(auth));
          }
          setAuthUser(res.data);
          setOpenSucc(true);
        })
        .catch((err) => {
          setOpenErr(true);
          setUpdateError(err.response.data.message);
        });
    }
  };

  const getUserContent = () => {
    if (error) {
      return (
        <div className='sm:col-span-6'>
          Error:
          {" " + error.message}
          <br/>
          Sign out and log back in to fix this error.
        </div>
      );
    } if (loading || !user) {
      return <div>Loading...</div>;
    } else {
      return (
      <>

        {/* First Name and validations */}
        <div className="sm:col-span-3">
          <label htmlFor="first" className="block text-sm font-medium text-gray-700">
            First name
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              value={first}
              onChange={(e) => setFirst(e.target.value)}
              placeholder={user.firstName}
              name="first"
              id="first"
              className={classNames(
                !isAlphaNumeric(first) ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ' border-blue-gray-300',
                "h-6 block w-full rounded-md  pr-10 text-red-900 focus:outline-none sm:text-sm"
              )}
              aria-invalid="true"
              aria-describedby="password-error"
            />
            { !isAlphaNumeric(first) &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            }
                            
          </div>
          { !isAlphaNumeric(first) &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              First name is not alphanumeric
            </p>
          }
        </div>

        {/* First Name and validations */}
        <div className="sm:col-span-3">
          <label htmlFor="first" className="block text-sm font-medium text-gray-700">
            Last name
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              value={last}
              onChange={(e) => setLast(e.target.value)}
              placeholder={user.lastName}
              name="last"
              id="last"
              className={classNames(
                !isAlphaNumeric(last) ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ' border-blue-gray-300',
                "h-6 block w-full rounded-md  pr-10 text-red-900 focus:outline-none sm:text-sm"
              )}
              aria-invalid="true"
              aria-describedby="password-error"
            />
            { !isAlphaNumeric(last) &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            }
                            
          </div>
          { !isAlphaNumeric(last) &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Last name is not alphanumeric
            </p>
          }
        </div>

        {/* Username and validations */}
        <div className="sm:col-span-6">
          <label htmlFor="first" className="block text-sm font-medium text-gray-700">
            Username
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={user.username}
              name="last"
              id="last"
              className={classNames(
                !isAlphaNumeric(username) ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ' border-blue-gray-300',
                "h-6 block w-full rounded-md  pr-10 text-red-900 focus:outline-none sm:text-sm"
              )}
              aria-invalid="true"
              aria-describedby="password-error"
            />
            { !isAlphaNumeric(username) &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            }
                            
          </div>
          { !isAlphaNumeric(username) &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Username is not alphanumeric
            </p>
          }
        </div>

        {/* Email and validations */}
        <div className="sm:col-span-6">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="email"
              value={email}
              placeholder={user.email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              id="email"
              className={classNames(
                !validateEmail(email) ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ' border-blue-gray-300',
                "h-6 block w-full rounded-md  pr-10 text-red-900 focus:outline-none sm:text-sm"
              )}
              aria-invalid="true"
              aria-describedby="password-error"
            />
            { !validateEmail(email) &&
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            }
                            
          </div>
          { !validateEmail(email) &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Invalid email
            </p>
          }
        </div>

        {user.updatedAt && 
          <p className="text-sm text-blue-gray-500 sm:col-span-6">
            This account was last updated on{' '}
            <time dateTime="2017-01-05T20:35:40">{user.updatedAt}</time>.
          </p>
        }
      </>
      )
    }
  }


  return (
      <div className="mx-auto max-w-3xl py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Account</h1>

          <form className="divide-y-blue-gray-200 mt-6 space-y-8 divide-y">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6">
                  <h2 className="text-xl font-medium text-blue-gray-900">Profile</h2>
                  {/* <p className="mt-1 text-sm text-blue-gray-500">
                  This information will be displayed publicly so be careful what you share.
                  </p> */}
                </div>

              {getUserContent()}

              </div>

              <div className="flex justify-end pt-8">
              <button
                  type="button"
                  onClick={() => navigate('?page=Projects')}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                  Cancel
              </button>
              <button
                  type="button"
                  onClick={handleSubmit}
                  className={classNames(
                    !validate() ? "bg-gray-400 cursor-default" : "bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                    "ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none"
                  )}
              >
                  Save
              </button>
              </div>
          </form>

        <Snackbar open={openErr} autoHideDuration={6000} onClose={(e, reason) => handleClose(e, reason, 'error')}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Error updating user data{updateError && `: ${updateError}`}
          </Alert>
        </Snackbar>

        <Snackbar open={openSucc} autoHideDuration={6000} onClose={(e, reason) => handleClose(e, reason, 'success')}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Successfully updated user data!
          </Alert>
        </Snackbar>
      </div>
  );
}

/* Redux */
const mapStateToProps = (state) => ({
  models: state.modelsReducer.models,
  currProjectParams: state.modelsReducer,
  projectName: state.modelsReducer.projectName,
  activeProject: state.activeProjectReducer.activeProject,
});
const mapDispatchToProps = (dispatch) => ({
  togglePagination: (key) => dispatch(togglePagination(key)),
  setActiveProject: (params) => dispatch(setActiveProject(params)),
  setActiveProjectData: (params) => dispatch(setActiveProjectData(params)),
  setReduxActiveProjectId: (params) => dispatch(setActiveProject(params)),
  setReduxModelsData: (params) => dispatch(setActiveProjectData(params)),
  setReduxControllersData: (params) => dispatch(setControllers(params)),
  setReduxRoutesData: (params) => dispatch(setRoutes(params)),
  resetReduxModels: () => dispatch(resetProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDisplay);
