import React from 'react';

export default function SelectInput({ onChange, value, children }) {
  return (
    <select
      className='h-7 block w-full pl-3 pr-10 border-gray-300 text-sm p-0 ml-1'
      value={value}
      onChange={onChange}
    >
      {children}
    </select>
  )
}