import React, {useEffect, useState} from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';
import {GENERATOR_BACKEND_URL} from '../constants/constants';
import useQuery from '../hooks/useQuery';
import {getPreppedProjectData} from "../util/initializeProject";
import { useLocation, useNavigate } from 'react-router-dom';


export default function Preview() {
    const location = useLocation();
    const navigate = useNavigate();

    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!location.search) {
            navigate("?page=server-file");
        }
    }, [])


    useEffect(() => {
        const queryString = location.search;
        const projectData = getPreppedProjectData(true);

        let cancelled = false;
        setLoading(true);
        axios.put(`${GENERATOR_BACKEND_URL}/previewpage${queryString}`, projectData)
            .then((r) => {
                if (!cancelled) {
                    setCode(r.data.content);
                    setLoading(false);
                    setError(null);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(err.response.data.message);
            });

        return () => {
            cancelled = true;
        };
    }, [location]);

    return (
        <div className='flex h-full'>
            <SyntaxHighlighter language="javascript" style={dark} customStyle={styles.code}>
                {loading
                    ? '/* loading... */'
                    : error
                        ? `/* error previewing page: ${error} */`
                        : code
                }
            </SyntaxHighlighter>
        </div>
    );
}

const styles = {
    code: {
        backgroundColor: '#00101a',
        flex: 1,
        textAlign: 'left',
        borderWidth: 0,
        overflowY: 'scroll',
        fontSize: '.8em',
        margin: '1em',
        marginRight: 0,
        padding: '2em'
    },
};
