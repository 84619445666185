import { getRandomInt } from "../services/helpers";
import { toPascalCase, toTitleCase } from "../util/caseMatching";

export function terminalBackend(command) {
  command = command.replace(/\s\s+/g, ' ');
  command = command.trim()
  if (command === '') {
    return;
  }

  if (command === 'help') {
    return {
      error: false,
      response:
`commands:
 - g scaffold : creates a model, controller, and CRUD routes
      ex. g scaffold user name:string age:number
`
    }
  }

  let arr = command.split(" ");
  if (arr.length > 3 && arr[0] === 'g' && arr[1] === 'scaffold') {
    let initParams = [];
    // let arrRest = arr.slice(3);
    let arrRest = arr.slice(3).join(" ").split(/(?:\n| )+/)

    for (let p of arrRest) {
      let param = p.trim().split(':');
      if (param.length === 2 && param[1] !== '') {
        initParams.push({ name: param[0], paramType: 'data', dataType: toTitleCase(param[1]) })
      } else {
        return {
          command: 'scaffold',
          error: true,
          response: `Invalid param definition at ${p}. ${JSON.stringify(arrRest)}`
        }
      }
    }

    let newModel = {
      id: getRandomInt(100000000),
      name: toPascalCase(arr[2]),
      params: initParams
    }
    
    return {
      command: 'scaffold',
      data: newModel,
      response:  `Created new model ${newModel.name}, and controller ${newModel.name}Controller.`,
      error: false
    }
  }

  if (command === 'init auth') {
    return {
      command: 'init auth',
      response: `Configured auth`,
      error: false
    }
  }

  return {
    error: true,
    response: `Invalid command: ${command}\nEnter 'help' for available commands`
  }
}