import {
  ADD_BLOCK_MIDDLEWARE,
  ADD_MIDDLEWARE,
  DELETE_BLOCK_MIDDLEWARE,
  DELETE_MIDDLEWARE,
  EDIT_BLOCK_MIDDLEWARE,
  EDIT_MIDDLEWARE,
  MOVE_BLOCK_MIDDLEWARE,
  RESET_MIDDLEWARES,
  SAVE_ACTIVE_MIDDLEWARE,
  SET_ACTIVE_MIDDLEWARE_FROM_ID,
  SET_MIDDLEWARES,
  TOGGLE_CALLBACK_BLOCK_MIDDLEWARE
} from '../Types';
import {addBlockAsChild, deleteBlock, editBlock, findBlock,} from '../../util/recurseBlock';
import {remove, set} from "./util";

const initialState = {
    middlewares: [],
    activeSequenceBlockCache: [],
    activeSequence: [],
    activeMiddleware: {
        name: ''
    },
    refresh: 0,
    saveCount: 0,
    isDragging: false,
    closed: [],
    withCallback: [],
};

const MiddlewaresReducer = (state = initialState, action) => {
    const newMiddlewares = JSON.parse(JSON.stringify(state.middlewares));
    let newSequence = JSON.parse(JSON.stringify(state.activeSequence));

    let updatedActiveSequence = [...state.activeSequence];
    let temp = null;

    switch (action.type) {
        case SET_MIDDLEWARES:
            return {
                ...state,
                middlewares: action.data,
            };

        case ADD_MIDDLEWARE:
            return {
                ...state,
                middlewares: state.middlewares.concat(action.data),
            };

        case EDIT_MIDDLEWARE:
            console.log(action.data)
            for (let i = 0; i < newMiddlewares.length; i++) {
                if (newMiddlewares[i].id === action.key) {
                    newMiddlewares[i] = {...newMiddlewares[i], ...action.data};
                }
            }

            return {
                ...state,
                middlewares: newMiddlewares,
                refresh: state.refresh + 1,
            };

        case DELETE_MIDDLEWARE:
            return {
                ...state,
                middlewares: state.middlewares.filter((r) => r.id !== action.key),
            };

        case SET_ACTIVE_MIDDLEWARE_FROM_ID:
            let tempMid = null;
            for (const mid of newMiddlewares) {
                if (mid.id === action.id) {
                    temp = mid.logic;
                    tempMid = mid;
                }
            }

            return {
                ...state,
                activeMiddleware: tempMid,
                activeSequence: temp,
                activeSequenceBlockCache: [],
            };

        /*
         * Saves active sequence to middlewares
         */
        case SAVE_ACTIVE_MIDDLEWARE:
            for (const b of state.activeSequenceBlockCache) {
                updatedActiveSequence = editBlock(updatedActiveSequence, b[0], b[1]);
            }

            const updatedMiddlewares = [...state.middlewares];
            for (let i = 0; i < updatedMiddlewares.length; i++) {
                const mid = updatedMiddlewares[i];
                if (state.activeMiddleware.id === mid.id) {
                    updatedMiddlewares[i] = {
                        ...state.activeMiddleware,
                        logic: updatedActiveSequence,
                    };
                }
            }

            return {
                ...state,
                middlewares: updatedMiddlewares,
                activeSequence: updatedActiveSequence,
                saveCount: state.saveCount + 1,
            };

        case ADD_BLOCK_MIDDLEWARE:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            if (action.parentId) {
                newSequence = addBlockAsChild(
                    newSequence,
                    action.data,
                    action.parentId,
                    action.index,
                    action.variant,
                );
            } else {
                newSequence.splice(action.index, 0, action.data);
            }

            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
            };

        case EDIT_BLOCK_MIDDLEWARE:
            // const newCache = state.activeSequenceBlockCache.set(action.key, action.data);
            const newCache = set(state.activeSequenceBlockCache, action.key, action.data);
            return {
                ...state,
                activeSequenceBlockCache: newCache,
            };

        case DELETE_BLOCK_MIDDLEWARE:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            newSequence = deleteBlock(newSequence, action.key);
            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
            };

        case MOVE_BLOCK_MIDDLEWARE:
            // UPDATE ACTIVE SEQUENCE FROM CACHE
            for (const b of state.activeSequenceBlockCache) {
                newSequence = editBlock(newSequence, b[0], b[1]); // b[0] is id, b[1] is newData
            }

            // store block and delete
            temp = findBlock(newSequence, action.blockId);
            if (!action.parentId) {
                temp.parentId = null;
            }
            newSequence = deleteBlock(newSequence, action.blockId);

            // add block again in right index
            if (action.parentId) {
                newSequence = addBlockAsChild(
                    newSequence,
                    temp,
                    action.parentId,
                    action.index,
                    action.variant,
                );
            } else {
                newSequence.splice(action.index, 0, temp);
            }

            return {
                ...state,
                activeSequence: newSequence,
                refresh: state.refresh + 1,
                activeSequenceBlockCache: [],
            };

        case TOGGLE_CALLBACK_BLOCK_MIDDLEWARE:
            temp = state.withCallback;
            if (state.withCallback.includes(action.key)) {
                // temp.delete(action.key);
                temp = remove(temp, action.key);
            } else {
                // temp.add(action.key);
                temp.append(action.key);
            }

            return {
                ...state,
                withCallback: temp,
                refresh: state.refresh + 1,
            };

        case RESET_MIDDLEWARES:
            return initialState;

        default:
            return state;
    }
};

export default MiddlewaresReducer;
